"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AnalyticsTypeEnum = void 0;
var AnalyticsTypeEnum;
(function (AnalyticsTypeEnum) {
    AnalyticsTypeEnum["CSVUPLOAD"] = "CSVUPLOAD";
    AnalyticsTypeEnum["FUNNEL"] = "FUNNEL";
    AnalyticsTypeEnum["TRAIT"] = "TRAIT";
    AnalyticsTypeEnum["EXPORT"] = "EXPORTS";
})(AnalyticsTypeEnum = exports.AnalyticsTypeEnum || (exports.AnalyticsTypeEnum = {}));
