import { Form, MenuProps, Space } from "antd";
import PrimaryButton from "components/Buttons/PrimaryButton/PrimaryButton";
import AccessControl from "components/HOC/AccessControl/AccessControl";
import InnerNav from "components/InnerNav/InnerNav";
import TextInput from "components/Inputs/TextInput/TextInput";
import filterIcon from "../../../../../images/_filter.svg";
import { t } from "i18next";
import { ReactNode, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTES } from "utils/constants";
import "./CreateHeader.css"
import {
  FeatureSettingsFlagTypes,
  roleCheckTypes,
} from "utils/constants/constants";
import {AudienceManagementPageType } from "utils/enums";
import { validateName } from "validator/antdValidator";
import { ButtonProps } from "antd/lib";
import Selector from "components/Selector/Selector";
import { getJourneyTypeList } from "helperFunctions/events";
import { useSelector } from "react-redux";
type propTypes = {
  type: AudienceManagementPageType;
  initialValues?: { name?: string; description?: string; journey?:string };
  onNameChange?: (e: React.ChangeEvent<HTMLInputElement>) => any;
  onNameBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onDescriptionChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onJourneyChange?: (value:string) => void;
  saveButtonProps?:ButtonProps,
  headerRightItem?: ReactNode;
};
export const CreateHeader = ({
  type,
  initialValues,
  onNameChange,
  onNameBlur,
  onDescriptionChange,
  onJourneyChange,
  saveButtonProps,
  headerRightItem
}: propTypes) => {
  const { state } = useLocation();
  const data = {
    label: !state?.id
      ? type == AudienceManagementPageType.FUNCTION? t("createFunctionLabel"): type == AudienceManagementPageType.TRAIT? t("createTraitLabel"):type == AudienceManagementPageType.SEGMENT? t("createSegmentLabel"):t("createFunnelLabel")
      : type == AudienceManagementPageType.FUNCTION? t("updateFunctionLabel"): type == AudienceManagementPageType.TRAIT? t("updateTraitLabel"):type == AudienceManagementPageType.SEGMENT?t("updateSegmentLabel"):t("updateFunnelLabel"),
  };
  const navigate = useNavigate();
  const [tabFilter, setTabFilter] = useState<string>(type);
  const [filteredItems,seFilteredItems]=useState<any[]>([])
  const eventList = useSelector((state: any) => state.eventList);
  /**
   * Handles tab click events in the menu.
   * Updates the current tab filter and navigates to the appropriate route based on the clicked tab.
   *
   * @param {MenuProps["onClick"]} param - Object containing the key of the clicked menu item.
   * @param {string} param.key - The key of the clicked menu item, determining the navigation target.
   */
  const onTabClick: MenuProps["onClick"] = ({ key }: { key: string }) => {
    // Update the state to reflect the selected tab
    setTabFilter(key);

    // Navigate to different routes based on the selected tab
    if (key === AudienceManagementPageType.SEGMENT) {
      // Navigate to the Audience Create page
      navigate(ROUTES.DASHBOARD_AUDIENCE_CREATE);
    } else if (key === AudienceManagementPageType.TRAIT) {
      // Navigate to the All Traits page
      navigate(ROUTES.DASHBOARD_AUDIENCE_TRAIT_CREATE);
    }  else if (key == AudienceManagementPageType.FUNCTION) {
      navigate(ROUTES.DASHBOARD_AUDIENCE_FUNCTION_CREATE);
    }
  };
  return (
    <div>
      <div className="d-flex position-relative gap-2 audience-create-header-main-container justify-content-between  align-items-center ">
        {/* for icon and title of header */}
        <div className="d-flex justify-content-between w-100 gap-3  flex-wrap">
          <div className="d-flex align-items-center ">
            <div className="d-flex   align-items-center">
              <span className="d-flex   filter-icon-container justify-content-center ">
                <img
                  src={filterIcon}
                  height={"100%"}
                  width={"100%"}
                  alt="filter"
                ></img>
              </span>

              <h5 className="m-0 mx-2 audience-create-header-title">
                {data.label}
              </h5>
            </div>
          </div>

          {/* for buttons of header */}
          <Space className="d-flex  mb-auto">
            {type===AudienceManagementPageType.FUNNEL?headerRightItem:
            <PrimaryButton onClick={() => navigate(-1)}>
              {t("discardLabel")}
            </PrimaryButton>}
          </Space>
        </div>
        <AccessControl items={type!==AudienceManagementPageType.FUNNEL?[
          {
            label: t("queryLabel"),
            key: AudienceManagementPageType.SEGMENT,
          },
          {
            label: t("traitsLabel"),
            key: AudienceManagementPageType.TRAIT,
            featureFlag: FeatureSettingsFlagTypes.TRAITS,
          },
          {
            label: t("functionsLabel"),
            key: AudienceManagementPageType.FUNCTION,
            featureFlag: FeatureSettingsFlagTypes.FUNCTIONS,
          },
        ]:[]} getAccessedItems={(items)=>{seFilteredItems(items)}} accessControl={{ role: [roleCheckTypes.Checker ,roleCheckTypes.Maker] }}>
          <div className="mb-auto audience-segment-save">
            <PrimaryButton
              {...saveButtonProps}
              //   disabled={loading.count || !isValidForm}
              htmlType="submit"
              type="primary"
            >
              {data.label}
            </PrimaryButton>
          </div>
        </AccessControl>
      </div>
      <div className="d-flex justify-content-between py-3  flex-wrap-reverse gap-2 ">
        <div className="d-flex gap-3 flex-wrap w-75">
        {onNameChange && <div className="col-md-5 col-12 ">
            <strong className="form-item-custom-label">
              {type==AudienceManagementPageType.FUNCTION?t("functionNameLabel"):type==AudienceManagementPageType.TRAIT?t("traitNameLabel"):type==AudienceManagementPageType.SEGMENT?t("segmentNameLabel"):t("funnelNameLabel")}
            </strong>
            <Form.Item
              rules={[
                {
                  validator: validateName,
                  message: type==AudienceManagementPageType.FUNCTION?`${t("plzInputFunctionNameMsg")}`:type==AudienceManagementPageType.TRAIT?`${t("plzInputTraitNameMsg")}`:`${t("inputSegmentNameMsg")}`,
                },
              ]}
              initialValue={initialValues?.name}
              name={"name"}
              className="m-0"
            >
              <TextInput
                onChange={onNameChange}
                onBlur={onNameBlur}
                className="mt-2"
              />
            </Form.Item>
          </div>
            }
         {onDescriptionChange && <div className="col-md-5 col-12">
            <strong className="form-item-custom-label">
              
              {type===AudienceManagementPageType.FUNNEL?t("funnelDescriptionLabel"):type===AudienceManagementPageType.SEGMENT?t("segmentDescriptionLabel"):t("descriptionLabel")}
            </strong>
            <Form.Item
              initialValue={initialValues?.description}
              rules={[
                {
                  validator: validateName,
                  message: `${type==AudienceManagementPageType.SEGMENT?t("inputSegmentDescriptionMsg"):type==AudienceManagementPageType.FUNNEL?`${t("inputFunnelDescriptionMsg")}`:`${t("inputFunctionDescriptionMsg")}`}`,
                },
              ]}
              name={"description"}
              className="m-0"
            >
              <TextInput className="mt-2" onChange={onDescriptionChange} />
            </Form.Item>
          </div>}
        </div>
        <div>
          {type!==AudienceManagementPageType.FUNNEL && headerRightItem}
        </div>
      </div>
     {(filteredItems?.length && type!==AudienceManagementPageType.FUNNEL)? <InnerNav
        menu={filteredItems}
        onTabClick={onTabClick}
        selected={tabFilter}
      />:<></>}
      <div>
        {type==AudienceManagementPageType.FUNNEL && onJourneyChange? <div>
      <strong className="form-item-custom-label ">
      {t("selectJourneyMsg")}
      </strong>
        <Form.Item
                name={"journey"}
                className="input-size-md my-2"

                initialValue={initialValues?.journey}
                rules={[
                  {
                    required:true,
                    message: `${t("selectJourneyMsg")}`,
                  },
                ]}
              >
                <Selector
                  onChange={onJourneyChange}
                  placeholder={`${t("selectJourneyMsg")}`}
                  options={getJourneyTypeList(eventList.data)}
                ></Selector>
              </Form.Item>
              </div>
              :<></>}
      </div>
    </div>
  );
};
