import { t } from "i18next";
import { v4 as uuid } from "uuid";
import eventsIcon from "../images/events.svg";
import journeyIcon from "../images/journeyIcon.png";

import userTraitsIcon from "../images/users_traits.svg";
import computedTraitsIcon from "../images/computed_traits.svg";
import { AudienceEnum } from "utils/enums";
import { FeatureSettingsFlagTypes } from "utils/constants/constants";
export const audienceItems: any = {
  units: [
    { label: "Seconds", value: "s" },
    { label: "Minutes", value: "m" },
    { label: "Hours", value: "h" },
    { label: "Days", value: "d" }
  ],
  time: [
    { label: "Minutes", value: "m" },
    { label: "Hours", value: "h" },
    { label: "Days", value: "d" },
  ],
  timeunit: [
    { label: "Minutes Ago", value: "minutes" },
    { label: "Hours Ago", value: "hours" },
    { label: "Days ago", value: "days" },
  ],
  platformlist: [
    { label: "Android", value: "Android" },
    { label: "iOS", value: "IOS" },
    { label: "web", value: "Web" },
  ],
  deviceLanguagelist: [
    { label: "English", value: "19" },
    { label: "Arabic", value: "7" },
    { label: "Hindi", value: "Hindi" },
    { label: "Urdu", value: "Urdu" },
  ],
  genderlist: [
    { label: "Male", value: "Male" },
    { label: "Female", value: "Female" },
  ],
  booleanlist: [
    { label: "True", value: "true" },
    { label: "False", value: "false" },
  ],
  interestlist: [
    "MovieBuff",
    "Foodie",
    "Traveller",
    "Religious",
    "NightOwl",
    "FitnessFreak",
    "HealthConscious",
    "MusicLover",
    "Tech Lover",
    "PhotographyLover",
    "SportsLover",
    "NewsJunkie",
    "NewsFollower",
    "Culinarian",
    "Shopaholic",
    "Shopper",
    "Gamer",
    "CasualGamer",
    "PetLover",
    "Fashionista",
    "Inquisitive",
    "ComicsLover",
    "Puzzleteir",
    "HomeSeeker",
  ],
  timelist: [
    { label: "Morning(6-11Hrs)", value: "6-11" },
    { label: "Afternoon(11-16Hrs)", value: "11-16" },
    { label: "Evening(16-21Hrs)", value: "16-21" },
    { label: "Night(21-24Hrs)", value: "21-24" },
    { label: "Past Midnight(0-6Hrs)", value: "0-6" },
  ],
  placelist: [
    { label: "Home", value: "Home" },

    { label: "Office", value: "Office" },

    { label: "In-Vehicle", value: "In-Vehicle" },

    { label: "Train Station", value: "Train Station" },

    { label: "Airport", value: "Airport" },

    { label: "other", value: "other" },
  ],
  number: [
    { label: "Equals", value: "eq" },
    { label: "Greater Than", value: "gt" },
    { label: "Less Than", value: "lt" },
    { label: "Not Equals", value: "neq" },
    { label: "Less Than or Equal to ", value: "lte" },
    { label: "Greater Than or Equal to ", value: "gte" },
    { label: "In (Set Operator) ", value: "in" },
    { label: "Not in (Set Operator) ", value: "nin" },
    { label: "Is Between", value: "isb" },
    { label: "Is Not Between", value: "isnb" },
  ],
  string: [
    { label: "Equals", value: "eq" },
    { label: "Not Equals", value: "ne" },
    { label: "Contains", value: "contains" },
    { label: "In (Set Operator)", value: "in" },
    { label: "Not in (Set Operator)", value: "nin" },
    { label: "Starts the String", value: "startsWith" },
    { label: "Ends the String", value: "endsWith" },
    { label: "Does not start the string", value: "doesnt startsWith" },
    { label: "Does not end the string", value: "doesnt endsWith" },
  ],
  boolean: [
    { label: "Equals", value: "eq" },
    { label: "Not Equals", value: "neq" },
  ],
  networklist: [
    { label: "WIFI", value: "WIFI" },
    { label: "2G", value: "2G" },
    { label: "3G", value: "3G" },
    { label: "4G", value: "4G" },
  ],
  fileHeaderOptions: [
    { label: "Yes", value: "true" },
    { label: "No", value: "false" },
  ],
  typeOfDataOptions: [
    { label: "Client Id", value: "clientid" },
    { label: "Device Id", value: "did" },
    { label: "Email", value: "email" },
    { label: "Phone", value: "phone" },

  ],
  frequencyOperands: [
    { label: "at least", value: "at least" },
    { label: "at most", value: "at most" },
    { label: "exactly", value: "exactly" },
  ],
  timeWindowOperators: [
    { label: "since", value: "since" },
    { label: "after", value: "after" }

  ],
 allJourneysOption:[{ label: "All Journeys", value: "allJourneys" }],
 journeyStepsOptions: [
  { label: "First step", value: "firstStep" },
  { label: "Last step", value: "lastStep" }
],

  getfilterPopupData: (computedTraits: any[]=[]) => {
    const ctraits = computedTraits.map((trait: any) => {
      return {
        id: trait._id,
        idType: "dynamic",
        title: trait.name,
        icon: computedTraitsIcon,
      };
    });
    return [
      {
        id: 1,
        icon: eventsIcon,
        title: t("eventsLabel"),
        description: t("eventsPerformedMsg"),
        subtabs: [
          {
            id: "past",
            idType: "static",
            title: t("pastEventLabel"),
            icon: eventsIcon,
          },
          {
            id: "live",
            title: t("liveEventLabel"),
            idType: "static",
            icon: eventsIcon,
          },
          {
            id: "journey",
            title: t("journeyLabel"),
            idType: "static",
            icon: journeyIcon,
          },
        ],
      },
      {
        id: 2,
        title: t("userTraitsLabel"),
        description: t("propertiesRelatedMsg"),
        icon: userTraitsIcon,
        subtabs: [
          {
            id: "who",
            idType: "static",
            title: t("createUserTraitMsg"),
            icon: userTraitsIcon,
          },
        ],
      },
      {
        id: 4,
        title: t("computedTraitsLabel"),
        description: t("buildNewMetricesMsg"),
        featureFlag:FeatureSettingsFlagTypes.TRAITS,
        icon: computedTraitsIcon,
        subtabs: [
          {
            id: "cTrait",
            idType: "static",
            title: t("createComputedTraitMsg"),
            icon: computedTraitsIcon,
          },
          ...ctraits,
        ],
      }
    ];
  },
};
export function getContextConfig(context: string) {
  return [
    {
      Context: "who",
      Category: [
        "Device",
        "App Usage",
        "Custom Vars",
        "Competing Apps",
        "Custom Segment",
      ],
      Label: "is doing",
    },
    {
      Context: "what",
      Category: ["Events"],
      Label: "at",
    },
    {
      Context: "where",
      Category: ["Geo", "Location", "Network"],
      Label: "and",
    },
    {
      Context: "when",
      Category: ["Time"],
      Label: "",
    },
  ].filter((item) => item.Context == context);
}
export function getCategory() {
  return [
    {
      value: "Custom Vars",
      label: "Profile",
    },
    { label: "Device", value: "Device" },
    { label: "Usage", value: "App Usage" },
    {
      value: "Geo",
      label: "Location",
    },
    {
      value: "Custom Segment",
      label: "Custom Segment",
    },
  ];
}
export function getCategoryContextConfig(category: string, criteria = "") {
  const data = [
    {
      category: "Device",
      reachable: true,
      attributes: [
        {
          label: "Device Id",
          value: "did",
          type: "string",
          operators: [
            { label: "Equals", value: "eq" },
            { label: "Not Equals", value: "ne" },
            { label: "In", value: "in" },
          ],
        },
        {
          label: "Carrier",
          value: "c",
          type: "string",
          multiple: true,
          operators: [
            { label: "In", value: "in" },
            { label: "Not In", value: "nin" },
          ],
        },
        {
          label: "Platform",
          value: "p",
          type: "string",
          multiple: false,
          operators: [
            { label: "Equals", value: "eq" },
            { label: "Not Equals", value: "ne" },
          ],
        },
        {
          label: "Platform Version",
          value: "pv",
          type: "string",
          multiple: true,
          operators: [
            { label: "In", value: "in" },
            { label: "Not In", value: "nin" },
          ],
        },
        {
          label: "App Version",
          value: "av",
          type: "string",
          multiple: true,
          operators: [
            { label: "In", value: "in" },
            { label: "Not In", value: "nin" },
          ],
        },
        {
          label: "Device Make",
          value: "ma",
          type: "string",
          multiple: true,
          operators: [
            { label: "In", value: "in" },
            { label: "Not In", value: "nin" },
          ],
        },
        {
          label: "Device Model",
          value: "d",
          type: "string",
          multiple: true,
          operators: [
            { label: "In", value: "in" },
            { label: "Not In", value: "nin" },
          ],
        },
        {
          label: "Device Language",
          value: "device_lang",
          type: "deviceLanguagelist",
          multiple: false,
          operators: [
            { label: "In", value: "in" },
            { label: "Not In", value: "nin" },
          ],
        },
      ],
    },
    {
      category: "Demographics",
      reachable: true,
      attributes: [
        {
          label: "Gender",
          value: "gender",
          type: "string",
          multiple: false,
          operators: [
            { label: "Equals", value: "eq" },
            { label: "Not Equals", value: "ne" },
          ],
        },
      ],
    },
    {
      category: "Custom Vars",
      reachable: true,
      attributes: [],
    },
    {
      category: "App Usage",
      reachable: true,
      attributes: [
        {
          label: "First Seen",
          value: "fs",
          type: "number",
          operators: [
            { label: "Greater Than", value: "gt" },
            { label: "Greater Than or Equal To", value: "gte" },
            { label: "Less Than", value: "lt" },
            { label: "Less Than or Equal To", value: "lte" },
          ],
        },
        {
          label: "Last Seen",
          value: "ls",
          type: "number",
          operators: [
            { label: "Greater Than", value: "gt" },
            { label: "Greater Than or Equal To", value: "gte" },
            { label: "Less Than", value: "lt" },
            { label: "Less Than or Equal To", value: "lte" },
          ],
        },
        {
          label: "Session Count",
          value: "sc",
          type: "number",
          operators: [
            { label: "Equals", value: "eq" },
            { label: "Greater Than", value: "gt" },
            { label: "Less Than", value: "lt" },
          ],
        },
        {
          label: "Total Session Duration(Minutes)",
          value: "tsd",
          type: "number",
          operators: [
            { label: "Equals", value: "eq" },
            { label: "Greater Than", value: "gt" },
            { label: "Less Than", value: "lt" },
          ],
        },
        {
          label: "Notification Permission",
          multiple: false,
          value: "permissions->'is_pn'",
          type: "booleanlist",
          operators: [{ label: "Equals", value: "eq" }],
        },
      ],
    },
    {
      category: "Interests",
      reachable: true,
      attributes: [
        {
          label: "Interests",
          value: "Interests",
          type: "string",
          multiple: true,
          operators: [
            { label: "In", value: "in" },
            { label: "Not In", value: "nin" },
          ],
        },
      ],
    },
    {
      category: "Geo",
      reachable: true,
      attributes: [
        {
          label: "City",
          value: "cty",
          type: "string",
          multiple: true,
          operators: [
            { label: "In", value: "in" },
            { label: "Not In", value: "nin" },
          ],
        },
        {
          label: "District",
          value: "district",
          type: "string",
          multiple: true,
          operators: [
            { label: "In", value: "in" },
            { label: "Not In", value: "nin" },
          ],
        },
        {
          label: "State",
          value: "state",
          type: "string",
          multiple: true,
          operators: [
            { label: "In", value: "in" },
            { label: "Not In", value: "nin" },
          ],
        },
        {
          label: "Country",
          value: "cc",
          type: "string",
          multiple: true,
          operators: [
            { label: "In", value: "in" },
            { label: "Not In", value: "nin" },
          ],
        },
        {
          label: "Geo-fencing",
          value: "gf",
          type: "string",
          multiple: false,
          operators: [
            { label: "OnEntry", value: "oen" },
            { label: "OnExit", value: "oex" },
          ],
        },
      ],
    },
    {
      category: "Network",
      attributes: [
        {
          label: "Connection Type",
          value: "connection",
          type: "networklist",
          multiple: true,
          operators: [
            { label: "In", value: "in" },
            { label: "Not In", value: "nin" },
          ],
        },
        {
          label: "Roaming",
          value: "roaming",
          type: "booleanlist",
          multiple: false,
          operators: [{ label: "Equals", value: "eq" }],
        },
      ],
    },
    {
      category: "Time",
      attributes: [
        {
          label: "Time",
          value: "time",
          type: "timelist",
          multiple: true,
          operators: [
            { label: "In", value: "in" },
            { label: "Not In", value: "nin" },
          ],
        },
        {
          label: "Custom",
          value: "custom",
          type: "time",
          operators: [{ label: "Between", value: "between" }],
        },
      ],
    },
    {
      category: "Communique",
      pubsubattr: [],
    },
    {
      category: "Events",
      attributes: [],
    },
    {
      category: "Custom Segment",
    },
  ];
  if (category && criteria != "") {
    let mdata: any = [];
    data
      .filter((item) => item.category == category)[0]
      .attributes?.forEach((att) => {
        if (att.value == criteria) {
          mdata.push(...att.operators);
        }
      });
    return mdata;
  } else {
    return data.filter((item) => item.category == category);
  }
}
export const getDw = (time: "h" | "m" | "d" | undefined, dwell: number) => {
  if (time == "m") {
    return dwell * 60;
  } else if (time == "h") {
    return dwell * 60 * 60;
  } else if (time == "d") {
    return dwell * 60 * 60 * 24;
  } else {
    return 0;
  }
};
/**
 * Converts the format of the 'who' data in the segmentinfo object based on the specified type and will move the condition from who to where if the category is Geo.
 * If type is 'incoming', organizes the 'who' data into categories with children.
 * If type is 'outgoing', flattens the 'who' data into a single array of objects.
 * @param segmentinfo The segmentinfo object containing the 'who','where' ,'what' and 'when'
 * @param type The type of conversion to perform: 'incoming' for data coming from the API, 'outgoing' for data going to the API.
 * @returns The segmentinfo object with the 'who' data converted to the specified format.
 */
export const changeAudienceDataFormat = (
  segmentinfo: any,
  type: "incoming" | "outgoing"
) => {
  // Create a copy of the segmentinfo object
  let finalOutput: any = {};
  // Initialize an array to store the modified data
  const mData: any[] = [];
  const modified_segmentinfo: any = {};

  // Check if the type is 'incoming' (data coming from the API) or 'outgoing' (data going to the API)
  if (type == "incoming") {
    // Convert the 'who', 'where', 'when', and 'what' arrays to include an 'id' for each item
    Object.keys(segmentinfo).forEach((segKey: string) => {
      if (segKey == AudienceEnum.WHO || segKey == AudienceEnum.WHERE || segKey == AudienceEnum.WHEN || segKey == AudienceEnum.WHAT)
        // Iterate over each segment in the segmentinfo array
        modified_segmentinfo[segKey] = segmentinfo[segKey].map(
          (segment: any) => {
            let tempSegment = { ...segment }; // Copy the current segment object

            // Check if the current segment is in the "what" segment group and has the 'attr' property
            if (segKey == AudienceEnum.WHAT && tempSegment.attr) {
              // Create a copy of the attributes array or an empty array if it doesn't exist
              const segmentAttributes = tempSegment?.attributes
                ? [...tempSegment?.attributes]
                : [];

              // Replace the first element (if it exists) with a new object with 'attr', 'operator', 'e_operator', and 'value' properties
              segmentAttributes[0] = {
                attr: tempSegment.attr,
                operator: tempSegment.operator,
                e_operator: AudienceEnum.AND,
                value: tempSegment.value,
              };

              // Update the 'attributes' property of the tempSegment object with the modified segmentAttributes array
              tempSegment = { ...tempSegment, attributes: segmentAttributes };
            }

            // Return a new object with a unique id and the updated tempSegment object
            return { id: uuid(), ...tempSegment };
          }
        );
      else {
        modified_segmentinfo[segKey] = segmentinfo[segKey];
      }
    });
    finalOutput = { ...modified_segmentinfo };
    // Object to keep track of categories already added
    const categoryObj: any = {};
    // Iterate over each element in the 'who' and 'where' array
    [...modified_segmentinfo[AudienceEnum.WHO], ...modified_segmentinfo[AudienceEnum.WHERE]].forEach(
      (seg: any) => {
        const modifiedData: any = {};
        // If the category is not already added, add it to mData
        if (!categoryObj[seg.category]) {
          categoryObj[seg.category] = seg;
          modifiedData[AudienceEnum.CATEGORY] = seg.category;
          modifiedData[AudienceEnum.ID] = seg.id;
          delete seg.category;
          delete seg.id;
          if(modifiedData.category===AudienceEnum.CUSTOM_VARS && (seg.operator=="in" || seg.operator=="nin")){
            const value=seg.value.map((item:string) => JSON.parse(item)[0]) // Parse each string and extract the first element
            seg={...seg,value}
          }
          //modified children items to include an id
          modifiedData[AudienceEnum.CHILDREN] = [{ id: uuid(), ...seg }];
          mData.push(modifiedData);
        } else {
          // If category is already added, find its index in mData and push the child element
          const targetElementIndex = mData.findIndex(
            ({ category }) => category == seg.category
          );
          delete seg.category;
          delete seg.id;
          mData[targetElementIndex].children.push({ id: uuid(), ...seg });
        }
      }
    );
    // Update the 'who' property in the finalOutput with the modified data
    finalOutput[AudienceEnum.WHO] = mData;
  } else {
    // If the type is 'outgoing', flatten the 'who' array
    Object.keys(segmentinfo).forEach((segKey: string) => {
      if (segKey == AudienceEnum.WHO || segKey == AudienceEnum.WHERE || segKey == AudienceEnum.WHEN || segKey == AudienceEnum.WHAT)
        modified_segmentinfo[segKey] = segmentinfo[segKey].map(
          (segment: any) => {
            // Create a shallow copy of the segment object
            let copiedSegment = { ...segment };

            // Check if the current segment is in the "what" segment group and has the 'attributes' property
            if (segKey == AudienceEnum.WHAT && segment.attributes) {
              // Create a shallow copy of the first attribute object in the 'attributes' array
              const modifiedAttribute = { ...segment?.attributes[0] };

              // Remove the 'e_operator' property from the modified attribute object
              delete modifiedAttribute.e_operator;

              // Merge the modified attribute object into the copiedSegment object
              copiedSegment = { ...copiedSegment, ...modifiedAttribute };
            }
            // Remove the 'id' property, which was added for UI purposes only
            delete copiedSegment.id;
            return copiedSegment;
          }
        );
      else {
        modified_segmentinfo[segKey] = segmentinfo[segKey];
      }
    });
    const mDataWhere: any = [];
    // Iterate over each element in the 'who' array
    modified_segmentinfo[AudienceEnum.WHO].forEach(
      ({ category, children }: { category: string; children: any[] }) => {
        children.forEach((child) => {
          // If category is Geo, move that condition from 'who' to 'where'
          const copiedChild = { ...child };
          delete copiedChild.id;
          if (category == AudienceEnum.GEO) {
            mDataWhere.push({ ...copiedChild, category });
          } else if (category) {
            mData.push({ ...copiedChild, category });
          }
        });
      }
    );
    finalOutput = { ...modified_segmentinfo };
    // Update the 'who' property in the finalOutput with the modified data
    finalOutput[AudienceEnum.WHO] = mData;
    // Update the 'where' property in the finalOutput with the modified data
    finalOutput[AudienceEnum.WHERE] = mDataWhere;
  }
  // Return the final output
  return finalOutput;
};
export const getCriteria = (segmentinfo: any) => {
  const criteria: any = [];
  Object.keys(segmentinfo).forEach((key) => {
    if (Array.isArray(segmentinfo[key])) {
      segmentinfo[key].forEach((condition: any) => criteria.push(condition));
    }
  });
  return criteria;
};
