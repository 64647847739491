import { Form} from "antd";
import  { useState } from "react";
import PrimaryButton from "../../components/Buttons/PrimaryButton/PrimaryButton";
import "./SetupNewApp.css";
import { t } from "i18next";
import TextInput from "../../components/Inputs/TextInput/TextInput";
import internetIcon from "../../images/webnew.svg";
import appStoreIcon from "../../images/appstorenew.svg";
import googlePlayIcon from "../../images/googleplaynew.svg";
import hotStarIcon from "../../images/hotstar.png";
import PageLayout from "layout/PageLayout/PageLayout";
import { BOOLEAN_OPTIONS } from "utils/constants/selectorOptions";
import useMessage from "hooks/useMessage";
import { API_ENDPOINTS } from "utils/constants";
import centralApi from "services/centralApi";
import { useDispatch, useSelector } from "react-redux";
import { getToken } from "redux/features/app/authTokenSlice";
import { useNavigate } from "react-router-dom";

type FormDataProps = {
  _id?: string;
  key?: string;
  isAppDeleted: string;
  icon: string;
  category: string;
  devemailid: string;
  a_appname: string;
  i_appname: string;
  w_appname: string;
  sendSteps: string;
};

function SetupNewApp() {
  const loginUser = useSelector((state: any) => state.loginUser.data);
  const [loading, setLoading] = useState(BOOLEAN_OPTIONS.FALSE);
  const navigate = useNavigate();
  const dispatch: any = useDispatch();
  const [form] = Form.useForm();
  const { showError, showSuccess } = useMessage();  

   //setting formdata data in use State
   const [formData, setFormData] = useState<FormDataProps>(
    {
      isAppDeleted: "false",
      icon: "assets/img/app-store-logos.jpg",
      category:"",
      devemailid:"",
      a_appname:"",
      i_appname:"",
      w_appname:"",
      sendSteps:"both"
    }
  );

    // handleFormChange handling form data change
    const handleFormChange = (key: string, value: any) => {
      setFormData((prevState: typeof formData ) => ({
        ...prevState,
        [key]: value,
       }));
      };
    
    /**
     * createApp calls create app api and adds an app in apps collection
     * @returns app id of the app which is created
     */
    const createApp = async()=>{
      try {
        const payload = {
          args: JSON.stringify({
            name:formData.w_appname,
            icon:formData.icon,
            isAppDeleted:formData.isAppDeleted,
            category:formData.category,
            _id:formData._id ,
            key: formData.key
          }),
    
          api_key: loginUser.api_key,
        };
        const url = API_ENDPOINTS.CREATE_NEW_APP_URL;
        const appData = await centralApi("GET", url, null, payload)
        await dispatch(getToken())
        return appData;
      } catch (error) {
        showError(t("somethingWrongLabel")) 
        return null
      }
    } 

    //checkEmailDomain CHECKS THE EMAIL DOMAIN OF DEVELOPER
    const checkEmailDomain = async()=>{
      try {
        const payload = {
          args: JSON.stringify({
            devemailid:formData.devemailid
          }),
    
          api_key: loginUser.api_key,
        };
        const url = API_ENDPOINTS.CHECK_EMAIL_DOMAIN_URL;
        await centralApi("GET", url, null, payload);
        await dispatch(getToken())
        
      } catch (error) {
        showError(t("somethingWrongLabel"))
      }
      finally{
        return
      }
    }

    //sendDevData function send app data and saves dev email in apps collection
    const sendDevData = async(data:any)=>{
      try {
        const payload = {
          args: JSON.stringify({
            devemailid:formData.devemailid,
            a_appname:formData.a_appname,
            i_appname:formData.i_appname,
            sendSteps:formData.sendSteps,
            w_appname:formData.w_appname,
            app_id:data._id,
            app_key:data.key
          }),
    
          api_key: loginUser.api_key,
        };
        const url = API_ENDPOINTS.SEND_DEV_DATA_URL;
        await centralApi("GET", url, null, payload);
        
      } catch (error) {
        showError(t("somethingWrongLabel"))
      }
      finally{
        return
      }
    }

    const onFormSubmit = async()=>{
      try {
        // Destructure _id and key from formData (assuming formData contains these fields)
        const { _id, key } = formData;
        if (_id && _id.length !== 24){
           // Validation failed: show an error and stop the process
            showError(t("appidValidationLabel"));
            return; // Stop further execution
        }
        if (key && key.length !== 40){
          // Validation failed: show an error and stop the process
           showError(t("appkeyValidationLabel"));
           return; // Stop further execution
       }
        setLoading(BOOLEAN_OPTIONS.TRUE)
        const appData = await createApp();
        if(appData == null){
          setLoading(BOOLEAN_OPTIONS.FALSE);
          showError(t("somethingWrongLabel")) 
          await dispatch(getToken())
          return
        }
        await checkEmailDomain();
        await sendDevData(appData);
        showSuccess(t("appCreatedSuccessfully"));
        navigate("/dashboard")    
      } catch (error) {
        setLoading(BOOLEAN_OPTIONS.FALSE)
        showError(t("somethingWrongLabel")) 
      }
    }


  return (
    <PageLayout
     title="Setup App"
     topLeftItem={t("dashboardLabel")}
     loading={loading}
    > 
    <div className="setup-new-app-inner-container ">
    <Form
        layout="vertical"
        form={form}
        onFinish={onFormSubmit}
      >
      <div className="setup-new-app-inner-container-header d-flex align-items-center">
        <h5 className="m-0">Setup your App</h5>
      </div>
      <div className="d-flex w-100 setup-your-app-container setup-new-app-inner-container-body mt-3  gap-3 ">
      <div className="w-50 p-4 setup-your-app-container-child1 ">
      <strong style={{ color: "var(--color-text-disabled-strong)" }}>
        {t("setupAppDescriptionLabel")}
      </strong>
    
        <Form.Item name={"a_appname"} 
           rules={[
            { required: true, message: "Andriod app name is required" },
          ]}
        >
          <TextInput
            addonBefore={<img height={22} width={22} src={googlePlayIcon} />}
            placeholder={"Application Name / App Store URL"}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              handleFormChange("a_appname", e.target.value);
            }}
          />
        </Form.Item>
        <Form.Item name={"i_appname"} 
         rules={[
          { required: true, message: "Ios app name is required" },
        ]}
        >
          <TextInput
            addonBefore={<img height={22} width={22} src={appStoreIcon} />}
            placeholder={"Application Name / App Store URL"}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              handleFormChange("i_appname", e.target.value);
            }}
          />
        </Form.Item>
        <Form.Item name={"w_appname"} 
         rules={[
          { required: true, message: "Web app name is required" },
        ]}
        >
          <TextInput
            addonBefore={<img height={22} width={22} src={internetIcon} />}
            placeholder={"Website URL"}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              handleFormChange("w_appname", e.target.value);
            }}
          />
        </Form.Item>
      </div>
      <div className="w-50 p-3 setup-your-app-container-child2">
      <div >
        <h5 className="py-2 app-title">Example Entry of an App</h5>
      </div>
      <div className="">
        <div className="d-flex mt-4 gap-2 py-1 just align-items-center">
          <img
            src={hotStarIcon}
            style={{ borderRadius: 6 }}
            alt="appstore-icon"
            height={30}
            width={30}
          />
          <p >hotstar live TV movies cricket</p>
        </div>
        <div className="d-flex mt-3 gap-2  py-1">
          <img
            src={appStoreIcon}
            alt="appstore-icon"
            height={30}
            width={30}
          />
          <p>https://itunes.apple.com/in/app/hotstar/id934459219?mt=8</p>
        </div>
        <div className="d-flex mt-3  gap-2 py-1">
          <img
            src={googlePlayIcon}
            alt="appstore-icon"
            height={30}
            width={30}
          />
          <p>https://itunes.apple.com/in/app/hotstar/id934459219?mt=8</p>
        </div>
      </div>
      </div>
      </div>
      <div className="d-flex gap-3 w-100 pt-3">
        <Form.Item name={"_id"} label={"App Id"}  className="w-50">
          <TextInput
            placeholder={"App Id (optional)"}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              handleFormChange("_id", e.target.value);
            }}
            maxLength={24}
            showCount
          />
         </Form.Item>
        <Form.Item name={"key"} label={"App key"} className="w-50" >
          <TextInput
            placeholder={"App Key (optional)"}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              handleFormChange("key", e.target.value);
            }}
            maxLength={40}
            showCount
          />
         </Form.Item>
        
 
      </div>
      <div className="d-flex gap-3 setup-new-app-developer-email">
         <Form.Item name={"devemailid"} 
            label={"Developer email"}
            rules={[
              { required: true, message: "Developer email id is required" },
            ]}
         >
          <TextInput
            type="email"
            placeholder={"Developer email id (required)"}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              handleFormChange("devemailid", e.target.value);
            }}
          />
         </Form.Item>
         </div>
         <div className="gap-2  d-flex justify-content-end">
              <PrimaryButton
                type="primary"
                htmlType="submit"
              >
                {t("createLabel")}
              </PrimaryButton>
              <PrimaryButton
                style={{
                  color: "white",
                  background: "var( --color-red-main)",
                }}
                onClick={() => {
                  navigate(-1)
                }}
              >
                {t("closeLabel")}
              </PrimaryButton>
        </div>
    
    </Form>
    </div> 

    </PageLayout>
  );
}

export default SetupNewApp;
