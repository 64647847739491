import { Modal } from "antd";
import { useEffect, useState } from "react";
import Selector from "../../Selector/Selector";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import centralApi from "services/centralApi";
import useMessage from "hooks/useMessage";
import TextInput from "components/Inputs/TextInput/TextInput";
import { Form } from "antd";
import { getToken } from "redux/features/app/authTokenSlice";
import { UserRoleEnum } from "utils/enums";
import { API_ENDPOINTS } from "utils/constants";

const AddUserModel = ({
  visibility,
  setVisibility,
  getUpdatedData,
  // setShowMessage,
}: any) => {
  const { t } = useTranslation();
  const emailLabel = t("emailLabel");
  const selectLabel = t("selectLabel");
  const { showError, showSuccess } = useMessage();
  const [form] = Form.useForm();
  const [email, setEmail] = useState<string | undefined>("");
  const [emailError, setEmailError] = useState("");
  const [role, setRole] = useState<string | undefined>("");
  const { appId } = useSelector((state: any) => state.activeApp);
  const loginUser = useSelector((state: any) => state.loginUser.data);
  const authToken = useSelector((state: any) => state.authToken.token);
  const dispatch : any = useDispatch()


  async function validateEmail() {
    const payload = {
      args: JSON.stringify({
        email: email,
      }),

      api_key: loginUser.api_key,
    };
    const url: string = API_ENDPOINTS.VALIDATE_EMAIL_URL;
    try {
      const data = await centralApi("GET", url, null, payload);
      if (data?.result) {
        setEmailError(data.result);

        // showError(data.result);
        return false;
      }
      return true;
    } catch (error) {
      showError(t("somethingWrongLabel"));
      return false;
    }
  }
  const handleSave = async () => {

    try {
      if (!email || !role) {
        showError(t("fillAllDetailsMsg"));
      } else {
        const payload = {
          args: JSON.stringify({
            modified_by: loginUser._id,
            appid: appId,
            role: role,
            email: email,
          }),
          api_key: loginUser.api_key,
          app_id: appId,
          authToken
        };
  
        const url = `/o/users/addUsersRole`;
  
        const data = await centralApi("POST", url, payload, null);
        dispatch(getToken())

  if (data. acknowledged == true) {
          await getUpdatedData();

          showSuccess(t("userAddedSuccessfullyMsg"));
          setEmail("");
            setRole("");
            setVisibility(false);
          

        } else {
          showError(t("emailNotExistsMsg"));

        }
        
  
      }
    } catch (error) {
      dispatch(getToken())

      console.error("Error in handleSave:", error);
      showError(t("somethingWrongLabel"));
    }
  };
  
  const handleEmailChange = (e: any) => {
    setEmail(e.target.value);
    setEmailError(""); // Clear error when user starts typing
  };
  const handleRoleChange = async (value: any) => {
    const isEmailValid = await validateEmail();
    if (isEmailValid) {
      setRole(value);
    }
    else{
      setRole(undefined)
      setEmail(undefined)
      form.resetFields()
    }
  };
  const handleDropdownVisibleChange = async (open: boolean) => {
    if (open) {
      try {
        await validateEmail();
      } catch (error) {
        console.error("Validation error:", error);
      }
    }
  };

  return (
    <Modal
      title={t("addNewUserLabel")}
      centered
      open={visibility}
      okText={t("saveLabel")}
      cancelText={t("cancelLabel")}
      onOk={handleSave}
      onCancel={() => setVisibility(false)}
      style={{ position: "relative" }}
    >
      <Form
        form={form}
        layout="horizontal"
        // onFinish={onFormSubmit}
      >
        <Form.Item
          required={true}
          className="m-0"
          validateStatus={emailError ? "error" : ""}
          help={emailError}
        >
          <TextInput
            value={email}
            onChange={handleEmailChange}
            placeholder={emailLabel}
            style={{ width: "100%", margin: "15px 0px" }}
          />
        </Form.Item>
        <Form.Item required={true}  className="m-0">
          <Selector
            placeholder={selectLabel}
            value={role}
            onChange={handleRoleChange}
            onDropdownVisibleChange={handleDropdownVisibleChange}
            style={{ width: "100%", margin: "15px 0px" }}
            options={[
              {
                value: UserRoleEnum.APP_ADMIN,
                label: t("adminLabel"),
              },
              {
                value: UserRoleEnum.APP_MANAGER,
                label: t("managerLabel"),
              },
              {
                value: UserRoleEnum.APP_MARKETER,
                label: t("marketerLabel"),
              },
              {
                value: UserRoleEnum.APP_REVIEWER,
                label: t("reviewerLabel"),
              },
              {
                value: UserRoleEnum.APP_INFOSEC,
                label: t("infosecLabel"),
              },
              {
                value: UserRoleEnum.APP_CONTACT_CENTER,
                label: t("contactCenterLabel"),
              },
            ]}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddUserModel;
