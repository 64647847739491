import { Divider, MenuProps } from 'antd'
import InnerNav from 'components/InnerNav/InnerNav'
import { t } from 'i18next'
import React, { Fragment, useEffect, useState } from 'react'
import UserList from './Components/UserList/UserList'
import Decryption from './Components/Decryption/Decryption'
import { DBSetup } from './Components/DBSetup/DBSetup'
import { useSelector } from 'react-redux'
import { checkUserRole } from 'helperFunctions/common'
import { roleCheckTypes } from 'utils/constants/constants'
import { Diagnostic } from './Components/Diagnostic/Diagnostic'


const UserListNav = () => {
    const [appTabFilter, setAppTabFilter] = useState("userList")
    const userRole = useSelector((state: any) => state.loginUser?.data?.activeAppUserRole);
    const [selectedComponent, setSelectedComponent] =
      useState<JSX.Element | null>(null)
  
    const onTabClick: MenuProps["onClick"] = (e) => {
      setAppTabFilter(e.key)
    }
  
    useEffect(() => {
      switch (appTabFilter) {
        case "userList":
          setSelectedComponent(<UserList />)
          break
        case "decryption":
          setSelectedComponent(< Decryption/>)
          break
          case "dbSetup":
          setSelectedComponent(< DBSetup/>)
          break
          case "diagnostic":
            setSelectedComponent(<Diagnostic/>)
            break
           
           // Add cases for other tabs
      default:
        setSelectedComponent(<div></div>) // Default empty component
    }
  }, [appTabFilter])
  return (
    <Fragment>
        <div className="app-setting-container ">
        <div className="mt-4">
          <h6 className="decryption-notification-container-heading">
           {t("diagnosticLabel")}
          </h6>
          <Divider className="decryption-notification-custom-divider" />
        </div>
        <div className="app-setting-nav">
          <InnerNav
            theme="light"
            onTabClick={onTabClick}
            selected={appTabFilter}
            menu={[
              {
                
                label: t("userListLabel"),
                 key: "userList",
            
              },  
              {
                
                label: t("diagnosticsLabel"),
                 key: "diagnostic",
            
              },
              ...(checkUserRole(userRole, roleCheckTypes.GlobalAdmin)
              ? [            
              {
                
                label: t("decryptionLabel"),
                 key: "decryption",
            
              }]
              : []),
              
              ...(checkUserRole(userRole, roleCheckTypes.Contributor)
              ? [
                  {
                    label: "DB setup",
                    key: "dbSetup",
                  },
                ]
              : []),
            ]}
            />
          </div>
        </div>
        {selectedComponent}
      </Fragment>
    )
}

export default UserListNav;