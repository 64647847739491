import { ROUTES } from "utils/constants";

export const CONSTANTS = {
  WHO: "who",
  WHAT: "what",
  WHEN: "when",
  WHERE: "where",
  CUSTOM_VARS: "Custom Vars",
  CUSTOM_SEGMENT: "Custom Segment",
  CUSTOM: "custom",
  GF: "gf",
  FS: "fs",
  LS: "ls",
  DEVICE: "Device",
  PLACE_LIST: "placelist",
  TIME_LIST: "timelist",
  BOOLEAN_LIST: "booleanlist",
  NETWORK_LIST: "networklist",
  DEVICE_LANGUAGE_LIST: "deviceLanguagelist",
  AND: "AND",
  OR: "OR",
  CATEGORY_TYPE: "category_type",
  OPERATOR: "operator",
  VALUE: "value",
  OPERAND: "operand",
  EVENTS: "Events",
  H: "h",
  HN: "hn",
  LIVE: "live",
  PAST: "past",
  QUERY: "query",
  TRAIT: "trait",
  SYNC: "sync",
  SPLIT: "split",
  SAVE: "save",
  COUNT: "count",
  INCOMING: "incoming",
  OUTGOING: "outgoing",
  CATEGORY: "category",
  ID: "id",
  CHILDREN: "children",
  GEO: "Geo",
  CTRAIT: "cTrait",
  BOOLEAN: "boolean",
  NUMBER: "number",
  TREE: "json",
  IMAGE: "image",
  AU: "au",
  ASL: "asl",
  AGGREGATE_DATA: "aggregateData",
  AVG_AGGREGATE_DATA: "averageAggregateData",
  TABLE_DATA: "tableData",
  AVG_TABLE_DATA: "averageTableData",
  PREVIOUS_TOTAL: "previousTotal",
  HEADER: "Header",
  OFFER: "Offer",
  CARDS:"Cards",
  PORTFOLIO: "Portfolio",
  DEFAULTKEY:"DefaultVal123",
  ACCOUNT_ENGAGEMENT: "AccountEngagement",
  REVIEW:"review",
  BOTTOM: "bottom",
  MIDDLE: "middle",
  EVENT: "event",
  MONTHS_FIRST_LETTER_LIST: [
    "J",
    "F",
    "M",
    "A",
    "M",
    "J",
    "J",
    "A",
    "S",
    "O",
    "N",
    "D",
  ],
} as const;
export const COLORS = {
  TIMELINE_DOT_COLORS: ["blue", "gray", "green", "red"]
} as const;
export const METHOD = {
  COUNTAUDIENCEREACH:"countAudienceReach"
}

export const FEATURE_FLAGS_CONFIG = {
  DEMOGRAPHICS: { name: "demographics", url: ROUTES.DASHBOARD_DEMOGRAPHICS },
  USAGE: { name: "usage",url: ROUTES.DASHBOARD_DEMOGRAPHICS },
  TRANSACTIONAL_CAMP: { name: "transactionalCamp",url: ROUTES.DASHBOARD_TRANSACTIONALCAMPAIGN },
  CHURN: { name: "churn",url: ROUTES.DASHBOARD_CHURN},
  XAMIT: { name: "xmitAi",url: ROUTES.GENAI_DASHBOARD},
  LOYALTY: { name: "loyalty",url: ROUTES.DASHBOARD_LOYALTY},
  APP_SETTING: { name: "appsetting",url: ROUTES.DASHBOARD_APPSETTING},
  HOME: { name: "home",url: ROUTES.DASHBOARD},


  }


  export const roleCheckTypes = {
    SuperUser: 'SuperUser',
    Checker: 'Checker',
    Maker: 'Maker',
    Contributor: 'Contributor',
    NonContributor: 'NonContributor',
    Reviewer: 'Reviewer',
    Infosec: 'Infosec',
    GlobalAdmin: "GlobalAdmin",
    AppAdmin: "AppAdmin",
    Manager: "Manager",
    Marketer: "Marketer",
    UserAdminRights: "UserAdminRights",
    ContactCenter:"ContactCenter"
  } as const;


  export const FeatureSettingsFlagTypes = {
    DEMOGRAPHICS: "demographics",
    USAGE: "usage",
    REPORTS: "reports",
    APP_INBOX_REPORTS: "appInboxReports",
    MASKED_DIDS: "maskedDids",
    AUDIENCE_EXPORTS: "audience_exports",
    CHURN: "churn",
    EXP_IMG: "expImg",
    ADV_PUSH: "advPush",
    CAMP_EXCEPT_PUSH: "campExceptPush",
    TRANSACTIONAL_CAMP: "transactionalCamp",
    USER_PROFILE_IMG: "userprofileImg",
    OPTIMISATION: "optimisation",
    APP_INBOX_AND_DELIVERY_TYPE: "appInboxAndDeliveryType",
    FUNNEL_PERIOD: "funnelPeriod",
    DOCUMENTATION: "documentation",
    OUTSIDE_THE_APP: "outsideTheApp",
    CAMPAIGN_DETAILS_CSV: "campaignDetailsCsv",
    HIDE_GRANULAR_MONETARY_CACLTV: "hidegranularMonetaryCACLTV",
    COMPETING_APPS: "competingApps",
    EMBED_CUSTOM_FIELD: "embedCustomField",
    WHOWHATWHEREWHEN: "whowhatwherewhen",
    DEEP_LINK_URL: "deepLinkUrl",
    CATEGORY: "category",
    EMAIL_EDITOR: "email_editor",
    LANDING_PAGE_VIEW: "landingPageView",
    AB_TESTING_VARIANT: "ab_testing_variant",
    LANGUAGE_VARIANT: "language_variant",
    FUNNEL_LAUNCH_BTN: "funnelLaunchbtn",
    CMP_INSERT_INTO_PG: "cmpInsertInToPG",
    ADVANCED_FREQ: "advancedFreq",
    LAUNCH_CAMP_FREQ: "launchCampFreq",
    ACTION_BUTTON_SETTINGS: "actionbuttonSettings",
    SPECIAL_ATTRIBUTES: "specialattributes",
    CONVERSION_SETTINGS: "conversionSettings",
    JOURNEY_NAV_SETTINGS: "journeynavsettings",
    SETUP_APPS_SETTINGS: "setupAppsSettings",
    DELAY_LAUNCH: "delayLauch",
    REMOVE_WEB: "removeWeb",
    LIVE_EVENT_SETT: "liveEventSett",
    SCHEDULER_SETT: "schedulerSett",
    C_SEG_PHONE_EMAIL: "cSegPhoneEmail",
    XMIT_AI: "xmitAi",
    LOYALTY: "loyalty",
    DEVICE_ATTRIBUTES: "deviceAttributes",
    APP_USAGE_ATTRIBUTES: "appUsageAttributes",
    MODELS: "models",
    FUNCTIONS:"functions",
    TRAITS: "traits",
    AUDIENCE_SYNC: "audienceSync",
    ALLOW_LIST: "allowList",
    DOWNLOAD_SDK:"downloadSDK",
    PERFORMANCE_REPORTS:"performanceReports",
    MANAGE_MODULES:"manageModules",
    DISPLAY_PREFERENCE:"displayPreference",
    DELAY_CAMP:"delayCamp",
    ACQUISITION_BY_TYPE:"acquisitionByType",
    CONSENT_CHECK:"isConsentHonoured",
    INSIGHTS:"inSights",
    MODE:"isSettingsMode",
    DAY_PART:"dayPart",
    LIVE_ACTIVITY:"liveActivity",
    CAMPAIGN_SHOWEN_IN_SESSION:"campaignShowenInSession",
    INTERVAL_MESSAGE:"intervalBetweenMessage",
    LINKS:"links",
    SHORTENER:"shortener",
    BUILDER:"builder",
    CUSTOMER_360:"customer360",
    ATTRIBUTION_REPORTS:"attributionReport",
    CUSTOMER_360_MAPPING:"customer360Mapping",
    PERSONLIZATION_MAPPING:"personlizationMapping",
    FUNNEL_V2:"funnelV2",
    COHORT_CHART:"cohortChart",
    JOURNEY:"journey",
    FUNNEL:"funnel",
    APP_ACQUISTION:"appAcquistion",
    CAMP_DELETION: "campDeletion",
    TRAIT_PREVIEW:"traitPreview",
    MAKER_CHECKER:"makerChecker"






  } as const;