import React, { useEffect, useState } from "react";
import PrimaryButton from "components/Buttons/PrimaryButton/PrimaryButton";
import filterIcon from "images/_filter.svg";
import { Form, Steps } from "antd";
import "./MultiStep.css";
import { fetchTemplatesList } from "redux/features/templates_/templatesByTypeSlice";
import { setCampaignData } from "redux/features/campaign/campaignDataSlice";
import { setCurrentStep } from "redux/features/campaign/campaignCreateCurrentStepSlice";
import { v4 as uuid } from "uuid";

import { useDispatch, useSelector } from "react-redux";
import { t } from "i18next";
import useMessage from "hooks/useMessage";
import SegmentBlockLayout from "layout/SegmentBlockLayout/SegmentBlockLayout";

import { CampaignChannelTypeEnum } from "utils/enums";
import Template from "./Template/Template";
import { fetchTemplateById } from "redux/features/templates_/templateByIdSlice";
import TimeWindow from "pages/Audience/AudienceCreate/components/WindowContainers/TimeWindow/TimeWindow";
import Selector from "components/Selector/Selector";

const MultiStep: React.FC = () => {
  // Initial condition object for new funnel step
  const initialCondition = {
    order: undefined,
    channel: undefined,
    templateId: undefined,
  };

  const [template, setTemplate] = useState({
    multiStepData: [], // Setting the initial state with dummy data
  });

  const [stepIndex, setStepIndex] = useState(0); // State to track the current step

  const campaignData = useSelector((state: any) => state.campaignData.data);
  const currentStep = useSelector(
    (state: any) => state.campaignCreateCurrentStep
  );
  const loginUser = useSelector((state: any) => state.loginUser.data);
  const { appId } = useSelector((state: any) => state.activeApp);
  const { showError, showSuccess } = useMessage();
  const [form] = Form.useForm();
  const dispatch: any = useDispatch();
  const [templatesByStep, setTemplatesByStep] = useState<{
    [key: number]: any[];
  }>({});
  const [fetchedTemplatesByStep, setFetchedTemplatesByStep] = useState<any>({});
  const [selectedOrders, setSelectedOrders] = useState([]);

  //remove the filter condition
  const removeMultiStepData = () => {
    setStepIndex((prevIndex: number) => Math.max(prevIndex - 1, -1));

    setTemplate((prevState: any) => {
      // Create a copy of the previous state
      const state = { ...prevState };
      // Create a new array without mutating the original one
      state.multiStepData = state.multiStepData.slice(0, -1);

      return state;
    });
  
  };
  // Handle changes in the form and update funnel data accordingly
  const handleFormChange = (data: any) => {
    setTemplate((prevState: any) => {
      return { ...prevState, ...data }; // Merge updated form data with current state
    });
    setTemplate((prevState: any) => {
      return { ...prevState, ...data }; // Merge updated form data with current state
    });
  };
  const fetchTemplate = async (value: any, id: any) => {
    handleStepClick(id);

    try {
      const response = await dispatch(
        fetchTemplateById({
          app_id: appId,
          api_key: loginUser.api_key,
          // template_id: JSON.parse(tempString)._id,
          template_id: value,
        })
      ).unwrap();
      // Save the fetched template in the state by index (id)
      setFetchedTemplatesByStep((prevState) => ({
        ...prevState,
        [id]: response, // Save the template by step id
      }));
    } catch {}
  };

  const handleChannelChange = async (channel: any, id: any) => {
    handleStepClick(id);

    try {
      const response = await dispatch(
        fetchTemplatesList({
          app_id: appId,
          api_key: loginUser.api_key,
          template_type: channel,
        })
      );

      setTemplatesByStep((prevState) => ({
        ...prevState,
        [id]: response.payload, // Store templates per step
      }));
    } catch (error) {}
  };

  const convertToOriginalForm = (flowStructure: any) => {
    if (
      !flowStructure ||
      !flowStructure.nodes ||
      flowStructure.nodes.length === 0
    ) {
      return []; // Return an empty array if no data
    }

    const { nodes } = flowStructure;
    const multiStepData: any[] = [];

    // Find all message nodes
    const messageNodes = nodes.filter(
      (node: any) => node.type === "MessageNode"
    );

    // Process each message node and reconstruct the steps
    messageNodes.forEach((messageNode: any, index: number) => {
      const step: any = {
        order: index + 1,
        channel: messageNode.variant.type,
        templateId: messageNode.variant.templateId,
      };

      // Check for previous node type (DelayNode or SegmentSplitNode) to determine 'after'
      if (index > 0) {
        const prevNode = nodes.find(
          (node: any) => node.id === messageNodes[index - 1].child
        );

        if (prevNode) {
          if (
            prevNode.type === "DelayNode" ||
            prevNode.type === "SegmentSplitNode"
          ) {
            // Dynamically handle DelayNode and SegmentSplitNode
            if (prevNode.variant.time && prevNode.variant.type) {
              step.after = {
                event: messageNodes[index - 1].variant.type, // Use the previous message type as the event
                t_operand: "after", // 'after' is the default for delay logic
                type: prevNode.variant.type, // Use the type from the previous node (e.g., 'm', 's', 'd')
                time: prevNode.variant.time, // Use the time from the previous node
              };
            }
          }
        }
      }

      // Only add `after` if it's defined
      if (step.after) {
        multiStepData.push(step);
      } else {
        multiStepData.push({
          order: step.order,
          channel: step.channel,
          templateId: step.templateId,
        });
      }
    });

    // Return the reconstructed multiStepData without the Exit step
    return multiStepData;
  };
    // Helper function to create message nodes
    function createMessageNode(
      order: number,
      channel: string,
      templateId: string,
      id: string,
      childNodeId: string | null = null
    ) {
      return {
        id, // Use the provided ID instead of generating a new one
        type: "MessageNode",
        name: `Message ${order}`,
        variant: {
          type: channel,
          templateId,
        },
        child: childNodeId,
      };
    }

    // Helper function to create delay nodes
    function createDelayNode(type: string, time: number, childNodeId: string) {
      return {
        id: uuid(),
        type: "DelayNode",
        variant: {
          type,
          time,
        },
        child: childNodeId,
      };
    }

    // Helper function to create segment split nodes
    function createSegmentSplitNode(
      segment: string,
      trueChild: string,
      falseChild: string,
      time:number,
      type:string,
    ) {
      return {
        id: uuid(),
        type: "SegmentSplitNode",
        variant: {
          type: "Boolean",
          segment,
          trueChild,
          falseChild,
          time: {
            type:type,
            time: time
          }
        },
        
      };
    }

  function convertToFlowStructure(multiStepData: any) {
    const nodes: any = [];
    const entryNode = {
      type: "EntryNode",
      segment: uuid(), // Generate unique segment ID
      child: null,
    };
    const exitNode = {
      type: "ExitNode",
    };


    // Convert each step into the flow structure
    let previousNodeId: string | null = null;
    let nextMessageNodeId: string | null = null; // Store the ID for the next MessageNode

    multiStepData.forEach((step, index) => {
      // Generate the ID for the current MessageNode
      const messageNodeId: string = nextMessageNodeId || uuid(); // Reuse nextMessageNodeId if it exists

      // Create the MessageNode with the pre-generated ID
      const messageNode = createMessageNode(
        step.order,
        step.channel,
        step.templateId,
        messageNodeId
      );

      // Push the MessageNode to the nodes array
      nodes.push(messageNode);

      // Connect the previous node's child to the current MessageNode
      if (previousNodeId) {
        const previousNode = nodes.find((node) => node.id === previousNodeId);
        if (previousNode) {
          // Only set previousNode.child if the current step does not have a DelayNode or SegmentSplitNode
          if (!step.after || !step.after.event) {
            previousNode.child = messageNode.id;
          }
        }
      }

      // If this is the last step, point to ExitNode
      if (index === multiStepData.length - 1) {
        messageNode.child = exitNode.type;
      }

      // Check if the NEXT step has a `after.event`
      const nextStep = multiStepData[index + 1];
      if (nextStep && nextStep.after && nextStep.after.event) {
        // Create the DelayNode if there's a delay time
        let delayNodeId: string | null = null;
        if (nextStep.after.time) {
          const delayNode = createDelayNode(
            nextStep.after.type,
            nextStep.after.time,
            uuid()
          );
          delayNodeId = delayNode.id;
          nodes.push(delayNode);

          // Connect the MessageNode to the DelayNode
          messageNode.child = delayNodeId;
        }

        // Create the SegmentSplitNode if there's an event
        if (nextStep.after.event) {
          // Generate the ID for the next MessageNode (this will be the trueChild in SegmentSplitNode)
          nextMessageNodeId = uuid();

          // Create the SegmentSplitNode and link it to the next MessageNode
          const segmentSplitNode = createSegmentSplitNode(
            uuid(), // Unique segment ID for this split
            nextMessageNodeId, // True child will be the next MessageNode
            exitNode.type, // False child points to exitNode
            nextStep.after.time, // Pass time from DelayNode
            nextStep.after.type // Pass type from DelayNode
          );
          nodes.push(segmentSplitNode);

          // Connect the DelayNode (or MessageNode if no DelayNode) to the SegmentSplitNode
          if (delayNodeId) {
            const delayNode = nodes.find((node) => node.id === delayNodeId);
            delayNode.child = segmentSplitNode.id;
          } else {
            messageNode.child = segmentSplitNode.id;
          }

          // Update the previousNodeId to the SegmentSplitNode's ID
          previousNodeId = segmentSplitNode.id;
        } else {
          // If there's no SegmentSplitNode, update the previousNodeId to the DelayNode or MessageNode
          previousNodeId = delayNodeId || messageNode.id;
        }
      } else {
        // If there's no `after.event` for the next step, update the previousNodeId to the current MessageNode
        previousNodeId = messageNode.id;
      }
    });

    // Set the entryNode's child to the first MessageNode
    entryNode.child = nodes[0].id;

    // Return the full structure
    return {
      entryNode,
      exitNode,
      nodes,
    };
  }

  const onFormSubmit = async () => {
    try {
      if (template?.multiStepData.length == 1) {
        showError("please add atleast one condition");
      } else {
        // Convert the data
        const flowStructure = convertToFlowStructure(template.multiStepData);

        dispatch(
          setCampaignData({
            ...campaignData,
            multiStepData: flowStructure,

            t: CampaignChannelTypeEnum.MULTI_STEP,
          })
        );
        dispatch(setCurrentStep(currentStep + 1));
      }
    } catch (error) {
      // Show error message if something goes wrong during submission
      showError(t("somethingWrongLabel"));
    }
    // setStepsData((prevState)=>({...prevState,submitLoading:false}))
  };
   // Define your options
   const orderOptions = [
    { label: "1", value: 1 },
    { label: "2", value: 2 },
    { label: "3", value: 3 },
    { label: "4", value: 4 },
    { label: "5", value: 5 },
    { label: "6", value: 6 },
    { label: "7", value: 7 },
    { label: "8", value: 8 },
    { label: "9", value: 9 },
  ];
  const options = [
    { label: t("smsLabel"), value: CampaignChannelTypeEnum.SMS },
    { label: t("emailLabel"), value: CampaignChannelTypeEnum.EMAIL },
    { label: t("pushLabel"), value: CampaignChannelTypeEnum.PUSH },
    { label: t("firstStepJourneyLabel"), value: "firstStep" },
    { label: t("lastStepJourneyLabel"), value: "lastStep" },
  ];

  const handleStepClick = (index: number) => {
    setStepIndex(index); // Update the current step index
  };
  const handleInputChange = (data: any, index: any) => {
    // Dispatching action to update audience segment information in Redux state
    let modifiedData = data;

    if (data["attributes"] && data["attributes"]?.length == 0) {
      modifiedData = {
        ...data,
        attr: undefined,
        operator: undefined,
        value: undefined,
      };
    }

    if (data.channel) {
      handleChannelChange(data.channel, index); // Notify parent with new channel
    }
    
    setTemplate((prevState: any) => {
      const updatedDataArray = [...prevState.multiStepData]; // Copy the existing data array

      // Update the specific index with the new data
      updatedDataArray[index] = {
        ...updatedDataArray[index],
        ...modifiedData, // Merge new data with existing data at the index
      };

      return { ...prevState, multiStepData: updatedDataArray }; // Return the updated state
    });
  };
  // Function to generate funnel steps as tree items
  const getTreeItems = () => {
    const conditionItems = template?.multiStepData?.map(
      (item: any, index: number) => ({
        icon: (
          <PrimaryButton className="px-2" type="primary">
            {index === 0 ? t("allLabel") : t("andLabel")}
          </PrimaryButton>
        ),
        title: (
          <SegmentBlockLayout
            key={index}
            index={index}
            onDeleteClick={
              index === template?.multiStepData.length - 1
                ? () => removeMultiStepData()
                : undefined
            }
          >
            <div className="multi-step-box">
              <div className="box-body-container mx-1  px-1">
                <div className="d-flex flex-column py-2 ">
                  <div className="d-flex flex-wrap flex-row gap-md-4 gap-2 align-items-center py-3">
                    <div className="d-flex gap-2 align-items-center ">
                      <Form.Item
                        rules={[{ required: true, message: "" }]}
                        className="event-inputs"
                        name={`order_${index}`}
                        initialValue={item?.order}
                      >
                        <Selector
                          showSearch
                          onChange={(value: string) => {
                            handleInputChange(
                              {
                                order: value,
                              },
                              index
                            );
                            const updatedOrders = [...selectedOrders];
                            updatedOrders[index] = value;
                            setSelectedOrders(updatedOrders);
                          }}
                          style={{ width: 100 }}
                          options={orderOptions.filter(option => !selectedOrders.includes(option.value) || selectedOrders[index] === option.value)}  // Filter out selected options

                          placeholder={`${t("selectOrderLabel")}`}
                        />
                      </Form.Item>
                      <Form.Item
                        rules={[{ required: true, message: "" }]}
                        className="event-inputs"
                        name={`channel_${index}`}
                        initialValue={item?.channel}
                      >
                        <Selector
                          showSearch
                          style={{ width: 150 }}
                          onChange={(value: string) => {
                            handleInputChange(
                              {
                                channel: value,
                              },
                              index
                            );
                          }}
                          options={[
                            {
                              value: CampaignChannelTypeEnum.SMS,
                              label: t("smsLabel"),
                            },

                            {
                              value: CampaignChannelTypeEnum.PUSH,
                              label: t("pushLabel"),
                            },

                            {
                              value: CampaignChannelTypeEnum.EMAIL,
                              label: t("emailPushLabel"),
                            },
                          ]}
                          placeholder={`${t("selectChannelLabel")}`}
                        />
                      </Form.Item>
                      <Form.Item
                        rules={[{ required: true, message: "" }]}
                        className="event-inputs"
                        name={`templateId_${index}`}
                        initialValue={item?.templateId}
                      >
                        <Selector
                          showSearch
                          style={{ width: 300 }}
                          onChange={(value: string) => {
                            handleInputChange(
                              {
                                templateId: value, // `value` will now contain the `_id`
                              },
                              index
                            );
                            fetchTemplate(value, index);
                          }}
                          options={templatesByStep[index]?.map(
                            ({
                              template_name,
                              _id,
                            }: {
                              template_name: string;
                              _id: string;
                            }) => ({
                              label: template_name, // Display name in the dropdown
                              value: _id, // Set the ID as the value
                            })
                          )}
                          placeholder={`${t("selectTemplateLabel")}`}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div>
                    <div>
                      <PrimaryButton disabled={true} className="mb-2">
                        Send {item.channel} template
                      </PrimaryButton>
                    </div>
                    <div className="time-window">
                      {index !== 0 && (
                        <TimeWindow
                          form={form}
                          eventList={options}
                          setValue={(value) => {
                            handleInputChange(
                              {
                                after: { ...item?.after, ...value },
                              },
                              index
                            );
                          }}
                          id={index}
                          value={item?.after}
                          showAfterLabel={true}
                        ></TimeWindow>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SegmentBlockLayout>
        ),
      })
    );
    // Button to add a new  step (condition)
    const addConditionButton = {
      id: null,
      icon: (
        <PrimaryButton
          type="primary"
          onClick={() => {
            const lastChannel =
              template?.multiStepData?.[template?.multiStepData.length - 1]
                ?.channel || null;

            // Add new step with the same channel as the last step
            handleFormChange({
              multiStepData: [
                ...(template?.multiStepData || []),
                {
                  ...initialCondition,
                  after: {
                    event: lastChannel, // Copy the event from the last step to the new step
                  },
                },
              ],
            });
          }}
          disabled={template?.multiStepData?.length >= 5} //ifselected steps length > 5
        >
          {t("addConditionLabel")}
        </PrimaryButton>
      ),
      title: "",
    };
    return [...(conditionItems || []), addConditionButton];
  };

  useEffect(() => {
    if (campaignData) {
      // Destructure multiStepData from campaignData.data
      const { multiStepData } = campaignData;
      // Step 1: Convert multiStepData if needed
      const multiData = convertToOriginalForm(multiStepData);
      // Step 2: Update template state with the new multiStepData (converted)
      setTemplate({
        multiStepData: multiData || [], // Fallback to empty array if no data
      });

      // Fetch templates for each step
      multiData?.forEach((step: any, index: any) => {
        if (step.templateId) {
          handleChannelChange(step.channel, index);
          // Fetch the template for this specific step by its templateId
          fetchTemplate(step.templateId, index); // Fetch template based on step order or any other identifier
          handleStepClick(index);
        }
      });
    }
  }, [campaignData]); // This hook will run every time campaignData changes

  return (
    <div className="container-fluid multi-step-create-container px-1">
      <div
        className="px-sm-3 py-4 px-2 d-flex flex-column "
        style={{ background: "var(--color-other-white)" }}
      >
        <Form form={form} onFinish={onFormSubmit}>
          <div className="d-flex  justify-content-between gap-1 align-items-start ">
            {/* for icon and title of header */}
            <div className="d-flex align-items-center flex-wrap justify-content-between w-100">
              <div className="d-flex   align-items-center">
                <span className="d-flex   filter-icon-container justify-content-center ">
                  <img
                    src={filterIcon}
                    height={"100%"}
                    width={"100%"}
                    alt="filter"
                  ></img>
                </span>
                <span>
                  <h5 className="m-0 mx-2">{t("multiStepCampaignlLabel")}</h5>
                </span>
              </div>
            </div>
            <PrimaryButton className="mt-1" htmlType="submit" type="primary">
              {t("saveLabel")}
            </PrimaryButton>
          </div>
          <div className="mt-3">
            <strong className="mb-2">
              Select the order in which you want to execute multi-step campaign
            </strong>
          </div>

          {/* steps starts from here */}

          <div className="d-flex ">
            <div className="col-sm-8">
              <Steps
                direction="vertical"
                current={100}
                items={getTreeItems()}
              />
            </div>
            <div className="col-sm-4">
              <div>
                {fetchedTemplatesByStep ? (
                  <Template
                    templateSelected={fetchedTemplatesByStep}
                    stepIndex={stepIndex} // Pass the step index
                    totalSteps={template?.multiStepData?.length} // Pass total number of steps for conditional styling
                  />
                ) : null}
              </div>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default MultiStep;
