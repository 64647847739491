import { Link } from "react-router-dom";
import appiceIcon from "../../images/Appice_Logo_new.png";
import "./HeaderNav.css";
import { Avatar, Dropdown, MenuProps } from "antd";
import { CaretDownFilled, LogoutOutlined, ProfileOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import CustomModal from "../Models/CustomModel/CustomModel";
import { t } from "i18next";
import { logoutUser } from "redux/features/user/loginUserSlice";
import { ROUTES } from "utils/constants";

interface HeaderNavProps {
  backgroundColor: string;
  name: string;
  onclick: () => void;
}

function HeaderNav({ backgroundColor, name, onclick }: HeaderNavProps) {
  const loginUser = useSelector((state: any) => state.loginUser);
  const [logoutModelOpen,setLogoutModelOpen]=useState(false)
  const activeApp = useSelector((state: any) => state.activeApp);
  const dispatch:any = useDispatch();
  const items: MenuProps["items"] = [
    {
      key: "1",
      label: (
        
        <Link className="HeaderNav_links" to={"/dashboard/editProfile"}>
           <span className="d-flex align-items-center gap-2">
           <ProfileOutlined  />
            <span>Profile</span>
          </span>

        </Link>
      ),
    },
    {
      key: "2",
      label: (
        <Link
          className="HeaderNav_links pointer-cursor"
          onClick={() => setLogoutModelOpen(true)}
          to={"#"}
        >
          <span className="d-flex align-items-center gap-2">
            <LogoutOutlined />
            <span>Logout</span>
          </span>
        </Link>
      ),
    },
  ];
  

  return (
    <nav
      className="navbar top-navbar-main-container navbar-expand-lg bg-body-tertiary px-3"
      style={{ backgroundColor: backgroundColor }}
    >
      <CustomModal
      open={logoutModelOpen}
      closable={false}
       title={t("logoutLabel")}
       onCancel={()=>setLogoutModelOpen(false)}
       onOk={()=>{dispatch(logoutUser());setLogoutModelOpen(false)}}
       okText={t("logoutLabel")}
       cancelText={undefined}
      >
{t("areYouSureMsg")}
      </CustomModal>
      <div className="container-fluid d-flex">
        <div>
          <span className="mx-sm-0 mx-2"></span>
          <Link className="navbar-brand" to={`${ROUTES.DASHBOARD}`}>
            <img
              className="appice-icon"
              src={appiceIcon}
              alt="logo"
              height={35}
            />
          </Link>
        </div>
        <div>
          {loginUser.data.full_name ? (
            <div>
              <Dropdown
                menu={{ items }}
                trigger={["click"]}
                placement="bottomRight"
              >
                <span style={{ marginLeft: "5px" }} onClick={() => {}}>
                  <Avatar
                    alt={loginUser.data.full_name}
                    src={loginUser.data.userpicurl}
                  />

                  <span
                    style={{
                      marginLeft: "5px",
                      marginRight: "2px",
                      cursor: "pointer",
                    }}
                  >
                    <span className="d-sm-inline d-none">
                      {" "}
                      {loginUser.data.full_name}{" "}
                    </span>
                  </span>
                  <CaretDownFilled style={{ fontSize: "10px" }} />
                </span>
              </Dropdown>
            </div>
          ) : (
            loginUser.status !== "loading" && <Link to={"/login"}>Login</Link>
          )}
        </div>
      </div>
    </nav>
  );
}

export default HeaderNav;
HeaderNav.defaultProps = {
  backgroundColor: "white",
  name: "Guest",
  onclick: () => {},
};
