import React, { useEffect, useState } from "react";
import { Button, Divider, Form, Radio } from "antd";
import TextInput from "../../../components/Inputs/TextInput/TextInput";
import Selector from "../../../components/Selector/Selector";
import PrimaryButton from "../../../components/Buttons/PrimaryButton/PrimaryButton";
import Spinner from "../../../components/Spinner/Spinner";
import { Helmet } from "react-helmet";
import { t } from "i18next";
import { fetchEventList } from "redux/features/event/eventListSlice";
import { useDispatch, useSelector } from "react-redux";
import { getJourneyTypeList } from "helperFunctions/events";
import "./JourneyManagement.css";
import centralApi from "services/centralApi";
import { API_ENDPOINTS } from "utils/constants";
import useMessage from "hooks/useMessage";
import { DataTypeEnum } from "utils/enums";
import { getToken } from "redux/features/app/authTokenSlice";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import CustomCheckbox from "components/CustomCheckbox/CustomCheckbox";
import CustomTable from "components/CustomTable/CustomTable";

const JourneyManagement: React.FC = () => {
  const api_key = useSelector((state: any) => state.loginUser.data.api_key);
  const dispatch: any = useDispatch(); // Dispatch function from Redux
  const { showError, showSuccess } = useMessage(); // Message hooks for success/error messages
  const [form1] = Form.useForm(); // Form instance for "Add new journeys"
  const [form2] = Form.useForm(); // Form instance for "Add attributes"
  const [form3] = Form.useForm(); // Form instance for "Add events"

  const [selectedJourney, setSelectedJourney] = useState<string>(""); // State for selected journey
  const [allEventList, setAllEventList] = useState<any>([]);
  const [loading, setLoading] = useState(false); // Loading state for API requests
  const loginUser = useSelector((state: any) => state.loginUser.data); // Get logged-in user data from Redux state
  const authToken = useSelector((state: any) => state.authToken.token);
  const { appId } = useSelector((state: any) => state.activeApp); // Get active app ID from Redux state
  const [selectedJourneyAttributes, setSelectedJourneyAttributes] =
    useState<string>(""); // State for selected attribute journey

  interface SelectedEventData {
    event: string; // Event name, initially empty
    journey: string; // Journey name
    step: string; // Step in the journey
    isDisplay: any; // Visibility flag
    list: Array<{ name: string; type: string }>; // List of attributes associated with the event
  }
  const initialSelectedDataEvent: SelectedEventData = {
    event: "", // Event name, initially empty
    journey: "", // Journey name
    step: "", // Step in the journey
    isDisplay: undefined, // Visibility flag
    list: [], // List of attributes associated with the event
  };

  // Use state with the initial data
  const [selectedEventData, setSelectedEventData] = useState<SelectedEventData>(
    initialSelectedDataEvent
  );

  const filteredEventsOnJourneyBased = allEventList?.filter(
    (e: any) => e.journey === selectedJourney
  );
  const [selectedEventDataAttributes, setSelectedEventDataAttributes] =
    useState<SelectedEventData>(initialSelectedDataEvent);

  
  const filteredEventsOfAttributes = allEventList?.filter(
    (e: any) =>
      e.journey === selectedJourneyAttributes &&
      e.isDisplay !== null &&
      e.isDisplay !== false
  );

  //to get all event list irrespective of isDisplay false
  async function getEventList() {
    const payload = {
      app_id: appId,

      api_key: api_key,
      showAll: true, //to show all events
    };
    if (appId && api_key) {
      try {
        setLoading(true);
        const data = await centralApi(
          "GET",
          API_ENDPOINTS.GET_ALL_EVENTS_LIST,
          null,
          payload
        );
        // Keep the entire item object and filter out items where `event` is undefined or empty
        const filteredEventLists = data?.data?.filter(
          (item: any) => item?.event && item?.event?.trim() !== ""
        );
        setAllEventList(filteredEventLists);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }
  }

  useEffect(() => {
    getEventList();
  }, []);
  // Generic function to handle API requests
  async function handleApiRequest(endpoint: string, values: any, form: any) {
    const payload = {
      args: JSON.stringify({
        app_id: appId, // Include app ID in payload
        ...values, // Spread other values into payload
      }),
      authToken,
      api_key: loginUser.api_key, // Include API key from logged-in user
      app_id: appId, // Include app ID again for consistency
    };

    try {
      setLoading(true); // Set loading state to true
      await centralApi("POST", endpoint, payload, null); // Make the API request
      showSuccess(t("sucessfullySavedLabel")); // Show success message
      // Fetch updated event list and token
      getEventList();
      dispatch(fetchEventList({ api_key: loginUser.api_key, app_id: appId }));
      dispatch(getToken());
      setSelectedEventData(initialSelectedDataEvent);
      form.resetFields(); // Reset the form fields after submission
    } catch (error) {
      // Handle any errors that occur during the API request
      showError(t("somethingWrongLabel")); // Show error message
    } finally {
      setLoading(false); // Ensure loading state is reset
    }
  }

  // Handle adding a new journey
  async function handleAddJourney(values: any) {
    await handleApiRequest(API_ENDPOINTS.ADD_JOURNEY, values, form1);
  }

  // Handle adding an attribute to an event
  async function handleAddAttribute() {
    const allAttributes = {
      attributes: selectedEventDataAttributes?.list, // Use list from selectedEventData for attributes

      event: selectedEventDataAttributes?.event, // Include the selected event in the attributes
      journey: selectedJourneyAttributes,
      isDisplay: selectedEventDataAttributes?.isDisplay || true,
    };
    await handleApiRequest(
      API_ENDPOINTS.ADD_ATTRIBUTE_TO_EVENT,
      allAttributes,
      form2
    );
  }

  // Handle adding or updating an event
  async function handleAddEvent(values: any) {
    const event = {
      event: selectedEventData?.event || values.event, // Use selected event or the value from the form
      journey: selectedJourney, // Include the selected journey
      step: values.step, // Include the step value from the form
      isDisplay: selectedEventData?.isDisplay,
    };
    const updateEvent = {
      selectedEvent: selectedEventData?.event,
      event: values.event, // If updating, include the selected event
      journey: selectedJourney, // Include the selected journey
      step: values.step == "none" ? "" : values.step, // Include the step value from the form
      isDisplay: selectedEventData?.isDisplay,
    };
    // Determine whether to add or update the event based on selectedEvent
    selectedEventData?.event
      ? await handleApiRequest(API_ENDPOINTS.UPDATE_EVENT, updateEvent, form3)
      : await handleApiRequest(
          API_ENDPOINTS.ADD_EVENT_TO_JOURNEY,
          event,
          form3
        );
  }

  // Effect to fetch event list when the component mounts or when the user API key/app ID changes
  useEffect(() => {
    if (loginUser.api_key && appId) {
      dispatch(fetchEventList({ api_key: loginUser.api_key, app_id: appId }));
    }
  }, [loginUser]); // Dependency array to re-run effect when loginUser changes
  const innerContainer = {
    backgroundColor: "var(--color-other-white)", // Style for the inner container
  };
  useEffect(() => {
    form3.setFieldsValue({
      event: selectedEventData?.event || undefined, // Use undefined to allow placeholder
      step: selectedEventData?.step || undefined, // Use undefined to allow placeholder
      isDisplay: selectedEventData?.isDisplay,
    });
  }, [selectedEventData]);
  // When the selected event changes, update the form values accordingly
  useEffect(() => {
    // Set initial values for the form, based on the selected event and attributes
    if (selectedEventData?.event) {
      // If editing an existing event, populate the form fields with data
      const selectedAttribute = selectedEventData?.list.find(
        (attr: any) => attr.name === selectedEventData?.event
      );
      form2.setFieldsValue({
        event: selectedAttribute ? selectedAttribute.name : "",
        type: selectedAttribute ? selectedAttribute.type : "",
      });
    }
  }, [selectedEventData]); // Re-run when selectedEvent or attributes change

  // Handle adding a new attribute
  const handleAddAttributesFields = () => {
    const newAttribute = { name: "", type: "" }; // Default structure for a new attribute

    setSelectedEventDataAttributes((prevState) => ({
      ...prevState,
      list: [...prevState.list, newAttribute], // Add the new attribute to the list
    }));
  };

  // Handle updating an attribute
  const handleUpdateAttribute = (index: number, key: string, value: any) => {
    const updatedList: any = [...selectedEventDataAttributes.list];

    updatedList[index][key] = value;

    setSelectedEventDataAttributes((prevState) => ({
      ...prevState,
      list: updatedList,
    }));
  };
  const handleRemoveAttribute = (index: number) => {
    setSelectedEventDataAttributes((prevState) => {
      const updatedList = prevState.list.filter((_, i) => i !== index); // Exclude the attribute at the given index
      return { ...prevState, list: updatedList }; // Update the state
    });
  };

  const attributeColumns = [
    {
      title: t("attributeNameLabel"),
      dataIndex: "name",
      render: (text, record, index) => (
        <TextInput
          value={record.name}
          onChange={(e) => handleUpdateAttribute(index, "name", e.target.value)}
          style={{ width: 180 }}
          placeholder={t("attributeNameLabel")}
        />
      ),
    },
    {
      title: t("typeLabel"),
      dataIndex: "type",
      render: (text, record, index) => (
        <Selector
          value={record.type}
          onChange={(value) => handleUpdateAttribute(index, "type", value)}
          allowClear
          style={{ width: 200 }}
          placeholder={t("typeLabel")}
          options={[
            {
              label: t("booleanLabel"),
              value: DataTypeEnum.BOOLEAN,
            },
            {
              label: t("numberLabel"),
              value: DataTypeEnum.NUMBER,
            },
            {
              label: t("stringLabel"),
              value: DataTypeEnum.STRING,
            },
          ]}
        />
      ),
    },
    {
      title: t("displayLabel"),
      dataIndex: "isDisplay",
      render: (text, record, index) => (
        <CustomCheckbox
          checked={record.isDisplay !== undefined ? record.isDisplay : true} // If undefined, set to true
          onChange={(e) =>
            handleUpdateAttribute(index, "isDisplay", e.target.checked)
          }
        />
      ),
    },
    {
      title: t("actionLabel"),
      key: "actions",
      render: (text, record, index) => (
        <div className="d-flex">
          {index === selectedEventDataAttributes.list.length - 1 && (
            <Button
              type="text"
              icon={<CloseOutlined />}
              onClick={() => handleRemoveAttribute(index)}
              style={{ marginRight: 8 }}
            />
          )}
          <Button
            type="text"
            icon={<PlusOutlined />}
            onClick={handleAddAttributesFields}
          />
        </div>
      ),
    },
  ];
  const eventColumns = [
    {
      title: t("eventNameLabel"), // You can replace this with the appropriate translation key
      dataIndex: "event",
      key: "event",
      render: (text) => <p>{text}</p>, // Display the event name
    },
    {
      title: t("stepLabel"), // Replace with the appropriate translation key
      dataIndex: "step",
      key: "step",
      render: (text) => <p>{text}</p>, // Display the step
    },
    {
      title: t("viewLabel"), // Replace with the appropriate translation key
      render: (text, record) => (
        <Radio.Group
          value={selectedEventDataAttributes?.event}
          onChange={(e) => handleActionClick(e, record.event)} // Pass `item.event` to handler
        >
          <Radio value={record.event} />
        </Radio.Group>
      ),
    },
  ];
  // Handler for action button click
  const handleActionClick = (e, eventName) => {
    const selectedEventObject = filteredEventsOfAttributes.find(
      (e: any) => e?.event === eventName
    );

    // Set the selected event
    setSelectedEventDataAttributes(selectedEventObject);
  };
  const dataSource = selectedEventDataAttributes?.list?.map((item, index) => ({
    ...item,
    key: index, // Or use a unique identifier from your data
  }));
  return (
    <div className="container-fluid journey-management-container px-1">
      <Helmet>
        <title>Appice | Journeys</title>
      </Helmet>

      <div className="d-flex gap-2 justify-content-between flex-wrap my-3">
        <h4 className="fw-semi-bold">{t("journeyManagementLabel")}</h4>
      </div>
      <div className="container-fluid p-sm-4 p-3 " style={innerContainer}>
        {loading ? (
          <Spinner />
        ) : (
          <>
            <div className="d-flex gap-2 align-items-baseline journey-management-container-heading">
            <div className="journey-management-container-number">1</div>

              <h6 >
               

                {t("mapEventsLabel")}
              </h6>
            </div>
            <div className="">
            </div>
            <Divider className="journey-management-custom-divider" />
            <Form form={form1} layout="vertical" onFinish={handleAddJourney}>
              <div className="d-flex gap-2 ">
                <div className="d-flex gap-2 flex-column  align-items-baseline">
                  <div className="d-flex gap-2 ">
                    <div className="d-flex gap-2   ">
                      <Form.Item
                        name={"event"}
                        rules={[
                          {
                            required: true,
                            message: t("selectEventLabel") as string,
                          },
                        ]}
                      >
                        <Selector
                          style={{ width: 200 }}
                          placeholder={t("selectEventLabel")}
                          options={allEventList?.map((event: any) => ({
                            label: event.event, // event name
                            value: event.event, // Unique identifier for each option
                          }))}
                        ></Selector>
                      </Form.Item>
                    </div>
                    <Form.Item
                      name={"journey"}
                      rules={[
                        {
                          required: true,
                          message: t("journeyNameLabel") as string,
                        },
                      ]}
                    >
                      <TextInput
                        name="journey"
                        placeholder={t("journeyNameLabel")}
                      />
                    </Form.Item>

                    <div className="journey-management-container-button">
                      <PrimaryButton
                        className="journey-management-container-button"
                        type="primary"
                        htmlType="submit"
                      >
                        {t("addJourneyLabel")}
                      </PrimaryButton>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
           
            <div className="mt-4 d-flex gap-2 align-items-baseline journey-management-container-heading">
            <div className="journey-management-container-number">2</div>

              <h6 >

                {t("selectOrAddEventLabel")}{" "}
              </h6>
            </div>
          

            <Divider className="journey-management-custom-divider" />
            <Form form={form3} layout="vertical" onFinish={handleAddEvent}>
             
              <div className="d-flex gap-2   ">
                <Form.Item>
                  <Selector
                    allowClear
                    onChange={(value) => {
                      setSelectedJourney(value);
                      setSelectedEventData(initialSelectedDataEvent);
                    }}
                    style={{ width: 200 }}
                    placeholder={t("selectJourneyLabel")}
                    options={getJourneyTypeList(allEventList)}
                  />
                </Form.Item>
                <Form.Item>
                  <Selector
                    style={{ width: 220 }}
                    placeholder={t("selectEventLabel")}
                    value={selectedEventData?.event || undefined} // Controlled value
                    options={filteredEventsOnJourneyBased?.map((e: any) => {
                      return { label: e?.displayname, value: e?.event };
                    })}
                    onChange={(value) => {
                      // Find the selected event object
                      const selectedEventObject =
                        filteredEventsOnJourneyBased.find(
                          (e: any) => e?.event === value
                        );

                      // Set the selected event
                      setSelectedEventData(selectedEventObject);
                    }}
                  ></Selector>
                </Form.Item>
                <Form.Item name="isDisplay">
                  <CustomCheckbox
                    onChange={(e: any) => {
                      setSelectedEventData((prevState: any) => {
                        return {
                          ...prevState,
                          isDisplay: e.target.checked, // Assuming the checkbox uses `e.target.checked` for the boolean value
                        };
                      });
                    }}
                    checked={
                      selectedEventData?.event
                        ? selectedEventData?.isDisplay === null
                          ? false
                          : selectedEventData?.isDisplay ?? true
                        : false
                    }
                    label="Set display"
                  />
                </Form.Item>
              </div>
              <div className="d-flex gap-2 ">
                <div className="d-flex gap-2 flex-column  align-items-baseline">
                  <div className="d-flex gap-2 ">
                    <Form.Item
                      name={"event"}
                      rules={[
                        {
                          required: selectedEventData?.event ? false : true,
                          message: t("eventNameLabel") as string,
                        },
                      ]}
                    >
                      <TextInput
                        style={{ width: 220 }}
                        value={selectedEventData?.event}
                        placeholder={t("eventNameLabel")}
                        disabled={true}

                      />
                    </Form.Item>

                    <p className="mt-2">is</p>
                    <Form.Item name="step">
                      <Selector
                        style={{ width: 180 }}
                        placeholder={t("selectStepLabel")}
                        options={[
                          {
                            label: t("noneLabel"), // This is the display label in the dropdown
                            value: "none", // This is the  value for that option
                          },
                          {
                            label: t("firstStepJourneyLabel"),
                            value: "firstStep",
                          },
                          {
                            label: t("lastStepJourneyLabel"), // This is the display label in the dropdown
                            value: "lastStep", // This is the  value for that option
                          },
                        ]}
                      ></Selector>
                    </Form.Item>

                    <div className="journey-management-container-button">
                      <PrimaryButton
                        className="journey-management-container-button"
                        htmlType="submit"
                        type="primary"
                        disabled={selectedJourney == ""}
                      >
                        {selectedEventData?.event
                          ? t("updateLabel")
                          : t("addEventLabel")}
                      </PrimaryButton>
                    </div>
                  </div>
                </div>
              </div>
            </Form>

            <div className="d-flex gap-2 ">
              <div className="mt-4 col-sm-6 ">
               
                <div className="d-flex gap-2 align-items-baseline journey-management-container-heading">
            <div className="journey-management-container-number">3</div>

              <h6 >

                {t("validateEventsLabel")}{" "}
              </h6>
            </div>
                <div>
                  
                  <Form.Item>
                    <Selector
                    className="mt-2"
                      allowClear
                      onChange={(value) => {
                        setSelectedJourneyAttributes(value);
                        setSelectedEventDataAttributes(value);
                      }}
                      style={{ width: 300 }}
                      placeholder={t("selectJourneyLabel")}
                      options={getJourneyTypeList(allEventList)}
                    />
                  </Form.Item>
                  {selectedJourneyAttributes && (
                    <CustomTable
                      dataSource={filteredEventsOfAttributes}
                      columns={eventColumns}
                      rowKey="event" // Assuming each event is unique by its 'event' field
                      bordered={false} // If you don't want column borders
                      pagination={false} // Disable pagination if needed
                    />
                  )}
                </div>
              </div>

              <div className="col-sm-6">
                <div className="mt-4 ">
                 
                  <div className="d-flex gap-2 align-items-baseline journey-management-container-heading">
            <div className="journey-management-container-number">4</div>

              <h6 >

                {t("addRemoveAttributeLabel")}{" "}
              </h6>
            </div>
                  <p className="mt-2 mb-4"> {t("selectEventToEditLabel")} </p>
                  <div className=" journey-management-container-table ">
                    {dataSource && dataSource.length > 0 ? (
                      <>
                        <CustomTable
                          dataSource={dataSource}
                          columns={attributeColumns}
                          pagination={false} // Disable pagination if you want to control it manually
                          rowKey="key" // Ensure each row has a unique key
                        />{" "}
                      </>
                    ) : null}
                    {selectedJourneyAttributes && (
                      <div className=" w-100 mt-4 d-flex gap-2 ms-auto">
                        {dataSource && dataSource.length == 0 && (
                          <PrimaryButton
                            className="journey-management-container-button"
                            onClick={() => handleAddAttributesFields()}
                            type="primary"
                            disabled={selectedEventDataAttributes?.event == ""}
                          >
                            {t("addAttributesLabel")}
                          </PrimaryButton>
                        )}
                        {dataSource && (
                          <PrimaryButton
                            className=" journey-management-container-button "
                            htmlType="submit"
                            onClick={handleAddAttribute}
                            type="primary"
                            disabled={selectedEventDataAttributes?.event == ""}
                          >
                            {t("saveLabel")}
                          </PrimaryButton>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default JourneyManagement;
