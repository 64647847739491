import { Divider, Form } from "antd";
import PrimaryButton from "components/Buttons/PrimaryButton/PrimaryButton";
import CustomTextArea from "components/Inputs/CustomTextArea/CustomTextArea";
import TextInput from "components/Inputs/TextInput/TextInput";
import Selector from "components/Selector/Selector";
import Spinner from "components/Spinner/Spinner";
import useMessage from "hooks/useMessage";
import { t } from "i18next";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getToken } from "redux/features/app/authTokenSlice";
import centralApi from "services/centralApi";
import { API_ENDPOINTS } from "utils/constants";
import { BOOLEAN_OPTIONS } from "utils/constants/selectorOptions";
import {AnalyticsTypeEnum} from "utils/constants/index";

export const Diagnostic = () => {
  const [loading, setLoading] = useState<boolean>(BOOLEAN_OPTIONS.FALSE);
  const loginUser = useSelector((state: any) => state.loginUser.data);
  const [form] = Form.useForm();
  const { showError } = useMessage();
  const dispatch: any = useDispatch();
  const [logsData, setLogsData] = useState("");
  const { appId } = useSelector((state: any) => state.activeApp);
  const [type, setType] = useState(""); 

  /**
   *
   * @param values take app_id from values
   * returns success when collection and tables are created
   */
  const onFormSubmit = async (values: any) => {
    try {
      const { id, type,campId } = values;

      const payload = {
        args: JSON.stringify({
          id: id,
          type: type,
          app_id: appId,
          campId:campId
        }),

        api_key: loginUser.api_key,
      };
      setLoading(BOOLEAN_OPTIONS.TRUE);
      const url = API_ENDPOINTS.SEARCH_DID_URL;
      const data = await centralApi("GET", url, null, payload);
      setLogsData(data);
      await dispatch(getToken());
      setLoading(BOOLEAN_OPTIONS.FALSE);
      return;
    } catch (error) {
      setLoading(BOOLEAN_OPTIONS.FALSE);
      showError(t("somethingWrongLabel"));
    }
  };

  return (
    <div className="decryption-notification-container">
      <div className="mt-4 mb-4  ">
        <h6 className="decryption-notification-container-heading">
          Diagnostics
        </h6>
        <Divider className="decryption-notification-custom-divider" />
      </div>
      {loading ? (
        <Spinner />
      ) : (
        <Form form={form} layout="horizontal" onFinish={onFormSubmit}>
          <div className="d-flex gap-2">
            <Form.Item
              name={"type"}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Selector
                size="middle"
                style={{ width: 200 }}
                onChange={(value)=>setType(value)} // Track the selected value

                options={[
                  {
                    value: AnalyticsTypeEnum.TRAIT,
                    label: t("traitsLabel"),
                  },
                  {
                    value: "campaign",
                    label: t("campaignLabel"),
                  },
                  {
                    value: AnalyticsTypeEnum.CSVUPLOAD,
                    label: t("csvLabel"),
                  },
                  {
                    value: AnalyticsTypeEnum.EXPORT,
                    label: t("exportLabel"),
                  },
                  {
                    value: "frequency",
                    label: t("frequencyLabel"),
                  },
                  {
                    value: "appInbox",
                    label: t("appInboxLabel"),
                  },
                ]}
              />
            </Form.Item>
            <Form.Item
              name={"id"}
              className="w-50"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <TextInput placeholder="id" />
            </Form.Item>
            {type === "frequency" && (      <Form.Item
              name={"campId"}
              className="w-50"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <TextInput placeholder="camp id" />
            </Form.Item>)}
          </div>
          <PrimaryButton
            size="middle"
            type="primary"
            htmlType="submit"
            style={{ border: "none", width: "120px" }}
          >
            {t("startDiagnosticLabel")}
          </PrimaryButton>

          <div className="d-flex w-50 justify-content-end"></div>
        </Form>
      )}
      {logsData && (
        <div className="mt-2">
          <CustomTextArea value={logsData} rows={18} />
        </div>
      )}
    </div>
  );
};
