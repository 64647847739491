import { Form } from "antd";
import { t } from "i18next";
import { Helmet } from "react-helmet";
import {
  validateConfirmPassword,
  validatePassword,
} from "../../../validator/antdValidator";
import "./ResetPassword.css";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import centralApi from "../../../services/centralApi";
import useMessage from "hooks/useMessage";
import Spinner from "components/Spinner/Spinner";
import TextInput from "components/Inputs/TextInput/TextInput";
import PrimaryButton from "components/Buttons/PrimaryButton/PrimaryButton";
import { API_ENDPOINTS, ROUTES } from "utils/constants";
import { useForm } from "antd/es/form/Form";
function ResetPassword() {
  const location = useLocation();
  const navigate = useNavigate();
  const [csrfToken, setCsrfToken] = useState("");
  const [loading, setLoading] = useState(true);
  const [prid, setPrid] = useState("");
  const { showError } = useMessage();
  const [form] = useForm();
  //fetch reset password page data
  const fetchResetPassword = async () => {
    try {
      const prid = location?.search?.split("=")[1];
      setPrid(prid);
      const res = location.search
        ? await centralApi(
            "GET",
            `${API_ENDPOINTS.GET_RESET_URL + location.search}`
          )
        : navigate(ROUTES.FORGET);
      setCsrfToken(res?.csrfToken);
      setLoading(false);
      if (res.redirect) {
        navigate(res.redirectPath);
      }
    } catch (error) {
      setLoading(false);
    }
  };
  //submits the new password
  const handleSubmit = async ({
    newPassword,
    confirmNewPassword,
    _csrf,
  }: any) => {
    try {
      const body = {
        password: btoa(newPassword),
        again: btoa(confirmNewPassword),
        prid,
        _csrf,
      };
      const { redirect, redirectPath, message } = await centralApi(
        "POST",
        API_ENDPOINTS.RESET_URL,
        body
      );
      if (redirect) {
        navigate(redirectPath);
      }
      if(!redirect){
        
        showError(message || t("somethingWrongLabel"));

      }
    } catch (error: any) {
      const message = error?.response?.data?.message;
      showError(message || t("somethingWrongLabel"));
    }
  };
  useEffect(() => {
    fetchResetPassword();
  }, []);
  return (
    <div className="container-fluid p-0 reset-password-container d-flex justify-content-center align-items-center  h-100">
      <Helmet>
        <title>Appice | Reset Password</title>
      </Helmet>
      {loading ? (
        <Spinner />
      ) : (
        <div
          className="  p-5 reset-password-form-wrapper"
          style={{ background: "var(--color-other-white)", width: "500px" }}
        >
          <h4
            className="pb-2 text-center"
            style={{ fontWeight: "var(--font-weight-heading-strong)" }}
          >
            {t("signInTitle")}
          </h4>
          <Form
            onFinish={handleSubmit}
            form={form}
            initialValues={{ _csrf: csrfToken }}
            layout="vertical"
            validateTrigger={["onBlur"]}
          >       
                <Form.Item name="_csrf" hidden>
                  <TextInput />
                </Form.Item>
                <Form.Item
                  name="newPassword"
                  rules={[{ validator: validatePassword }]}
                >
                  <TextInput
                    type="password"
                    placeholder={t("newPasswordLabel")}
                  />
                </Form.Item>
                <Form.Item
                  name="confirmNewPassword"
                  rules={[
                    ({ getFieldValue }) => ({
                      validator: (_, value) =>
                        validateConfirmPassword(
                          _,
                          value,
                          getFieldValue("newPassword")
                        ),
                    }),
                  ]}
                >
                  <TextInput
                    type="password"
                    placeholder={t("confirmNewPasswordLabel")}
                  />
                </Form.Item>
            <Form.Item>
              <PrimaryButton
                type="primary"
                className="w-100 mt-2 "
                style={{ height: 40 }}
                htmlType="submit"
              >
                {t("resetPassword")}
              </PrimaryButton>
            </Form.Item>
          </Form>
          <div className=" d-flex flex-column text-center pt-2">
            <span className="pt-2">{t("appiceRightsLabel")}</span>
          </div>
        </div>
      )}
    </div>
  );
}

export default ResetPassword;
