import { useEffect, useState } from "react";
import { Layout } from "antd";
import HeaderNav from "../components/HeaderNav/HeaderNav";
import PanelSideNav from "../components/PanelSideNav/PanelSideNav";
import UserManagement from "../pages/UserManagement/UserManagement";
import { Content } from "antd/es/layout/layout";
import Campaign from "../pages/Campaign/Campaign";
import AppUsers from "../pages/AppUsers/AppUsers";
import { useDispatch, useSelector } from "react-redux";
import FunnelsTable from "../pages/Funnels/FunnelsTable/FunnelsTable";
import Journeys from "../pages/Journeys/JourneysTable/JourneysTable";
import CreateFunnels from "../pages/Funnels/CreateFunnel/CreateFunnels";
import JourneysCreate from "../pages/Journeys/JourneysCreate/JourneysCreate";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import SignUp from "../pages/Auth/SignUp/SignUp";
import Login from "../pages/Auth/Login/Login";
import Verification from "../pages/Auth/Verification/Verification";
import AppDashboard from "../pages/Dashboard/AppDashboard/AppDashboard";
import Home from "../pages/Dashboard/Home/Home";
import ResetPassword from "../pages/Auth/ResetPassword/ResetPassword";
import ForgetPassword from "../pages/Auth/ForgetPassword/ForgetPassword";
import { EditProfile } from "../pages/EditProfile/EditProfile";
import DisplayPrefrence from "../pages/DisplayPrefrence/DisplayPrefrence";
import TransactionalCampaigns from "../pages/TransactionalCampaigns/TransactionalCampaigns";
import TransactionalCampaignsDetail from "../pages/TransactionalCampaigns/TransactionalCampaignsDetail";
import Exports from "../pages/Exports/Exports";
import AppSetting from "../pages/AppSetting/AppSetting";
import AcquisitionChurn from "../pages/AcquisitionChurn/AcquisitionChurn";
import Demographics from "../pages/Demographics/Demographics";
import UtmBuilder from "../pages/UtmBuilder/UtmBuilder";
import SetupNewApp from "../pages/SetupNewApp/SetupNewApp";
import Events from "../pages/Events/Events";
import {
  fetchLoginUser,
  logoutUser,
  setActiveAppUserRole,
} from "../redux/features/user/loginUserSlice";
import Usage from "../pages/Usage/Usage";
import JourneysView from "../pages/Journeys/JourneysView/JourneysView";
import CampaignCreate from "../pages/Campaign/CampaignCreate/CampaignCreate";
import Trends from "../CDPpages/Trends/Trends";
import CampaignDetail from "../pages/Campaign/CampaignDetail/CampaignDetail";
import DemoMembersTable from "../commonPages/demo/demoMembersTable/DemoMembersTable";
import Segments from "../commonPages/demo/segments/Segments";
import { DemoDashboard } from "../commonPages/demo/demoDashboard/DemoDashboard";
import TierList from "../commonPages/demo/tier/TierList";
import CreateTier from "../commonPages/demo/tier/CreateTier";
import RewardList from "../commonPages/demo/rewards/RewardList";
import CreateReward from "../commonPages/demo/rewards/CreateReward";
import Template from "../pages/Campaign/Templates/Template";
import { heartBeat } from "services/heartBeat";
import { STATUS } from "redux/constant";
import AudienceTable from "pages/Audience/AudienceTable/AudienceTable";
import AudienceCreate from "pages/Audience/AudienceCreate/AudienceCreate";
import CreateTrait from "pages/Audience/Traits/CreateTrait/CreateTrait";
import DemoSync from "pages/Audience/AudienceCreate/DemoSync";
import PushGenAi from "commonPages/demo/genAi/PushGenAi/PushGenAi";
import Dashboard from "commonPages/demo/genAi/Dashboard/Dashboard";
import NotFound from "pages/NotFound/NotFound";
import FunnelDetails from "pages/Funnels/FunnelDetails/FunnelDetails";
import { ROUTES } from "utils/constants";
import Customer360 from "pages/Customer360/Customer360";
import AppUsersDetails from "pages/AppUsers/AppUsersDetails/AppUsersDetails";
import DataTransformation from "pages/DataTransformation/DataTransformation";
import Models from "pages/Audience/Models/Models";
import UserListNav from "pages/UserListManagement/UserListNav";
import { CONSTANTS ,FeatureSettingsFlagTypes, roleCheckTypes } from "utils/constants/constants";
import { getToken } from "redux/features/app/authTokenSlice";
import JourneyManagement from "pages/DataManagement/JourneyManagement/JourneyManagement";
import AuditLogs from "pages/AuditLogs/AuditLogs";
import AccessControl from "components/HOC/AccessControl/AccessControl";
import Customer360List from "pages/Customer360/Customer360List/Customer360List";
import Personalisation from "pages/DataManagement/Personalisation/Personalisation";
import { getFeaturesSettings } from "redux/features/app/featuresSettingSlice";
import { CampaignReports } from "pages/Campaign/CampaignReports/CampaignReports";
import { SmartLinkDetails } from "pages/Links/SmartLinks/SmartLinkDetails/SmartLinkDetails";
import { SmartLinkCreate } from "pages/Links/SmartLinks/SmartLinkCreate/SmartLinkCreate";
import { Links } from "pages/Links/Links";
import CreateFunction from "pages/Audience/Functions/CreateFunction/CreateFunction";
import { AudienceManagementPageType } from "utils/enums";
import TemplateView from "pages/Campaign/Templates/Details/TemplateView";
import { AttributeReports } from "pages/Campaign/AttributeReports/AttributeReports";
import Customer from "CDPpages/Customer360/Customer360";
import AppManagement from "pages/AppManagement/AppManagement";


function Panel() {
  const [showSideNav, setShowSideNav] = useState(false);
  const loginUser = useSelector((state: any) => state.loginUser.data);
  const { appId } = useSelector((state: any) => state.activeApp);
  const { status } = useSelector((state: any) => state.loginUser);
  const navigate = useNavigate();
  const dispatch: any = useDispatch();
  const location = useLocation();
  const { data } = useSelector((state: any) => state.featuresSetting);
  const isNotProtectedRoute = () => {
    return [
      ROUTES.SIGNUP,
      ROUTES.LOGIN,
      ROUTES.RESET,
      ROUTES.FORGET,
      ROUTES.AUTHENTICATION,
    ].includes(location.pathname);
  };
  useEffect(() => {
    //if signup or login page then the side nav will be invisible
    if (isNotProtectedRoute()) {
      setShowSideNav(false);
    } else {
      setShowSideNav(true);
    }
  }, [location.pathname , loginUser]);
  useEffect(() => {
    //fetch loggedIn user details  if the route is protected
    if (!isNotProtectedRoute()) {
      dispatch(fetchLoginUser());
    }
  }, []);
  //   calling fetures setting once whenever there is change in appid or login user
  useEffect(() => {
    if (appId && loginUser.api_key) {
      dispatch(
        getFeaturesSettings({
          api_key: loginUser.api_key,
          app_id: appId,
        })
      );
    }
  }, [appId, loginUser.api_key]);

  useEffect(() => {
    if (status == STATUS.ERROR && location.pathname != ROUTES.AUTHENTICATION) {
      dispatch(logoutUser());
    }
  }, [status]);
  useEffect(() => {
    if (status == STATUS.LOGGEDOUT) {
      navigate(ROUTES.LOGIN);
    }
  }, [status]);
  useEffect(() => {
    let intervalId: NodeJS.Timer;
    //invoke this function whenever the visibility changes
    const handleVisibilityChange = () => {
      //check if the screen is visible
      if (document.visibilityState === "visible") {
        updateUser();
        startInterval();
      } else {
        //clean up interval if screen is invisible
        clearInterval(intervalId);
      }
    };
    const updateUser = async () => {
      try {
        //update the user only if the user is loggedIn
        if (
          loginUser.api_key &&
          status !== STATUS.ERROR &&
          status !== STATUS.LOGGEDOUT
        ) {
          await heartBeat(loginUser.api_key);
        }
      } catch (error) {
        //if any error occured logout the user and cleanup interval
        dispatch(logoutUser());
        clearInterval(intervalId);
      }
    };
    const startInterval = () => {
      //start interval only when the user is loggedIn
      if (
        loginUser.api_key &&
        status !== STATUS.ERROR &&
        status !== STATUS.LOGGEDOUT
      ) {
        intervalId = setInterval(updateUser, 40000);
      }
    };
    // Start the interval if conditions are met initially
    startInterval();
    document.addEventListener("visibilitychange", handleVisibilityChange);
    // Clean up the event listener and interval on unmount
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      clearInterval(intervalId);
    };
  }, [loginUser]);
  useEffect(() => {
    if(! isNotProtectedRoute() && loginUser.api_key){
      dispatch(getToken())
    }
  }, [loginUser ,location.pathname ]);
  //calls this use effect when user login the panel or appid changes in activeappid
  useEffect(() => {
    if (loginUser.api_key) {
      dispatch(setActiveAppUserRole(appId));
    }
  }, [appId, loginUser]);


  return (
    <Layout style={{ height: "100vh" }}>
      <HeaderNav></HeaderNav>
      <Layout
        className="site-layout"
        style={{ height: "100%", overflow: "hidden" }}
      >
        {showSideNav && loginUser?.isValid && <PanelSideNav />}

        <Content
          style={{ padding: 0, overflowY: "auto", overflowX: "hidden" }}
          className="px-sm-3 p-1 py-2"
        >
          <Routes>
            <Route
              path={ROUTES.DASHBOARD}
              element={
            <AccessControl fallbackComponent={<NotFound setShowSideNav={setShowSideNav}/>}>  
              <Home />
            </AccessControl>
            }
            />
            <Route
              path={ROUTES.DASHBOARD_APP}
              element={
              <AccessControl fallbackComponent={<NotFound setShowSideNav={setShowSideNav}/>}>  
               <AppDashboard />
              </AccessControl>
            }
            />

            <Route
              path={ROUTES.DASHBOARD_ALLUSERS}
              element={
                <AccessControl accessControl={{role:[roleCheckTypes.UserAdminRights]}} fallbackComponent={<NotFound setShowSideNav={setShowSideNav}/>}>  
                 <UserManagement />
                </AccessControl>
                }
            />
            <Route path={ROUTES.SIGNUP} element={<SignUp />} />
            <Route path={ROUTES.LOGIN} element={<Login />} />
            <Route path={ROUTES.RESET} element={<ResetPassword />} />
            <Route path={ROUTES.FORGET} element={<ForgetPassword />} />

            <Route path={ROUTES.AUTHENTICATION} element={<Verification />} />
            <Route
              path={ROUTES.DASHBOARD_CAMPAIGN}
              element={
            <AccessControl accessControl={{role:[roleCheckTypes.Contributor ,roleCheckTypes.ContactCenter ]}} fallbackComponent={<NotFound setShowSideNav={setShowSideNav}/>}> 
              <Campaign /> 
            </AccessControl>
            }
            />
            <Route
              path={ROUTES.DASHBOARD_CAMPAIGN_DETAIL}
              element={
              <AccessControl accessControl={{role:[roleCheckTypes.Contributor,roleCheckTypes.ContactCenter ]}} fallbackComponent={<NotFound setShowSideNav={setShowSideNav}/>}> 
               <CampaignDetail />
              </AccessControl>
            }
            />

            <Route
              path={ROUTES.DASHBOARD_CAMPAIGN_REVIEW}
              element={
              <AccessControl accessControl={{role:[roleCheckTypes.Contributor  ,roleCheckTypes.ContactCenter]}} fallbackComponent={<NotFound setShowSideNav={setShowSideNav}/>}> 
               <CampaignDetail mode={CONSTANTS.REVIEW}  />
              </AccessControl>
            }
            />

            <Route
              path={ROUTES.DASHBOARD_CAMPAIGN_CREATE}
              element={
              <AccessControl accessControl={{role:[roleCheckTypes.Checker , roleCheckTypes.Maker ]}} fallbackComponent={<NotFound setShowSideNav={setShowSideNav}/>}> 
               <CampaignCreate />
               </AccessControl>
              }
            />
            <Route
              path={ROUTES.DASHBOARD_APPUSER}
              element={
              <AccessControl accessControl={{role:[roleCheckTypes.Contributor ] ,features:[FeatureSettingsFlagTypes.CUSTOMER_360]}}  fallbackComponent={<NotFound setShowSideNav={setShowSideNav}/>}> 
               <AppUsers />
              </AccessControl>
            }
            />
            <Route
              path={ROUTES.DASHBOARD_FUNNELS}
              element={
              <AccessControl accessControl={{role:[roleCheckTypes.Contributor ],features:[FeatureSettingsFlagTypes.FUNNEL]}} fallbackComponent={<NotFound setShowSideNav={setShowSideNav}/>}> 
                <FunnelsTable />
              </AccessControl>
            }
            />
            <Route
              path={ROUTES.DASHBOARD_FUNNEL_DETAIL}
              element={
              <AccessControl accessControl={{role:[roleCheckTypes.Contributor ],features:[FeatureSettingsFlagTypes.FUNNEL]}} fallbackComponent={<NotFound setShowSideNav={setShowSideNav}/>}> 
                <FunnelDetails />
              </AccessControl>
            }
            />
            <Route
              path={ROUTES.DASHBOARD_FUNNELS_CREATE}
              element={
               <AccessControl accessControl={{role:[roleCheckTypes.Checker , roleCheckTypes.Maker ],features:[FeatureSettingsFlagTypes.FUNNEL]}} fallbackComponent={<NotFound setShowSideNav={setShowSideNav}/>}> 
               <CreateFunnels />
              </AccessControl>
            }
            />
            <Route
              path={ROUTES.DASHBOARD_JOURNEYS}
              element={
             <AccessControl accessControl={{role:[roleCheckTypes.Contributor],features:[FeatureSettingsFlagTypes.JOURNEY]}} fallbackComponent={<NotFound setShowSideNav={setShowSideNav}/>}>
              <Journeys />
            </AccessControl>
            }
            />

            <Route
              path={ROUTES.DASHBOARD_USER_LIST}
              element={
              <AccessControl>  
              <UserListNav />
              </AccessControl>
            }
            />

            <Route
              path={ROUTES.DASHBOARD_JOURNEYS_CREATE}
              element={
             <AccessControl accessControl={{role:[roleCheckTypes.Checker , roleCheckTypes.Maker ],features:[FeatureSettingsFlagTypes.JOURNEY]}} fallbackComponent={<NotFound setShowSideNav={setShowSideNav}/>}> 
              <JourneysCreate />
             </AccessControl>
            }
            />
            <Route
              path={ROUTES.DASHBOARD_AUDIENCE}
              element={
              <AccessControl accessControl={{role:[roleCheckTypes.Contributor ,roleCheckTypes.ContactCenter ]}} fallbackComponent={<NotFound setShowSideNav={setShowSideNav}/>}>
               <AudienceTable type={AudienceManagementPageType.SEGMENT} />
              </AccessControl>
               }
            />
            <Route
              path={ROUTES.DASHBOARD_AUDIENCE_CREATE}
              element={
              <AccessControl accessControl={{role:[roleCheckTypes.Contributor  ,roleCheckTypes.ContactCenter ]}} fallbackComponent={<NotFound setShowSideNav={setShowSideNav}/>}>
                 <AudienceCreate />
              </AccessControl>
            
            }
            />
            <Route
              path={ROUTES.DASHBOARD_ACQUISITION}
              element={
              <AccessControl accessControl={{role:[roleCheckTypes.Contributor ], features:[FeatureSettingsFlagTypes.APP_ACQUISTION]}} fallbackComponent={<NotFound setShowSideNav={setShowSideNav}/>}>
               <AcquisitionChurn churn={false} />
              </AccessControl>
            }
            />
            <Route
              path={ROUTES.DASHBOARD_CHURN}
              element={
              <AccessControl accessControl={{role:[roleCheckTypes.Contributor ], features:[FeatureSettingsFlagTypes.CHURN]}} fallbackComponent={<NotFound setShowSideNav={setShowSideNav}/>}>  
                <AcquisitionChurn churn={true} />
              </AccessControl>
              }
            />
            <Route
              path={ROUTES.DASHBOARD_DEMOGRAPHICS}
              element={
              <AccessControl accessControl={{role:[roleCheckTypes.Contributor ], features:[FeatureSettingsFlagTypes.DEMOGRAPHICS]}} fallbackComponent={<NotFound setShowSideNav={setShowSideNav}/>}>  
                <Demographics />
              </AccessControl>
              }
            />
            <Route
              path={ROUTES.DASHBOARD_UTMBUILDER}
              element={
             <AccessControl accessControl={{role:[roleCheckTypes.Contributor ]}} fallbackComponent={<NotFound setShowSideNav={setShowSideNav}/>}>  
              <UtmBuilder />
             </AccessControl>
            }
            />
            <Route
              path={ROUTES.DASHBOARD_CREATEAPP}
              element={
                <AccessControl accessControl={{role:[roleCheckTypes.GlobalAdmin ]}}  fallbackComponent={<NotFound setShowSideNav={setShowSideNav}/>}>
                <SetupNewApp />
                </AccessControl>
              }
            />
            <Route
              path={ROUTES.DASHBOARD_EDIT_PROFILE}
              element={
              <AccessControl>
              <EditProfile />
              </AccessControl>
            }
            />
            <Route
              path={ROUTES.DASHBOARD_DISPLAY_PREFRENCE}
              element={
              <AccessControl accessControl={{role:[roleCheckTypes.Contributor ], features:[FeatureSettingsFlagTypes.DISPLAY_PREFERENCE]}} fallbackComponent={<NotFound setShowSideNav={setShowSideNav}/>}>      
                <DisplayPrefrence />
              </AccessControl>  
              }
            />

            <Route
              path={ROUTES.DASHBOARD_TRANSACTIONALCAMPAIGN}
              element={
              <AccessControl accessControl={{role:[roleCheckTypes.Contributor ], features:[FeatureSettingsFlagTypes.TRANSACTIONAL_CAMP]}} fallbackComponent={<NotFound setShowSideNav={setShowSideNav}/>}>      
                <TransactionalCampaigns />
              </AccessControl>
              }
            />
            <Route
              path={ROUTES.DASHBOARD_TRANSACTIONALCAMPAIGN_DETAIL}
              element={
              <AccessControl accessControl={{role:[roleCheckTypes.Contributor ]}} fallbackComponent={<NotFound setShowSideNav={setShowSideNav}/>}>  
               <TransactionalCampaignsDetail />
              </AccessControl>
            }
            />
            <Route
              path={ROUTES.DASHBOARD_APPSETTING}
              element={
              <AccessControl accessControl={{role:[roleCheckTypes.SuperUser ]}} fallbackComponent={<NotFound setShowSideNav={setShowSideNav}/>}>
                <AppSetting />
              </AccessControl>             
            }
            />
             <Route
              path={ROUTES.DASHBOARD_AUDIT_LOGS}
              element={
                <AccessControl accessControl={{role:[roleCheckTypes.Infosec ]}} fallbackComponent={<NotFound setShowSideNav={setShowSideNav}/>}>
                <AuditLogs />
                </AccessControl>
              }
            />
            <Route
              path={ROUTES.DASHBOARD_EXPORTS}
              element={
                <AccessControl accessControl={{role:[roleCheckTypes.Contributor,roleCheckTypes.Infosec ], features:[FeatureSettingsFlagTypes.REPORTS]}} fallbackComponent={<NotFound setShowSideNav={setShowSideNav}/>}>
                <Exports />
               </AccessControl>
            }
            />
             <Route
              path={ROUTES.DASHBOARD_LINKS}
              element={
                <AccessControl accessControl={{role:[roleCheckTypes.Contributor ], features:[FeatureSettingsFlagTypes.BUILDER]}} fallbackComponent={<NotFound setShowSideNav={setShowSideNav}/>}>
                 <Links />
                </AccessControl>
            }
            />
            <Route
              path={ROUTES.DASHBOARD_SMART_LINK_DETAILS}
              element={
                <AccessControl accessControl={{role:[roleCheckTypes.Contributor ],features:[FeatureSettingsFlagTypes.BUILDER]}}fallbackComponent={<NotFound setShowSideNav={setShowSideNav}/>}>
                 <SmartLinkDetails />
                </AccessControl>
            }
            />
             <Route
              path={ROUTES.DASHBOARD_SMART_LINK_CREATE}
              element={
                <AccessControl accessControl={{role:[roleCheckTypes.Checker , roleCheckTypes.Maker ], features:[FeatureSettingsFlagTypes.BUILDER]}} fallbackComponent={<NotFound setShowSideNav={setShowSideNav}/>}>
                 <SmartLinkCreate />
                </AccessControl>
            }
            />
            <Route
              path={ROUTES.DASHBOARD_EVENTS}
              element={
            <AccessControl accessControl={{role:[roleCheckTypes.Contributor ]}} fallbackComponent={<NotFound setShowSideNav={setShowSideNav}/>}> 
              <Events />
            </AccessControl>
            }
            />
            <Route
              path={ROUTES.DASHBOARD_USAGE}
              element={
             <AccessControl accessControl={{role:[roleCheckTypes.Contributor] ,features:[FeatureSettingsFlagTypes.USAGE]}} fallbackComponent={<NotFound setShowSideNav={setShowSideNav}/>}>
               <Usage />
              </AccessControl>
            }
            />
            <Route
              path={ROUTES.DASHBOARD_Attribute_REPORTS}
              element={
              <AccessControl accessControl={{role:[roleCheckTypes.Contributor]}} fallbackComponent={<NotFound setShowSideNav={setShowSideNav}/>}>
               <AttributeReports />
              </AccessControl>
            }
            />
            <Route
              path={ROUTES.DASHBOARD_JOURNEYS_VIEW}
              element={
             <AccessControl accessControl={{role:[roleCheckTypes.Contributor]}} fallbackComponent={<NotFound setShowSideNav={setShowSideNav}/>}>
              <JourneysView />
            </AccessControl>
            }
            />
            <Route
              path={ROUTES.DASHBOARD_TRENDS_ALLTRENDS}
              element={
             <AccessControl accessControl={{role:[roleCheckTypes.Contributor]}} fallbackComponent={<NotFound setShowSideNav={setShowSideNav}/>}>
              <Trends />
            </AccessControl>
            }
            />
            <Route
              path={ROUTES.DASHBOARD_CAMPAIGN_REPORTS}
              element={
              <AccessControl accessControl={{role:[roleCheckTypes.Contributor] ,features:[FeatureSettingsFlagTypes.PERFORMANCE_REPORTS]}} fallbackComponent={<NotFound setShowSideNav={setShowSideNav}/>}>
               <CampaignReports />
              </AccessControl>
            }
            />
            <Route
              path={ROUTES.DASHBOARD_AUDIENCE_ALLTRAITS}
              element={
              <AccessControl accessControl={{role:[roleCheckTypes.Contributor,roleCheckTypes.ContactCenter ], features:[FeatureSettingsFlagTypes.TRAITS]}} fallbackComponent={<NotFound setShowSideNav={setShowSideNav}/>}>
               <AudienceTable type={AudienceManagementPageType.TRAIT} />
              </AccessControl>
            }
            />
            <Route
              path={ROUTES.DASHBOARD_AUDIENCE_TRAIT_CREATE}
              element={
              <AccessControl accessControl={{role:[roleCheckTypes.Contributor ,roleCheckTypes.ContactCenter], features:[FeatureSettingsFlagTypes.TRAITS]}} fallbackComponent={<NotFound setShowSideNav={setShowSideNav}/>}>
                <CreateTrait />
              </AccessControl>  
              }
            />
            <Route
              path={ROUTES.DASHBOARD_CAMPAIGN_TEMPLATE}
              element={
              <AccessControl accessControl={{role:[roleCheckTypes.Contributor ]}} fallbackComponent={<NotFound setShowSideNav={setShowSideNav}/>}>
               <Template />
              </AccessControl>
            }
            />
            <Route
              path={ROUTES.DASHBOARD_APPUSER_DETAIL}
              element={
              <AccessControl accessControl={{role:[roleCheckTypes.Contributor ],features:[FeatureSettingsFlagTypes.CUSTOMER_360]}} fallbackComponent={<NotFound setShowSideNav={setShowSideNav}/>}>
                <Customer360 />
              </AccessControl>
              }
            />
            <Route
              path={ROUTES.DASHBOARD_APPUSERS_DETAILS}
              element={
            <AccessControl accessControl={{role:[roleCheckTypes.Contributor ]}} fallbackComponent={<NotFound setShowSideNav={setShowSideNav}/>}>           
              <AppUsersDetails />
            </AccessControl>
            }
            />
            <Route
              path={ROUTES.NOT_FOUND}
              element={<NotFound setShowSideNav={setShowSideNav} />}
            />
            <Route
              path={ROUTES.DASHBOARD_DATA_TRANSFORMATION}
              element={
               <AccessControl accessControl={{role:[roleCheckTypes.Checker ,roleCheckTypes.Maker ],features:[FeatureSettingsFlagTypes.CUSTOMER_360_MAPPING]}} fallbackComponent={<NotFound setShowSideNav={setShowSideNav}/>}>
                 <DataTransformation />
               </AccessControl>
            }
            />

             <Route
              path={ROUTES.DASHBOARD_JOURNEY_MANAGEMENT}
              element={
                <AccessControl accessControl={{role:[roleCheckTypes.Checker ,roleCheckTypes.Maker ]}} fallbackComponent={<NotFound setShowSideNav={setShowSideNav}/>}>
                 <JourneyManagement />
                </AccessControl>
              }
            />

            <Route
              path={ROUTES.DASHBOARD_CUSTOMER_DETAIL}
              element={
                <AccessControl accessControl={{role:[roleCheckTypes.Contributor ,roleCheckTypes.ContactCenter ],features:[FeatureSettingsFlagTypes.CUSTOMER_360]}} fallbackComponent={<NotFound setShowSideNav={setShowSideNav}/>}>
                 <Customer360 />
               </AccessControl> 
                }
            />
            <Route
              path={ROUTES.DASHBOARD_CUSTOMER}
              element={
                <AccessControl accessControl={{role:[roleCheckTypes.Contributor,roleCheckTypes.ContactCenter  ],features:[FeatureSettingsFlagTypes.CUSTOMER_360]}} fallbackComponent={<NotFound setShowSideNav={setShowSideNav}/>}>
                <Customer360List/>
                </AccessControl>
              }
            />
            <Route
              path={ROUTES.DASHBOARD_MANAGEMENT_PERSONALISATION}
              element={
               <AccessControl accessControl={{role:[roleCheckTypes.Checker , roleCheckTypes.Maker ],features:[FeatureSettingsFlagTypes.PERSONLIZATION_MAPPING]}} fallbackComponent={<NotFound setShowSideNav={setShowSideNav}/>}> 
                 <Personalisation />
               </AccessControl>
            }
            />
             <Route
              path={ROUTES.DASHBOARD_CAMPAIGN_TEMPLATE_VIEW}
              element={
               <AccessControl accessControl={{role:[roleCheckTypes.Contributor ]}} fallbackComponent={<NotFound setShowSideNav={setShowSideNav}/>}> 
                 <TemplateView />
               </AccessControl>
            }
            />
              <Route
              path={ROUTES.DASHBOARD_APPMANAGEMENT}
              element={
               <AccessControl accessControl={{role:[roleCheckTypes.GlobalAdmin ]}} fallbackComponent={<NotFound setShowSideNav={setShowSideNav}/>}> 
                 <AppManagement />
               </AccessControl>
            }
            />
            {/* demo pages */}
            <Route
              path={ROUTES.DASHBOARD_LOYALTY}
              element={
                <AccessControl accessControl={{role:[roleCheckTypes.Contributor ], features:[FeatureSettingsFlagTypes.LOYALTY]}} fallbackComponent={<NotFound setShowSideNav={setShowSideNav}/>}>  
                  <DemoDashboard />
                </AccessControl>  
              }
            />
            <Route
              path={ROUTES.DASHBOARD_LOYALTY_MEMBERS}
              element={
                <AccessControl accessControl={{role:[roleCheckTypes.Contributor ], features:[FeatureSettingsFlagTypes.LOYALTY]}} fallbackComponent={<NotFound setShowSideNav={setShowSideNav}/>}> 
                <DemoMembersTable
                  onTabClickUrl={ROUTES.DASHBOARD_LOYALTY_C360}
                />
                </AccessControl> 
              }
            />
            <Route
              path={ROUTES.DASHBOARD_LOYALTY_SEGMENTS}
              element={
                <AccessControl accessControl={{role:[roleCheckTypes.Contributor ], features:[FeatureSettingsFlagTypes.LOYALTY]}} fallbackComponent={<NotFound setShowSideNav={setShowSideNav}/>}> 
                 <Segments />
                </AccessControl>
              }
            />
            <Route
              path={ROUTES.DASHBOARD_LOYALTY_TIER}
              element={
              <AccessControl accessControl={{role:[roleCheckTypes.Contributor ], features:[FeatureSettingsFlagTypes.LOYALTY]}} fallbackComponent={<NotFound setShowSideNav={setShowSideNav}/>}> 
               <TierList />
              </AccessControl>
            }
            />
            <Route
              path={ROUTES.DASHBOARD_LOYALTY_TIER_CREATE}
              element={
               <AccessControl accessControl={{role:[roleCheckTypes.Contributor ], features:[FeatureSettingsFlagTypes.LOYALTY]}} fallbackComponent={<NotFound setShowSideNav={setShowSideNav}/>}> 
               <CreateTier />
              </AccessControl>
            }
            />
            <Route
              path={ROUTES.DASHBOARD_LOYALTY_C360}
              element={
              <AccessControl accessControl={{role:[roleCheckTypes.Contributor ], features:[FeatureSettingsFlagTypes.LOYALTY]}} fallbackComponent={<NotFound setShowSideNav={setShowSideNav}/>}> 
               <Customer360 />
              </AccessControl>
              }
            />
         
            <Route
              path={ROUTES.DASHBOARD_AUDIENCE_SYNC}
              element={<DemoSync />}
            />
            <Route
              path={ROUTES.DASHBOARD_LOYALTY_REWARDS}
              element={
              <AccessControl accessControl={{role:[roleCheckTypes.Contributor ], features:[FeatureSettingsFlagTypes.LOYALTY]}} fallbackComponent={<NotFound setShowSideNav={setShowSideNav}/>}> 
               <RewardList />
              </AccessControl> 
               }
            />
            <Route
              path={ROUTES.DASHBOARD_LOYALTY_REWARDS_CREATE}
              element={
                <AccessControl accessControl={{role:[roleCheckTypes.Contributor ], features:[FeatureSettingsFlagTypes.LOYALTY]}} fallbackComponent={<NotFound setShowSideNav={setShowSideNav}/>}> 
                 <CreateReward />
                </AccessControl>
              }
            />
            <Route
             path={ROUTES.DASHBOARD_PUSH_GENAI}
             element={
            <AccessControl accessControl={{role:[roleCheckTypes.Contributor ], features:[FeatureSettingsFlagTypes.XMIT_AI]}} fallbackComponent={<NotFound setShowSideNav={setShowSideNav}/>}> 
              <PushGenAi />
            </AccessControl>
             } 
             />
            <Route 
             path={ROUTES.GENAI_DASHBOARD} 
             element={
              <AccessControl accessControl={{role:[roleCheckTypes.Contributor ], features:[FeatureSettingsFlagTypes.XMIT_AI]}} fallbackComponent={<NotFound setShowSideNav={setShowSideNav}/>}> 
               <Dashboard />
              </AccessControl>
             
             } 
             />
            <Route
              path={ROUTES.DASHBOARD_AUDIENCE_MODELS}
              element={
              <AccessControl accessControl={{role:[roleCheckTypes.Contributor ], features:[FeatureSettingsFlagTypes.MODELS]}} fallbackComponent={<NotFound setShowSideNav={setShowSideNav}/>}>    
              <Models />
              </AccessControl>
             }
            />
            <Route
              path={ROUTES.DASHBOARD_AUDIENCE_FUNCTIONS}
              element={
              <AccessControl accessControl={{role:[roleCheckTypes.Contributor ,roleCheckTypes.ContactCenter], features:[FeatureSettingsFlagTypes.FUNCTIONS]}} fallbackComponent={<NotFound setShowSideNav={setShowSideNav}/>}>    
               <AudienceTable type={AudienceManagementPageType.FUNCTION} />
              </AccessControl>
             }
            />
             {/* ====demo page=== */}
             <Route path="dashboard/c360" element={<Customer/>} />
             {/* ======= */}
             <Route
              path={ROUTES.DASHBOARD_AUDIENCE_FUNCTION_CREATE}
              element={
              <AccessControl accessControl={{role:[roleCheckTypes.Contributor, roleCheckTypes.ContactCenter ], features:[FeatureSettingsFlagTypes.FUNCTIONS]}} fallbackComponent={<NotFound setShowSideNav={setShowSideNav}/>}>    
               <CreateFunction/>
              </AccessControl>
             }
            />
          </Routes>
        </Content>
      </Layout>
    </Layout>
  );
}

export default Panel;
