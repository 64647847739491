import { Divider, Form } from "antd"
import PrimaryButton from "components/Buttons/PrimaryButton/PrimaryButton";
import TextInput from "components/Inputs/TextInput/TextInput";
import Spinner from "components/Spinner/Spinner";
import useMessage from "hooks/useMessage";
import { t } from "i18next"
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getToken } from "redux/features/app/authTokenSlice";
import centralApi from "services/centralApi";
import { API_ENDPOINTS } from "utils/constants";
import { BOOLEAN_OPTIONS } from "utils/constants/selectorOptions";



export const DBSetup = () => {
    const [loading , setLoading] = useState<boolean>(BOOLEAN_OPTIONS.FALSE);
    const loginUser = useSelector((state: any) => state.loginUser.data);
    const [form] = Form.useForm();
    const { showError, showSuccess } = useMessage();
    const dispatch: any = useDispatch();

      /**
   * 
   * @param values take app_id from values 
   * returns success when collection and tables are created
   */
  const onFormSubmit = async(values: any) => { 
     try {  
        const {app_id} = values
        if (app_id && app_id.length !== 24){
            // Validation failed: show an error and stop the process
             showError(t("appidValidationLabel"));
             return; // Stop further execution
         }      
        const payload = {
            args: JSON.stringify({
              app_id:app_id
            }),
      
            api_key: loginUser.api_key,
          };
          setLoading(BOOLEAN_OPTIONS.TRUE)
          const url = API_ENDPOINTS.SETUP_DB_URL;
          await centralApi("GET", url, null, payload);
          await dispatch(getToken())
          setLoading(BOOLEAN_OPTIONS.FALSE)
          showSuccess(t("sucessfullySavedLabel"));     
        return   
     } catch (error) {
        setLoading(BOOLEAN_OPTIONS.FALSE)
        showError(t("somethingWrongLabel")) 
     }

  }

  return (
    <div className="decryption-notification-container">
    <div className="mt-4 mb-4  ">
      <h6 className="decryption-notification-container-heading">
      {"DB Setup Service"}
      </h6>     
      <Divider className="decryption-notification-custom-divider" />
      <small className="text-sm text-muted">{t("dbSetupDescLabel")}</small>
    </div>
    {loading ? (
            <Spinner />
          ) : (
            <Form
              form={form}
              layout="horizontal"
              onFinish={onFormSubmit}
            >
              <Form.Item 
                name={"app_id"} 
                label={"App Id"}  
                className="w-50"
                rules={[
                    {
                      required: true,
                      message: "App id is required",
                    },
                  ]}
                >
                <TextInput
                 placeholder={"App Id (existing)"}
                 maxLength={24}
                 showCount
                />
              </Form.Item>
              <div className="d-flex w-50 justify-content-end">
              <PrimaryButton
                  size="middle"
                  type="primary"
                  htmlType="submit"
                  style={{ border: "none", width: "80px" }}
                >
                  Setup Db's
                </PrimaryButton>
              </div>            
            </Form>
     )}
    </div>
  )
}
