import { Form } from "antd";
import CustomBreadCrumb from "components/CustomBreadCrumb/CustomBreadCrumb";
import { t } from "i18next";
import PageLayout from "layout/PageLayout/PageLayout";
import { FC, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { API_ENDPOINTS, ROUTES } from "utils/constants";
import { CreateHeader } from "pages/Audience/AudienceCreate/components/CreateHeader/CreateHeader";
import { validateNotEmpty } from "validator/antdValidator";
import CustomTextArea from "components/Inputs/CustomTextArea/CustomTextArea";
import centralApi from "services/centralApi";
import { useDispatch, useSelector } from "react-redux";
import { getToken } from "redux/features/app/authTokenSlice";
import useMessage from "hooks/useMessage";
import { getObjectId } from "services/getObjectId";
import { Function, QueryPayload } from "utils/models";
import { AudienceManagementPageType } from "utils/enums";
import Selector from "components/Selector/Selector";
const CreateFunction: FC= () => {
  const [form] = Form.useForm();
  const {showSuccess,showError } = useMessage();
  const loginUser = useSelector((state: any) => state.loginUser.data);
  const { appId } = useSelector((state: any) => state.activeApp);
  const navigate = useNavigate();
  const dispatch: any = useDispatch();
  const { state } = useLocation();
  const [formData, setFormData] = useState<{data:Function,loading:boolean,submitLoading:boolean,identityColumns:any[],allTables:any[]}>({ data: {tableName:"",audid:"",name:"",description:"",query:"",identityColumn:""},allTables:[],identityColumns:[],loading: false ,submitLoading:false});
  /**
 * Handles form data changes by updating the state.
 *
 * @param data - An object where keys represent form field names 
 *               and values are their corresponding updated values.
 *               The values can be strings or undefined.
 */
const handleFormChange = (data: { [key: string]: string | undefined }) => {
  setFormData((prevState) => ({
    ...prevState, // Preserve the existing state properties
    data: { 
      ...prevState.data, // Keep existing form data
      ...data, // Merge new data into the form state
    },
  }));
};

const fetchMetaData = async () => {
  try {
    const params = {
      api_key: loginUser.api_key,
      app_id: appId,
    };
    const data = await centralApi(
      "GET",
      API_ENDPOINTS.TRAIT_META_DATA_URL,
      null,
      params
    );
    setFormData((prevState: any) => ({ ...prevState,allTables:data.tableValues || [] }));
  } catch (error) {}
};

/**
   * "fetchTableInfo" Fetches information about a table from  API.
   *
   * @param operand - name of column optional
   *  If both table and column are available, it returns column values.
   *  If only the table name is provided, it returns column names for that table.
   * @returns The data fetched from the API or an empty array in case of an error.
   */
const fetchTableInfo = async () => {
  const queryPayload = new QueryPayload(
    {name:"",
    tableInfo:{
      tableName: formData?.data.tableName,
    },  
    projectionInfo:[],
    filterInfo:[]}
  );
  try {
    const params = {
      api_key: loginUser.api_key,
      app_id: appId,
      data:queryPayload.toString()
    };
    //call api
    const data = await centralApi(
      "GET",
      API_ENDPOINTS.GET_TRAIT_TABLE_INFO_URL,
      null,
      params
    );
    return data;
  } catch (error) {
    return [];
  }
};

/**
   * getColumns function calls the `fetchTableInfo` function to fetch column information for the current table.
   * It then updates the `metaData` state with the fetched columns information.
   */
const getColumns = async () => {
  setFormData((prevState) => ({ ...prevState, loading: true }));
  const {identityColumns} = await fetchTableInfo();
  //set state
  setFormData((prevState: any) => ({
    ...prevState,
    identityColumns,
    loading:false
  }));
};

 /**
 * Handles form submission by preparing the payload and calling the API.
 * It either creates a new function or updates an existing one.
 */
const handleSubmit = async () => {
  try {
    setFormData((prevState) => ({ ...prevState,submitLoading:true }));
    // Retrieve or generate the audience ID (audid)
    const audid =
      formData?.data.audid || (await getObjectId(appId, loginUser.api_key));

    // Retrieve auth token
    const authToken = await dispatch(getToken()).unwrap();

    // Common parameters for API request
    const params = {
      api_key: loginUser.api_key,
      app_id: appId,
      id: state?.id, // Optional ID for updating an existing function
    };

    // Construct the payload 
    const payload = new Function({
      ...formData.data,
      audid,
      createdOn:formData.data.createdOn,
      createdBy:formData.data.createdBy}
    );

    // Extend payload with additional parameters
    const payloadWithParams = {
      ...params,
      data: payload.toString(), // Convert payload to string for API transmission
      authToken,
    };

    // Determine the correct API endpoint (create or update)
    const apiEndpoint = !state?.id
      ? API_ENDPOINTS.CREATE_FUNCTION_URL // Create a new function
      : API_ENDPOINTS.UPDATE_FUNCTION_URL; // Update an existing function

    // Make API request
    const res = await centralApi("POST", apiEndpoint, payloadWithParams, params);
    setFormData((prevState) => ({ ...prevState, submitLoading:false }));
    if(res.inValidQuery){
      form.setFields([
        {
          name: "query",
          errors: [t("inputQueryMsg")],
        },
      ])
      return
    }
    // Handle API response
    if (res.acknowledged) {
      showSuccess(
        !state?.id
          ? t("functionCreatedSuccessMsg") // Show success message for creation
          : t("functionUpdatedSuccessMsg") // Show success message for update
      );
    }
  } catch (error) {
    setFormData((prevState) => ({ ...prevState, submitLoading:false }));
    // Handle errors gracefully
    showError(t("somethingWrongLabel"));
  }
   
  // Navigate back to the dashboard after form submission
  navigate(ROUTES.DASHBOARD_AUDIENCE_FUNCTIONS);
};



/**
 * Fetches function data when `state.id` is available.
 * This effect runs when `state` changes.
 */
useEffect(() => {
  fetchMetaData();
  if (state?.id) {
    (async () => {
      try {
        // Set loading state before fetching data
        setFormData((prevState) => ({ ...prevState, loading: true }));

        // Prepare API request parameters
        const params = {
          api_key: loginUser.api_key,
          app_id: appId,
          id: state.id, // Fetch data for the specific function ID
        };

        // Call the API to fetch function details
        const res = await centralApi(
          "GET",
          API_ENDPOINTS.GET_FUNCTION_BY_ID_URL,
          undefined, // No request body needed for GET request
          params
        );

        // Update state with the fetched data and disable loading
        setFormData((prevState) => ({
          ...prevState,
          data: res,
          loading: false,
        }));
        form.setFieldsValue(res)
      } catch (error) {
        // Handle errors and reset loading state
        setFormData((prevState) => ({ ...prevState, loading: false }));
      }
    })();
  }
}, [state]); // Dependency array ensures this runs when `state` changes

useEffect(() => {
    // Check if the tableName property is available in the `trait` object
    if (formData.data.tableName) {
      // Call the getColumns function to fetch column data based on the table name
      getColumns();
    }
  }, [formData.data.tableName]);

  return (
    <PageLayout
      loading={formData.loading}
      className="function-create-main-container"
      title="Function | Create"
      topLeftItem={
        <CustomBreadCrumb
          items={[
            {
              title: (
                <Link to={ROUTES.DASHBOARD_AUDIENCE}>{t("audienceLabel")}</Link>
              ),
            },
            {
              title: (
                <Link to={ROUTES.DASHBOARD_AUDIENCE_FUNCTIONS}>
                  {t("functionsLabel")}
                </Link>
              ),
            },
            {
              title: (
                <Link to={ROUTES.DASHBOARD_AUDIENCE_FUNCTION_CREATE}>
                  {true ? t("createLabel") : t("updateLabel")}
                </Link>
              ),
            },
          ]}
        />
      }
    >
      
        <Form
          form={form}
          colon={false}
          onFinish={handleSubmit}
          labelCol={{ flex: "100px" }}
          labelAlign="left"
        >
          <CreateHeader
            saveButtonProps={{loading:formData.submitLoading}}
            initialValues={{ name: formData.data.name ,description:formData.data.description}}
            onNameChange={(e) => handleFormChange({ name: e.target.value })}
            onDescriptionChange={(e)=>{handleFormChange({ description: e.target.value })}}
            type={AudienceManagementPageType.FUNCTION}
          />
          <div className="my-3">
            <strong>{t("computeFunctionMsg")}</strong>
          </div>
           <div className="col-12">
                            <Form.Item
                              initialValue={formData?.data?.tableName || undefined}
                              rules={[
                                {
                                  validator: validateNotEmpty,
                                  message: `${t("selectTableLabel")}`,
                                },
                              ]}
                              name={"tableName"}
                              className="mt-3 mb-0 "
                              label={t("selectTableLabel")}
                            >
                              <Selector
                                className="input-size-lg"
                                onChange={(value) => handleFormChange({ tableName:value })}
                                options={formData.allTables}
                              />
                            </Form.Item>
                          </div>
                            <div className="col-12">
                                            <Form.Item
                                              className="mt-3 mb-0 "
                                              label={t("identityColumnLabel")}
                                              initialValue={formData.data.identityColumn}
                                              name={"identityColumn"}
                                              rules={[
                                                {
                                                  validator: validateNotEmpty,
                                                  message: `${t("selectIdentityColumnMsg")}`,
                                                },
                                              ]}
                                            >
                                              <Selector
                                                loading={formData.loading}
                                                disabled={formData.loading}
                                                className="input-size-lg"
                                                onChange={(value) => handleFormChange({ identityColumn:value })}
                                                options={formData.identityColumns}
                                              />
                                            </Form.Item>
                                          </div>

          <div className="col-9">
            <Form.Item
              className="mt-3 mb-0 "
              labelCol={{ flex: 0 }}
              initialValue={formData.data.query}
              name={"query"}
              rules={[
                {
                  validator: validateNotEmpty,
                  message: `${t("inputQueryMsg")}`,
                },
              ]}
            >
              <CustomTextArea
                onChange={(e) => handleFormChange({ query: e.target.value })}
                rows={18}
                cols={10}
                placeholder={`${t("typeQueryMsg")}`}
              />
            </Form.Item>
          </div>
        </Form>
    </PageLayout>
  );
};

export default CreateFunction;
