import { Divider, Tabs } from "antd";
import "./Features.css";
import PrimaryButton from "../../../../components/Buttons/PrimaryButton/PrimaryButton";
import { Form } from "antd";
import CustomCheckbox from "../../../../components/CustomCheckbox/CustomCheckbox";
import React, { useState, useEffect } from "react";
import centralApi from "../../../../services/centralApi";
import Spinner from "../../../../components/Spinner/Spinner";
import NoData from "../../../../components/NoData/NoData";
import { t } from "i18next";
import { API_ENDPOINTS } from "utils/constants";
import { useDispatch, useSelector } from "react-redux";
import { setFeatureSettings } from "redux/features/app/featuresSettingSlice";
import useMessage from "hooks/useMessage";
import { BOOLEAN_OPTIONS } from "utils/constants/selectorOptions";
import { getToken } from "redux/features/app/authTokenSlice";

interface FeatureGroup {
  [key: string]: string[];
}

const Features: React.FC = () => {
  const dispatch: any = useDispatch();
  const featureData = useSelector(
    (state: any) => state.featuresSetting?.data[0]?.features || {}
  );
  const { showError, showSuccess } = useMessage();
  const [showNoDataMessage, setShowNoDataMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("campaign");

  const loginUser = useSelector((state: any) => state.loginUser);
  const activeApp = useSelector((state: any) => state.activeApp);
  const authToken = useSelector((state: any) => state.authToken.token);

  const appId = activeApp?.appId;
  const api_key = loginUser?.data.api_key;

  // Grouped feature labels with their keys
  const featureGroups: FeatureGroup = {
    campaign: [
      "ab_testing_variant",
      "language_variant",
      "advPush",
      "campExceptPush",
      "actionbuttonSettings",
      "advancedFreq",
      "launchCampFreq",
      "delayLauch",
      "liveEventSett",
      "schedulerSett",
      "delayCamp",
      "expImgState",
      "campaignShowenInSession",
      "intervalBetweenMessage",
      "multiStep",
      "campDeletion",
      "cmpInsertInToPG",
      "appInboxReports",
      "liveActivity",
      "landingPageView",
      "optimisationState",
      "dayPart",
      "makerChecker",
    ],
    pages: [
      "demographicsState",
      "usageState",
      "reportsState",
      "transState",
      "xmitAi",
      "loyalty",
      "performanceReports",
      "attributionReport",
      "customer360Mapping",
      "personlizationMapping",
      "setupAppsSettings",
      "churnState",
      "documentation",
      "displayPreference",
      "manageModules",
    ],
    common: [
      "funnelLaunchbtn",
      "email_editor",
      "shortener",
      "builder",
      "links",
       "maskedDids",
      "userprofileImgState",
      "appInboxAndDeliveryTypeState",
      "funnelPeriod",
      "outsideTheApp",
      "campaignDetailsCsv",
      "competingApps",
      "journeynavsettings",
      "funnelV2",
      "hidegranularMonetaryCACLTV",
      "embedCustomField",
      "whowhatwherewhen",
      "audience_exports",
      "deepLinkUrl",
      "category",
      "specialattributes",
      "conversionSettings",
      "removeWeb",
      "cSegPhoneEmail",
      "deviceAttributes",
      "appUsageAttributes",
      "functions",
      "traits",
      "audienceSync",
      "allowList",
      "acquisitionByType",
      "isConsentHonoured",
      "inSights",
      "isSettingsMode",
      "customer360",
      "cohortChart",
      "traitPreview",
      "journey",
      "funnel",
      "appAcquistion",
      "downloadSDK",

    ],
  };
78
  // Labels mapping
  const labels: { [key: string]: string } = {
    demographicsState: t("demographicsLabel"),
    language_variant: t("languageVariantLabel"),
    ab_testing_variant: t("abTestingVariantLabel"),
    usageState: t("usageLabel"),
    reportsState: t("reportsLabel"),
    appInboxReports: t("appInboxReportsLabel"),
    maskedDids: t("maskedDidsLabel"),
    churnState: t("churnLabel"),
    expImgState: t("expImgLabel"),
    advPush: t("advPushLabel"),
    campExceptPush: t("campExceptPushLabel"),
    transState: t("transactionalCampLabel"),
    performanceReports: t("performanceReportsLabel"),
    userprofileImgState: t("userprofileImgLabel"),
    optimisationState: t("optimisationLabel"),
    appInboxAndDeliveryTypeState: t("appInboxAndDeliveryTypeLabel"),
    funnelPeriod: t("funnelPeriodLabel"),
    documentation: t("documentationLabel"),
    outsideTheApp: t("outsideTheAppLabel"),
    campaignDetailsCsv: t("campaignDetailsCsvLabel"),
    competingApps: t("competingAppsLabel"),
    hidegranularMonetaryCACLTV: t("hidegranularMonetaryCACLTVLabel"),
    embedCustomField: t("embedCustomFieldLabel"),
    whowhatwherewhen: t("whowhatwherewhenLabel"),
    deepLinkUrl: t("deepLinkUrlLabel"),
    category: t("categoryLabel"),
    email_editor: t("emailEditorLabel"),
    landingPageView: t("landingPageViewLabel"),
    funnelLaunchbtn: t("funnelLaunchbtnLabel"),
    cmpInsertInToPG: t("cmpInsertInToPGLabel"),
    advancedFreq: t("advancedFreqLabel"),
    launchCampFreq: t("launchCampFreqLabel"),
    audience_exports: t("audienceExportsLabel"),
    actionbuttonSettings: t("actionButtonSettingsLabel"),
    specialattributes: t("specialAttributesLabel"),
    conversionSettings: t("conversionSettingsLabel"),
    journeynavsettings: t("journeyNavSettingsLabel"),
    setupAppsSettings: t("setupAppsSettingsLabel"),
    delayLauch: t("delayLaunchLabel"),
    removeWeb: t("removeWebLabel"),
    liveEventSett: t("liveEventSettLabel"),
    schedulerSett: t("schedulerSettLabel"),
    cSegPhoneEmail: t("phone/emailLabel"),
    xmitAi: t("xmitAiLabel"),
    loyalty: t("loyaltyLabel"),
    deviceAttributes:t("deviceAttributesLabel"),
    appUsageAttributes : t("appUsageAttributesLabel"),
    traits:t("traitsLabel"),
    functions:t("functionsLabel"),
    audienceSync:t("audienceSyncLabel"),
    allowList : t("allowListLabel"),
    downloadSDK:t("downloadSdkLabel"),
    manageModules:t("manageModulesLabel"),
    displayPreference:t("displayPreferenceLabel"),
    delayCamp:t("delayCampLabel"),
    acquisitionByType:t("acquisitionTypeLabel"),
    isConsentHonoured:t("consentChecklLabel"),
    inSights:t("inSightsLabel"),
    isSettingsMode: t("modeLabel"),
    dayPart:t("dayPartLabel"),
    liveActivity:t("liveActivityLabel"),
    campaignShowenInSession:t("campaignShowenInSessionLabel"),
    intervalBetweenMessage:t("intervalBetweenMessageLabel"),
    links:t("linksLabel"),
    shortener:t("shortenerLabel"),
    builder:t("builderLabel"),
    customer360:t("customer360Label"),
    attributionReport:t("attributionReportLabel"),
    customer360Mapping:t("customer360MappingLabel"),
    personlizationMapping:t("personlizationMappingLabel"),
    funnelV2:t("funnelV2Label"),
    cohortChart:t("cohortChartLabel"),
    journey:t("journeyLabel"),
    funnel:t("funnelLabel"),
    appAcquistion:t("appAcquistionLabel"),
    campDeletion: t("campDeletionLabel"),
    multiStep: t("multiStepCampLabel"),
    traitPreview: t("traitPreviewLabel"),
    makerChecker: t("makerCheckerLabel"),




  };

  const handleChange = (featureKey: string, newValue: boolean) => {
    dispatch(setFeatureSettings({ [featureKey]: newValue }));
  };
  const handleSubmit = async () => {
    const labelToKeyMapping: { [label: string]: string } = {
      demographicsState: "demographics", 
      usageState: "usage", 
      reportsState: "reports", 
      churnState: "churn", 
      expImgState: "expImg", 
      advPushState: "advPush", 
      campExceptPushState: "campExceptPush", 
      transState: "transactionalCamp", 
      userprofileImgState: "userprofileImg", 
      appInboxDeliveryState: "appInboxAndDeliveryType", 
      documentationState: "documentation", 
      outsideTheAppState: "outsideTheApp", 
      optimisationState: "optimisation",


  };
  
    const reversedFeatureData: Record<string, boolean> = {};

const reversedMapping = Object.fromEntries(
  Object.entries(labelToKeyMapping).map(([key, value]) => [value, key])
);

// Now, let's iterate over all featureData keys and map them correctly

for (const key in featureData) {
  if (Object.prototype.hasOwnProperty.call(featureData, key)) {
    // Check if the key is in reversedMapping
    const mappedKey = reversedMapping[key] || key; // Use the key if not found in reversedMapping

    reversedFeatureData[mappedKey] = featureData[key];
  }
}
    const payload = {
      args: JSON.stringify({
        ...reversedFeatureData,
        app_id: appId,
      }),
      app_id: appId,
      authToken,
      api_key: api_key,
    };

    await saveFeatureSetting(payload);
  };

  async function saveFeatureSetting(formData: any) {
    const url: string = API_ENDPOINTS.SAVE_FEATURES_SETTINGS_URL;

    try {
      setLoading(true);
      await centralApi("POST", url, formData, null);
      showSuccess(t("sucessfullySavedLabel"));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      showError(t("somethingWrongLabel"));
    }
    dispatch(getToken());
  }

  useEffect(() => {
    // Check if featureData is empty or null
    if (Object.keys(featureData).length === 0) {
      setShowNoDataMessage(true);
    } else {
      setShowNoDataMessage(false);
    }
  }, [featureData]);

  // Render features for the active tab
  const renderFeatures = () => {
    const features = featureGroups[activeTab];
    return features.map((featureKey) => (
      <Form.Item
        key={featureKey}
        name={featureKey}
        initialValue={featureData[featureKey]}
      >
        <CustomCheckbox
          label={labels[featureKey] || featureKey}
          checked={
            featureData[featureKey] === undefined
              ? true
              : featureData[featureKey]
          }
          onChange={(e: any) => handleChange(featureKey, e.target.checked)}
        />
      </Form.Item>
    ));
  };

  return (
    <div className="feature-setting-container">
      <div className="mt-4 mb-4">
        <h6 className="feature-setting-container-heading">
          {t("featuresSettingHeadingLabel")}
        </h6>
        <Divider className="feature-setting-custom-divider" />
      </div>

      <div className="mt-3">
        <p>{t("featuresSettingDescriptionLabel")}</p>
        <div className="mt-3 align-items-center feature-setting-form">
          {showNoDataMessage ? (
            <NoData />
          ) : loading ? (
            <Spinner />
          ) : (
            <Form onFinish={handleSubmit}>
              <Tabs
                defaultActiveKey="campaign"
                onChange={(key) => setActiveTab(key)}
                items={[
                  {
                    key: "campaign",
                    label: t("campaignLabel"),
                    children: renderFeatures(),
                  },
                  {
                    key: "pages",
                    label: t("pagesLabel"),
                    children: renderFeatures(),
                  },
                  {
                    key: "common",
                    label: t("commonLabel"),
                    children: renderFeatures(),
                  },
                ]}
              />

              <Form.Item>
                <PrimaryButton
                  size="middle"
                  type="primary"
                  htmlType="submit"
                  style={{ border: "none", width: "70px" }}
                >
                  {t("saveLabel")}
                </PrimaryButton>
              </Form.Item>
            </Form>
          )}
        </div>
      </div>
    </div>
  );
};

export default Features;
