// install (please try to align the version of installed @nivo packages)
// yarn add @nivo/pie
import { BarDatum } from "@nivo/bar";
import { ResponsivePie } from "@nivo/pie";
import { formateIntoKorM } from "../../../helperFunctions/number";
import NoData from "../../NoData/NoData";
type propTypes = {
  data: BarDatum[];
  enableArcLabels?: boolean;
  enableArcLinkLabels?: boolean;
  colorSchema: string[] | { scheme: "brown_blueGreen" | "nivo" | "paired" | "greens" | "category10" };
  totalEvents: number;
  innerRadius?:number;
  showLegends?:boolean;
  outerSpace?:number
  // yLabel:string
  // xLabel:string
  // keys:[]
};
export const DonutPieChart: React.FC<propTypes> = ({
  data,
  enableArcLabels,
  enableArcLinkLabels,
  colorSchema,
  totalEvents,
  innerRadius = 0.5,
  showLegends,
  outerSpace
}) => {
  const CustomDonutTooltip = ({ datum }: any) => {
    return (
      <div className="firebase-tooltip-nivo-barchart">
        <div className="firebase-tooltip-header">
          <strong>{datum?.id}</strong>
        </div>
        <div className="firebase-tooltip-value">
          Value: <strong>{datum?.value}</strong>
        </div>
        <div>
          Percentage:
          <strong>{((datum?.value / totalEvents) * 100).toFixed(1)}%</strong>
        </div>
      </div>
    );
  };
  return (
    data?.length==0?<NoData/>: <ResponsivePie
      data={data}
      margin={outerSpace?{top:outerSpace , right:outerSpace , bottom:outerSpace , left: outerSpace}:{ top: 20, right:40 , bottom: 20, left: 20 }}
      tooltip={CustomDonutTooltip}
      startAngle={-180}
      innerRadius={innerRadius}
      activeOuterRadiusOffset={8}
      colors={colorSchema}
      borderColor={{
        from: "color",
        modifiers: [["darker", 0.2]],
      }}
      valueFormat={(value) => formateIntoKorM(value)}
      arcLinkLabelsSkipAngle={20}
      enableArcLabels={enableArcLabels}
      enableArcLinkLabels={enableArcLinkLabels}
      arcLinkLabelsTextColor="#333333"
      arcLinkLabelsThickness={2}
      arcLinkLabelsColor={{ from: "color" }}
      arcLabelsSkipAngle={20}
      arcLabelsTextColor={{
        from: "color",
        modifiers: [["darker", 2]],
      }}
      legends={showLegends?[
        {
            anchor: 'bottom-right',
            direction: 'column',
            justify: false,
            translateX: 40,
            translateY:10,
            itemsSpacing: 1,
            itemWidth: 100,
            itemHeight: 18,
            itemTextColor: '#999',
            itemDirection: 'right-to-left',
            itemOpacity: 1,
            symbolSize: 10,
            symbolShape: 'circle',
            effects: [
                {
                    on: 'hover',
                    style: {
                        itemTextColor: '#000'
                    }
                }
            ]
        }
    ]:[]}
    layers={innerRadius >0.3 ?[
      "arcs",
      "arcLabels",
      "arcLinkLabels",
      "legends",
      (props) => {
        const centerX = props.centerX;
        const centerY = props.centerY;
        return (
          <text
            x={centerX}
            y={centerY}
            textAnchor="middle"
            dominantBaseline="central"
            style={{
              fontSize: "16px",
              fontWeight: "bold",
              fill: "#333",
            }}
          >
            {formateIntoKorM(totalEvents)}
          </text>
        );
      },
    ]:undefined}
    />
  );
};
