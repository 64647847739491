import React, { useState, useEffect } from "react";
import { t } from "i18next";
import { Helmet } from "react-helmet";
import "./FunnelDetails.css";
import CustomBreadCrumb from "../../../components/CustomBreadCrumb/CustomBreadCrumb";
import { Link, useParams } from "react-router-dom";
import useMessage from "../../../hooks/useMessage";
import centralApi from "services/centralApi";
import { useDispatch, useSelector } from "react-redux";
import PrimaryButton from "components/Buttons/PrimaryButton/PrimaryButton";
import FunnelGraph from "funnel-graph-js";
import LaunchCampaign from "./Components/LaunchCampaign";
import NoData from "components/NoData/NoData";
import { API_ENDPOINTS, ROUTES } from "utils/constants";
import { getObjectId } from "services/getObjectId";
import { getRandomNumber } from "helperFunctions/number";
import FunnelExport from "./Components/FunnelExport";
import { addOffSet, currentEpochTime } from "helperFunctions/date";
import { useNavigate } from "react-router-dom";
import {
  removeTrailingNumber,
} from "helperFunctions/common";
import Spinner from "components/Spinner/Spinner";
import { FeatureSettingsFlagTypes, METHOD } from "utils/constants/constants";
import { userProperties } from "utils/constants/exports/constants";
import { getToken } from "redux/features/app/authTokenSlice";
import { useCustomNotification } from "hooks/useNotification";
import { Trans } from "react-i18next";
import { SuccessErrorEnum } from "utils/enums";
import PageLayout from "layout/PageLayout/PageLayout";
import { Attribute, FilterInfo, QueryPayload } from "utils/models";
import { fetchFunnelById, resetQueryPayload } from "redux/features/audience/queryPayloadSlice";
import FunnelChart from "components/Charts/FunnelChart/FunnelChart";
import { BOOLEAN_OPTIONS } from "utils/constants/selectorOptions";


const FunnelDetails:React.FC=()=>{
 const features = useSelector((state: any) => state.featuresSetting.data);
  const feature = features?.[0]?.features[FeatureSettingsFlagTypes.FUNNEL_V2];
  return (feature!==true && feature!==undefined)?<FunnelDetailsV2/>:<FunnelDetailsV1/>
}
const FunnelDetailsV1: React.FC = () => {
  interface RootState {
    loginUser: {
      data: {
        api_key: string;
      };
    };
  }
  interface ActiveAppState {
    activeApp: {
      appName: string;
      appId: string;
    };
  }
  interface Event {
    eventName: string;
    list: any[];
    selectedJourney: string;
  }

  interface Funnel {
    _id: string;
    events: Event[];
    name: string;
    description: string;
    duration: string;
    unit: string;
    customunit: string;
    platform: string[];
    customDate: Record<string, any>;
    id: string | null;
    createdOn: number;
    modifiedOn: number;
    deleted: boolean;
  }
  interface Attribute {
    attributeName: string;
    type: string;
    operator: string;
    value: string;
    e_operator: string;
  }
  interface StatsData {
    name: string;
    count: number;
    timeStamp: string;
    lastTimeStamp: string;
    customUnit: string;
    events: {
      eventName: string;
      operator: string;
      value: string;
      attributes: Attribute[];

      list: {
        name: string;
        type: string;
      }[];
    };
    p: string[];
    result:string;
  }

  interface SelectedSteps {
    [index: number]: "h" | "hn";
  }

  const { id } = useParams();
  const navigate = useNavigate();

  const loginUser = useSelector((state: RootState) => state.loginUser);
  const { appId } = useSelector((state: ActiveAppState) => state.activeApp);
  const [funnels, setFunnels] = useState<Funnel | null>(null);
  const dispatch: any = useDispatch();
  const {openNotification } = useCustomNotification();
  const [objectId, setObjectId] = useState("");

  const [statsData, setStatsData] = useState<StatsData[]>([]);

  const api_key = loginUser?.data.api_key;
  const { showSuccess, showError } = useMessage();

  const [launchCampaign, setLaunchCampaign] = useState<boolean>(false);
  const [funnelExport, setFunnelExport] = useState<boolean>(false);

  const getObject = async () => {
    try {
      const objectid = await getObjectId(appId, api_key);
      setObjectId(objectid);
    } catch (error) {
      showError(t("somethingWrongLabel"));
    }
  };
  const startExport = async (q: string) => {
    const payload = {
      api_key: api_key,
      app_id: appId,

      args: JSON.stringify({ q: q,name:funnels?.name}),
    };

    try {
      await centralApi("GET", API_ENDPOINTS.START_EXPORT, null, payload);
      openNotification(
        `${t("funnelExportInitiatedMsg")}`,
        <Trans
          i18nKey="visitTheExportsSectionMsg"
          components={{ 1: <Link to={ROUTES.DASHBOARD_EXPORTS} /> }}
        />,
        SuccessErrorEnum.SUCCESS,
        4
      );
    } catch {
      showError(t("somethingWrongLabel"));
    }
  };

  const getFunnel = async () => {
    const payload = {
      api_key: api_key,
      app_id: appId,
      id: id,
    };

    try {
      const response = await centralApi(
        "GET",
        API_ENDPOINTS.GETFUNNEL,
        null,
        payload
      );
      setFunnels(response);
    } catch (error: any) {
      showError(error.message);
    }
  };

  const getStats = async () => {
    const offset = addOffSet();
    const d = currentEpochTime();

    const payload = {
      api_key: api_key,
      app_id: appId,
      actor_property: "did",
      // args: serializedData,
      args: JSON.stringify(funnels),
      d,
      offset,
      platform: "all",
    };

    try {
      const response = await centralApi(
        "GET",
        API_ENDPOINTS.STATS,
        null,
        payload
      );
      setStatsData(response);
    } catch (error: any) {
      showError(error.message);
    }
  };
  // const totalFunnelSteps = statsData?.length;
  const totalFunnelSteps = (statsData && statsData?.result !== "Failed" && statsData.length > 0) ? statsData.length : 0;
  const generateSortedAttribute = (selectedSortAttribute: any) => {
    //   // check if there is event containing the selected attribute
    const event = statsData[parseInt(selectedSortAttribute.index)];
    if (event) {
      //check if that event has attributess
      const attribute = event.events.list.find(
        (attr) => attr.name === selectedSortAttribute.label
      );

      if (attribute) {
        return {
          index: selectedSortAttribute.index, // selected attribute index of its event
          name: selectedSortAttribute.name,
          value: selectedSortAttribute.label,
          step: `step${parseInt(selectedSortAttribute.index) + 1}Segment -> '${
            selectedSortAttribute.label
          }'`, //  step format
        };
      } else {
        // If event not found, handle as a generic column
        return {
          name: "column",
          value: selectedSortAttribute.label, // sortAttribute selected from dropdown
          col: "column",
        };
      }
    }
  };

  const handleCreateAudience = async (
    selectedSteps: SelectedSteps,
    name: string,
    description: string
  ) => {
    const what = statsData?.map((event, index) => ({
      operand: removeTrailingNumber(event.name), //remove _2 3 or anything appended to event name
      operator: event?.events?.operator || "", // Use default value if operator is not present
      value: event?.events?.value || "", // Use default value if value is not present
      category: "Events", // Category"
      attributes: event?.events?.attributes?.map(attr => ({
        attributeName: attr?.attributeName || "",
        type: attr?.type || "",
        operator: attr?.operator || "",
        value: attr?.value || "",
        e_operator: attr?.e_operator || ""
      })) || "", // Map attributes or use default value if not present
      since: { have: selectedSteps[index + 1] || "" }, // Use selectedSteps value
    }));
    const segmentInfo = {
      who: [],
      what: [...(what || [])], // Use default empty array if whatSegment is undefined
      where: [],
      when: [],
    };
    
    const uniqueNumber = getRandomNumber();
    try {
      const newToken = await dispatch(getToken()).unwrap();
      const params: any = {
        api_key: api_key,
        app_id: appId,
        uniqueNumber: uniqueNumber,
      };
      const data = new FormData();
      data.append("objectid", objectId);
      data.append("name", name);
      data.append("description", description);
      data.append("range", "0");
      data.append("segmentinfo", JSON.stringify(segmentInfo));
      data.append("api_key", api_key);
      data.append("app_id", appId);

      const resAud = await centralApi(
        "POST",
        API_ENDPOINTS.CREATE_AUDIENCE_URL,
        data,
        params,
        false,
        newToken
      );
      if (resAud?.name) {
        showSuccess(t("segmentCreatedSuccessfullyMsg"));
        navigate("/dashboard/campaign/create");
      } else {
        throw new Error();
      }
    } catch (error) {
      showError(t("somethingWrongLabel"));
    }
    dispatch(getToken())
  };
  const handleCountAudienceReach = async (
   params:any
  ) => {
    const { criteria, selectedSortAttribute, sortBy, attrColumns } = params;
    //chceck if it is not empty
    const sortedAttribute =
  Object.keys(selectedSortAttribute).length > 0
      && generateSortedAttribute(selectedSortAttribute);
    // events data for payload from Stats Api
    
const events = statsData?.map((events) => ({
      users: events?.count,
      key: events?.name,
      timeStamp: events?.timeStamp,
      lastTimeStamp: events?.lastTimeStamp,
      customUnit: events?.customUnit,
      events: events?.events,
      p: events?.p,
      attribute: events?.events?.attributes || "",
    }));

  
    const column = {
      sortedAttribute,
      userProperties,
      attributeColumns: attrColumns,
    };

    const { timeStamp, lastTimeStamp, p, customUnit } = statsData[0] || {};

    const method = METHOD.COUNTAUDIENCEREACH;
    const selectors = {
      column,
      countOrData: false,
      customUnit,
      events,
      execute: false,
      export: true,
      generate: true,
      lastTimeStamp,
      p,
      sortBy,
      timeStamp,
    };

    try {
      const payload: any = {
        api_key: api_key,
        app_id: appId,
        criteria: JSON.stringify(criteria),
        method,

        selectors,
      };
      const countAudience = await centralApi(
        "POST",
        API_ENDPOINTS.COUNT_AUDIENCE_REACH_URL,
        payload,
        null
      );
      if (countAudience?.result?.q) {
        startExport(countAudience?.result?.q);
      }
    } catch (error) {
      showError(t("somethingWrongLabel"));
    }
    dispatch(getToken())
  };
  
  
  

  const nameWithAttributes =totalFunnelSteps>0?
   statsData?.map((item) => {
    const strippedName = removeTrailingNumber(item.name || ""); //remove trailing number
    const attributes = item?.events?.attributes
      ?.map((attr: any) => `${attr.attributeName}`)
      .join(", ");
    const name = attributes
      ? `${strippedName} (${attributes})`
      : strippedName;
    return {
      name: name || "",
      count: item.count,
    };
  }):[]
  const validNameWithAttributes = Array.isArray(nameWithAttributes) && nameWithAttributes.length > 0
  ? nameWithAttributes.map((item) => ({
      name: item.name || "",
      count: item.count || 0
    }))
  : [];
  // Ensure that values are converted to numbers
const values = validNameWithAttributes.map((item) => Number(item.count)); //check for eventname has attributes
  useEffect(() => {
    if (totalFunnelSteps > 0) {
      //map graph only if data present
      const graph = new FunnelGraph({
        container: ".funnelContainer ",
        gradientDirection: "vertical",
        data: {
          labels: validNameWithAttributes?.map((item, index) =>
            `${item.name}${
              index ? `\nAverage step duration ${5 + index}m ${10 - index}s` : ""
            }\n${
              validNameWithAttributes.length - 1 === index
                ? `Average Turn around 20min 4s`
                : ""
            }`
          ),
      
    colors: ["orange", "red"], // Ensure you have enough colors if the number of labels exceeds the colors array
    values: values, //  the array of numbers for values

        },
        displayPercent: true,
        direction: "horizontal",
        width: 800,
        height: 350,
        subLabelValue: "raw",
      });

      graph.draw();
    }
  }, [statsData]);

  useEffect(() => {
    getFunnel();
    getObject();
  }, []);
  useEffect(() => {
    if (funnels !== null) {
      getStats();
    }
  }, [funnels]);

  return (
    <div className="container-fluid p-sm-2 p-2 ">
      <Helmet>
        <title>Appice | Funnel detail</title>
      </Helmet>
      <div className="d-flex justify-content-between align-items-center py-3 flex-wrap">
        <div>
          <CustomBreadCrumb
            items={[
              {
                title: <Link to="/dashboard/funnels">{t("funnelLabel")}</Link>,
              },
              {
                title: <Link to="">{t("detailLabel")}</Link>,
              },
            ]}
          />
        </div>
      </div>

      <div className="p-sm-3 px-1 funnel-detail-container">
        <div className="d-flex  justify-content-between mb-4">
          <h6>{funnels?.name}</h6>
          {totalFunnelSteps > 0 && (
            <div className="d-flex gap-2">
              <PrimaryButton
                onClick={() => {
                  setLaunchCampaign(true);
                }}
                type="primary"
              >
                {t("launchCampaignLabel")}
              </PrimaryButton>
              <PrimaryButton
                onClick={() => {
                  setFunnelExport(true);
                }}
                type="primary"
              >
                {t("funnelExportLabel")}
              </PrimaryButton>
            </div>
          )}
        </div>
        {totalFunnelSteps > 0 ? (
          <div>
            <div className="funnelContainer" />
          </div>
        ) : (
         <Spinner/>
        )}
                {totalFunnelSteps > 0 ? (
                  <>

        <LaunchCampaign
          launchCampaignState={launchCampaign}
          handleCreateAudience={handleCreateAudience} // Pass the handleSubmit function as the onSubmit prop
          totalSteps={totalFunnelSteps}
          setLaunchCampaignState={setLaunchCampaign}
        />

        <FunnelExport
          funnelExportState={funnelExport}
          handleCountAudienceReach={handleCountAudienceReach} // Pass the handleSubmit function as the onSubmit prop
          statsData={statsData}
          totalSteps={totalFunnelSteps}
          setFunnelExportState={setFunnelExport}
        /></>):null}
      </div>
    </div>
  );
};
//Funnel version 2
const FunnelDetailsV2: React.FC = () => {
  const { id } = useParams();
  const loginUser = useSelector((state: any) => state.loginUser.data);
  const { appId } = useSelector((state: any) => state.activeApp);
  const dispatch: any = useDispatch();
  const [chartData, setChartData] = useState<QueryPayload>();
  const [loading,setLoading]=useState(true)
  const [funnelExport, setFunnelExport] = useState<boolean>(BOOLEAN_OPTIONS.FALSE);
  const {openNotification } = useCustomNotification();
  const { showSuccess, showError } = useMessage();
  /**
 * Fetches chart data from the API based on the provided query payload.
 * @param {QueryPayload} data - The query parameters to construct the request payload.
 * @returns {Promise<any>} - The API response containing the chart data.
 */
const getChartData = async (data: QueryPayload) => {
  // Create a new instance of QueryPayload using the provided data
  const payload = new QueryPayload(data);
  // Construct request parameters
  const params = {
    api_key: loginUser.api_key, 
    app_id: appId,
    args: payload.toString(), // Convert payload to a string format for the API request
  };
  // Send GET request to the API and retrieve the response
  const response = await centralApi("GET", API_ENDPOINTS.STATS, null, params);
  return response; // Return API response
};

  useEffect(() => {
    if (id) {
      (async () => {
        try {
          setLoading(true)
          const data = await dispatch(
            fetchFunnelById({
              app_id: appId,
              api_key: loginUser.api_key,
              id,
            })
          ).unwrap();
          const chart_data = await getChartData(data);
          setChartData(chart_data);
          setLoading(false)
        } catch (error) {
          setLoading(false)
        }
      })();
    }
    return () => {
      dispatch(resetQueryPayload());
    };
  }, []);
  /**
 * Generates a formatted step title based on the provided filter item.
 * @param {FilterInfo} item - The filter item containing operand and attributes.
 * @returns {string} - The formatted step title.
 */
  const getStepTitle = (item: FilterInfo):string => {
  // Remove trailing numbers from the operand name
  const strippedName = removeTrailingNumber(item?.operand || ""); 
  // Extract attribute operands and join them into a string
  const attributes = item?.attributes
    ?.map((attr: Attribute) => `${attr?.operand || ""}`)
    .join(", ");
  // Combine operand name with attributes if they exist
  const name = attributes ? `${strippedName} (${attributes})` : strippedName;
  return name || "";
};

/**
 * Generates an HTML string representing drop-off attributes.
 * @param {Record<string, string>} dropoff - An object where keys represent drop-off attributes and values are their descriptions.
 * @returns {string} - The generated HTML string.
 */
function getDropOffAttributes(dropoff?: { [key: string]: string }):string {
  const htmlString = Object.keys(dropoff || {})
    .map((key) => 
      dropoff ? `<span>${key} : ${dropoff[key]}</span><br>` : ""
    )
    .join(""); // Join the array into a single string

  return htmlString; // Return the final HTML string
}

/**
 * 
 * @param params takes payload data from export and starts funnel v2 export
 */
const startExport = async (params: QueryPayload) => {
  const queryPayload = new QueryPayload(params);
  const payload = {
    api_key: loginUser.api_key,
    app_id: appId,
    args:queryPayload.toString() ,// Convert payload to a string format for the API request
  };

  try {
    await centralApi("GET", API_ENDPOINTS.START_EXPORT, null, payload);
    openNotification(
      `${t("funnelExportInitiatedMsg")}`,
      <Trans
        i18nKey="visitTheExportsSectionMsg"
        components={{ 1: <Link to={ROUTES.DASHBOARD_EXPORTS} /> }}
      />,
      SuccessErrorEnum.SUCCESS,
      4
    );
  } catch {
    showError(t("somethingWrongLabel"));
  }
};

  return (
    <PageLayout
      loading={loading}
      topLeftItem={
        <CustomBreadCrumb
          items={[
            {
              title: (
                <Link to={ROUTES.DASHBOARD_FUNNELS}>{t("funnelLabel")}</Link>
              ),
            },
            {
              title: <Link to="">{t("detailLabel")}</Link>,
            },
          ]}
        />
      }
    >
      <div className="d-flex  justify-content-between mb-4">
        <h6>{chartData?.name}</h6>
        <div className="d-flex gap-2">
          <PrimaryButton onClick={() => {}} type="primary">
            {t("launchCampaignLabel")}
          </PrimaryButton>
           <PrimaryButton
                onClick={() => {
                  setFunnelExport(BOOLEAN_OPTIONS.TRUE);
                }}
                type="primary"
              >
                {t("funnelExportLabel")}
              </PrimaryButton>
        </div>
      </div>
       {chartData?.filterInfo &&
       <>
        <FunnelChart data={
          chartData?.filterInfo.map((filterInfo)=>{
            return ({
                title:getStepTitle(filterInfo),
                avgStepDuration:filterInfo?.avg_step_duration,
                avgTotDuration:filterInfo?.avg_tot_duration,
                count:Number(filterInfo.count),
                footerLabel:getDropOffAttributes(filterInfo.dropoff),
                dropoffOperand:filterInfo.dropoff_operand
            })
          })
        }/> 
      
      </>
        }
        <FunnelExport
         v2={BOOLEAN_OPTIONS.TRUE}
         funnelExportState={funnelExport}
         handleCountAudienceReach={startExport} // Pass the handleSubmit function as the onSubmit prop
         setFunnelExportState={setFunnelExport}
      />
    </PageLayout>
  );
};


export default FunnelDetails;
