
export function dayHourWiseData(
  originalData: any[],
  filter: string,
  timeUnit: string,
  churn : boolean
): any[] {
  if (!originalData || originalData.length === 0) {
    // No data found, create a CSV with just the columns
    const columns = churn
    ? [timeUnit, "Platform", "InActive", "Repeat InActive", "android","ios","web"]
    : [timeUnit, "Platform", "New Installs", "Repeat Installs","android","ios","web", "Total"];


    return [columns.reduce((acc, col) => ({ ...acc, [col]: "" }), {})];
  }

  return originalData.map((item: any) => {
    const day = item.name;
    const platform = filter; // Replace this with the actual platform value
    const newInstalls = item.new;
    const repeatInstalls = item.repeat;
    const android= item.android
    const ios= item.ios
    const web= item.web

    const totalInstalls = newInstalls + repeatInstalls;
    // Determine the correct keys based on churn
    const keyNewInstalls = churn ? "InActive" : "New Installs";
    const keyRepeatInstalls = churn ? "Repeat InActive" : "Repeat Installs";
    return {
      [timeUnit]: item.name, // Dynamic property based on the provided timeUnit
      Platform: platform,
      [keyNewInstalls]: newInstalls,
      [keyRepeatInstalls]: repeatInstalls,
      android:android,
      ios:ios,
      web:web,
      
      Total: totalInstalls,
    };
  });
}

export function transformVersionData(originalData: any[]): any[] {
  if (!originalData || originalData.length === 0) {
    // No data found, create a CSV with just the columns
    const columns = [
      "Platform",
      "Version" ,
      "Total",
    ]
    return [columns.reduce((acc, col) => ({ ...acc, [col]: "" }), {})];
  }
  const transformedData: any[] = [];

  originalData.forEach((item: any) => {
    const [platform, version] = item.label.split(" "); // Assuming the label has a space between platform and version
    const total = item.value;

    const transformedItem = {
      Platform: platform,
      Version: version,
      Total: total,
    };

    transformedData.push(transformedItem);
  });

  return transformedData;
}

export function transformLocationData(
  originalData: any[],
  selectedFilter: string
): any[] {
  if (!originalData || originalData.length === 0) {
    // No data found, create a CSV with just the columns
    const columns = [selectedFilter === "city" ? "City" : "Country", "Total"];
    return [columns.reduce((acc, col) => ({ ...acc, [col]: "" }), {})];
  }

  const transformedData: any[] = [];

  originalData.forEach((item: any) => {
    const location = item.location;
    const total = item.cur;

    const transformedItem = {
      [selectedFilter === "city" ? "City" : "Country"]: location,
      Total: total,
    };

    transformedData.push(transformedItem);
  });

  return transformedData;
}
export function transformOsData(originalData: any[]): any[] {
  if (!originalData || originalData.length === 0) {
    // No data found, create a CSV with just the columns
    const columns = ["Platform", "Total"];
    return [columns.reduce((acc, col) => ({ ...acc, [col]: "" }), {})];
  }

  return originalData.map((version: any) => {
    return {
      Platform: version.label,
      Total: version.value,
    };
  });
}
