import "./AppManagement.css";
import CustomBreadCrumb from "components/CustomBreadCrumb/CustomBreadCrumb";
import { t } from "i18next";
import PageLayout from "layout/PageLayout/PageLayout";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import centralApi from "services/centralApi";
import { API_ENDPOINTS, ROUTES } from "utils/constants";
import { BOOLEAN_OPTIONS } from "utils/constants/selectorOptions";
import { Checkbox, Form } from "antd"; // Import Ant Design components
import PrimaryButton from "components/Buttons/PrimaryButton/PrimaryButton";
import { CopyTwoTone } from "@ant-design/icons";
import { copyText } from "helperFunctions/string";
import useMessage from "hooks/useMessage";
import NoData from "components/NoData/NoData";
import { fetchLoginUser } from "redux/features/user/loginUserSlice";

const AppManagement = () => {
  const [loading, setLoading] = useState(BOOLEAN_OPTIONS.FALSE);
  const [appsData, setAppsData] = useState<any[]>([]);
  const { showError, showSuccess } = useMessage();
  const [form] = Form.useForm(); // Form instance for Ant Design
  const loginUser = useSelector((state: any) => state.loginUser.data);
  const authToken = useSelector((state: any) => state.authToken.token);
  const navigate = useNavigate();
  const dispatch: any = useDispatch();

  // State to manage checkbox values
  const [checkboxValues, setCheckboxValues] = useState<Record<string, boolean>>({});

  //copy text and show message to the user
  const handleTextCopy = async (text: string) => {
    const res = await copyText(text);
    res.success ? showSuccess(res.message) : showError(res.message);
  };

  // Fetch apps data
  const fetchAppsData = async () => {
    try {
      
      if (loginUser.userOfApps && loginUser.userOfApps.length > 0) {
        setAppsData(loginUser.userOfApps);

        // Initialize checkbox values based on the `active` key (or any other key)
        const initialCheckboxValues = loginUser.userOfApps.reduce(
          (acc: Record<string, boolean>, app: any) => {
            acc[app._id] = app.show || BOOLEAN_OPTIONS.FALSE; // Use `active` key or default to false
            return acc;
          },
          {}
        );
        
        form.setFieldsValue(initialCheckboxValues); 
        setCheckboxValues(initialCheckboxValues);
      }
    } catch (error) {
      console.error("Error fetching apps data:", error);
    }
  };

  //CALL fetchAppsData FUNCTION AT THE START
  useEffect(() => {
    if (loginUser.api_key) {
      fetchAppsData();
    }
  }, []);

  /**
   * 
   * @param appId TAKED APPID WHICH HAS BEEN CHECKED OR UNCHECKED 
   * @param checked BOOLEAN VALUE 
   * RETURNS SETS VALUES IN CHECKBOXVALUES USE STATE AND ALSO SETS IT IN FORM FIELD 
   */
  const handleCheckboxChange = (appId: string, checked: boolean) => {
    // Update the local state
    setCheckboxValues((prevValues) => ({
      ...prevValues,
      [appId]: checked,
    }));
  
    // Update the form values
    form.setFieldsValue({
      [appId]: checked,
    });
  };


  // Handle form submission
  const onFormSubmit = async(values: any) => {
    try {
        // Transform the form values into an array of objects
        const formattedValues = Object.entries(values).map(([_id, value]) => ({_id,value,}));
        const body = {
            api_key: loginUser.api_key,
            authToken
          };
          const params:any = {
            api_key: loginUser.api_key,
            args: JSON.stringify({
                configData:formattedValues
            }),
          };
        setLoading(BOOLEAN_OPTIONS.TRUE);
        const response = await centralApi(
            "POST",
            API_ENDPOINTS.UPDATE_APP_MANAGEMENT,
            body,
            params
          )
          setLoading(BOOLEAN_OPTIONS.FALSE); 
          if(response){
            showSuccess(t("sucessfullySavedLabel"))
            navigate("/dashboard")
            dispatch(fetchLoginUser());
          }      
          
    } catch (error) {
        showError(t("somethingWrongLabel"))
        setLoading(BOOLEAN_OPTIONS.FALSE); 
    }

 
  };

  return (
    <div className="app-management-main-container px-1">
      <PageLayout
        title="App Management"
        loading={loading}
        topLeftItem={
          <CustomBreadCrumb
            items={[
              {
                title: (
                  <Link to={ROUTES.DASHBOARD_APPMANAGEMENT}>
                    {t("appManagementLabel")}
                  </Link>
                ),
              },
            ]}
          />
        }
      >
        <div className="mb-4">
          <h6>{t("appManagementSettingsLabel")}</h6>
          <p className="configure-apps-description-headings">
            {t("appManagementDescriptionLabel")}
          </p>
        </div>

        {/* Form with checkboxes */}
        <Form form={form} layout="vertical" onFinish={onFormSubmit}>
          <div className="app-management-checkbox-grid">
            {appsData.map((app) => (
              <Form.Item key={app._id} name={app._id}>
                <Checkbox
                  checked={checkboxValues[app._id]}
                  onChange={(e) =>
                    handleCheckboxChange(app._id, e.target.checked)
                  }
                >
                  <div className="d-flex flex-column">
                    <strong>{app.name}</strong>
                    <span className="text-disabled d-flex align-items-center justify-content-between configure-apps-description-headings gap-1">
                      {app._id}
                      <CopyTwoTone onClick={() => handleTextCopy(app._id)} />
                    </span>
                  </div>
                </Checkbox>
              </Form.Item>
            ))}
          </div>
          {/* if there are apps then show save button */}
          {appsData.length > 0 &&(
            <PrimaryButton
              size="middle"
              type="primary"
              htmlType="submit"
              style={{ border: "none", width: "70px" }}
            >
              {t("saveLabel")}
            </PrimaryButton>
            )}
            {/* if there are no apps in collection show no data */}
            {appsData.length === 0 &&(
             <NoData/>
            )}
        </Form>
      </PageLayout>
    </div>
  );
};

export default AppManagement;
