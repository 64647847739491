import { t } from "i18next";
import { BooleanStringEnum, PeriodEnum, PlatformTypeEnum, TimeUnitEnum } from "utils/enums";

export const PAGE_OPTIONS = {
  DATA: [
    {
      value: 10,
      label: 10,
    },
    {
      value: 20,
      label: 20,
    },
    {
      value: 50,
      label: 50,
    },
  ],

  DEFAULT_VALUE: 10,
  DEFAULT_SIZE: "middle",

  PAGINATION: {
    current: 1,
    pageSize: 10,
    simple: false,
  },
};

export const TABLE_OPTIONS = {
  SIZE: "small",
};

export const STRING_BOOLEAN_OPTIONS = {
  TRUE: "true",
  FALSE: "false",
  BOOLEAN:"boolean"

};

export const BOOLEAN_OPTIONS = {
  TRUE: true,
  FALSE: false,
};

export const APNS_FILE_TYPE_OPTIONS = {
  P8: "p8",
  P12: "p12",
};

export const MODE_OPTIONS = {
  DEV: "dev",
  PROD: "prod",
};
export const E_OPERATOR_OPTIONS = [
  { label: "AND", 
    value: "AND" },
  {
    label: "OR",
    value: "OR",
  },
];
export const platformOptions = ()=>[
  {
    value: PlatformTypeEnum.ANDROID,
    label: t("androidLabel"),
  },
  {
    value: PlatformTypeEnum.IOS,
    label: t("iosLabel"),
  },
  {
    value: PlatformTypeEnum.WEB,
    label: t("webLabel"),
  },
];
export const timeUnitOptions=()=>[
  {
    label: t("secondsLabel"),
    value: TimeUnitEnum.SECONDS,
  },
  {
    label: t("minutesLabel"),
    value: TimeUnitEnum.MINUTES,
  },
  {
    label: t("hoursLabel"),
    value: TimeUnitEnum.HOURS,
  },
  {
    label: t("dayLabel"),
    value: TimeUnitEnum.DAYS,
  },
  {
    label: t("weekLabel"),
    value: TimeUnitEnum.WEEKS,
  },
  {
    label: t("monthLabel"),
    value: TimeUnitEnum.MONTHS,
  },
]

export const periodOptions=(isAudience:boolean=false)=>[
  {
    label: t("yesterdayLabel"),
    value: PeriodEnum.YESTERDAY,
  },
  {
    label: t("todayLabel"),
    value: PeriodEnum.TODAY,
  },
  {
    label: t("tomorrowLabel"),
    value: PeriodEnum.TOMORROW,
  },...(!isAudience
    ? [
        {
          label: t("thisWeekLabel"),
          value: PeriodEnum.THIS_WEEK,
        },
        {
          label: t("lastWeekLabel"),
          value: PeriodEnum.LAST_WEEK,
        },
        {
          label: t("thisMonthLabel"),
          value: PeriodEnum.THIS_MONTH,
        },
        {
          label: t("lastMonthLabel"),
          value: PeriodEnum.LAST_MONTH,
        },
        {
          label: t("customLabel"),
          value: PeriodEnum.CUSTOM,
        },
      ]
    : []),
]
export const booleanStringOptions=()=>[{
  label:t("trueLabel"),
  value:BooleanStringEnum.TRUE
},
{
  label:t("falseLabel"),
  value:BooleanStringEnum.FALSE
}]
