import { FormInstance, Steps } from "antd";
import PrimaryButton from "components/Buttons/PrimaryButton/PrimaryButton";
import { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addSegment,
  removeSegment,
  setAudienceSegmentInfo,
} from "redux/features/audience/audienceSegmentDataSlice";
import { AudienceEnum, E_OperatorEnum } from "utils/enums";
import UserTraitParent from "../UserTrait/UserTraitParent/UserTraitParent";
import AudienceEvent from "../AudienceEvent/AudienceEvent";
import FilterPopup from "components/PopUp/FilterPopup/FilterPopup";
import { t } from "i18next";
import { audienceItems } from "helperFunctions/audience";
import { useNavigate } from "react-router-dom";
import ComputedTrait from "components/ComputedTrait/ComputedTrait";
import centralApi from "services/centralApi";
import { API_ENDPOINTS, ROUTES } from "utils/constants";
import SegmentBlockLayout from "layout/SegmentBlockLayout/SegmentBlockLayout";
import {
  formatComputedTraitFilterInfo,
  restructureFilterConditions,
} from "redux/features/audience/queryPayloadSlice";
import SegmentedCustom from "components/SegmentedCustom/SegmentedCustom";
import { E_OPERATOR_OPTIONS } from "utils/constants/selectorOptions";
import { FilterInfo } from "utils/models";

interface QueryStepsProps {
  form: FormInstance;
}

const QuerySteps: FC<QueryStepsProps> = ({ form }) => {
  
  const segment = useSelector((state: any) => state.audienceSegmentData.data);
  const loginUser = useSelector((state: any) => state.loginUser.data);
  const { appId } = useSelector((state: any) => state.activeApp);
  const traitList = useSelector((state: any) => state.computedTraitList.data);
  const { who, what, cTrait = [] } = segment?.segmentinfo;
  const navigate = useNavigate();
  const dispatch: any = useDispatch();
  const fetchComputedTrait = async (id: string) => {
    try {
      const params = {
        api_key: loginUser.api_key,
        app_id: appId,
        id,
      };
      return await centralApi(
        "GET",
        API_ENDPOINTS.TRAIT_BY_ID_URL,
        null,
        params
      );
    } catch (error) {
      return undefined;
    }
  };
  //restructure filter info data
  const restructureFilterInfo=(filterInfo:FilterInfo[])=>{
    let filter_info=formatComputedTraitFilterInfo(filterInfo);
    filter_info=restructureFilterConditions(filter_info)
    return filter_info
  }
  //adds new condition
  async function addCondition({ key }: { key: string }) {
    if (key == AudienceEnum.WHO) {
      dispatch(addSegment({ segmentType: AudienceEnum.WHO, data: {} }));
    } else if (key == AudienceEnum.LIVE || key == AudienceEnum.PAST || key == AudienceEnum.JOURNEY) {
      dispatch(
        addSegment({
          segmentType: AudienceEnum.WHAT,
          data: {
            category: AudienceEnum.EVENTS,
            isLive: key == AudienceEnum.LIVE,
            isJourney:key== AudienceEnum.JOURNEY,
            e_operator: AudienceEnum.AND,
          },
        })
      );
    } else if (key == AudienceEnum.CTRAIT) {
      navigate(ROUTES.DASHBOARD_AUDIENCE_TRAIT_CREATE);
    }  else {
      const computedTrait = await fetchComputedTrait(key);
            if (computedTrait) {
        dispatch(
          addSegment({
            segmentType: AudienceEnum.CTRAIT,
            data:computedTrait ,
          })
        );
      }
    }
  }
  const getTreeItems = () => {
    const getButton=(index:number,item:any)=>{
    return item?.category==AudienceEnum.EVENTS && (who?.length || index)?<SegmentedCustom value={item?.e_operator} onChange={(value:E_OperatorEnum)=>{
      dispatch(
        setAudienceSegmentInfo({
          segmentType: AudienceEnum.WHAT, // Segment type
          indexToModify: index, // Index of the block to modify
          category:item.category , // Category of the segment
          data:{...item,e_operator:value}, // Data to update
        })
      );
    }} options={E_OPERATOR_OPTIONS}/>:<PrimaryButton   type="primary">{t("andLabel")}</PrimaryButton>
    }
    return [
      ...who.map((item: any, index: number) => ({
        icon: getButton(index,item),
        title: (
          <SegmentBlockLayout
            key={item.id}
            onDeleteClick={() => {
              dispatch(
                removeSegment({
                  segmentType: AudienceEnum.WHO,
                  category: item?.category,
                  indexToRemove: index,
                })
              );
            }}
          >
            <UserTraitParent id={item.id} form={form} blockIndex={index} />
          </SegmentBlockLayout>
        ),
      })),
      ...what.map((item: any, index: number) => ({
        icon: getButton(index,item),
        title: (
          <SegmentBlockLayout
            key={item.id}
            onDeleteClick={() => {
              dispatch(
                removeSegment({
                  segmentType: AudienceEnum.WHAT,
                  indexToRemove: index,
                })
              );
            }}
          >
            <AudienceEvent
              isFunnel={false}
              onDataChange={(data) => {
                dispatch(
                  setAudienceSegmentInfo({
                    segmentType: AudienceEnum.WHAT, // Segment type
                    indexToModify: index, // Index of the block to modify
                    category: AudienceEnum.EVENTS, // Category of the segment
                    data, // Data to update
                  })
                );
              }}
              segment={item}
              form={form}
              id={item.id}
            />
          </SegmentBlockLayout>
        ),
      })),
      ...cTrait.map((item: any, index: number) => ({
        icon: getButton(index,item),
        title: (
          <SegmentBlockLayout
            key={item._id}
            onDeleteClick={() => {
              dispatch(
                removeSegment({
                  segmentType: AudienceEnum.CTRAIT,
                  indexToRemove: index,
                })
              );
            }}
          >
            <ComputedTrait trait={{...item,filterInfo:restructureFilterInfo(item.filterInfo || [])}} key={item._id} />
          </SegmentBlockLayout>
        ),
      })),
      {
        title: "",
        icon: (
          <FilterPopup
            data={audienceItems.getfilterPopupData(traitList)}
            onItemClick={addCondition}
          >
            <PrimaryButton type="primary">
              {t("addConditionLabel")}
            </PrimaryButton>
          </FilterPopup>
        ),
      },
    ];
  };
  return (
    <div>
      <Steps direction="vertical" current={100} items={getTreeItems()} />
    </div>
  );
};

export default QuerySteps;
