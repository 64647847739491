import React, { useEffect, useRef, useState } from "react";
import PrimaryButton from "components/Buttons/PrimaryButton/PrimaryButton";
import filterIcon from "images/_filter.svg";
import { Form, Steps } from "antd";
import TextInput from "components/Inputs/TextInput/TextInput";
import Selector from "components/Selector/Selector";
import { Link, useLocation, useNavigate } from "react-router-dom";
import CustomBreadCrumb from "components/CustomBreadCrumb/CustomBreadCrumb";
import "./CreateFunnels.css";
import Spinner from "components/Spinner/Spinner";
import {
  validateNoSpecialChars,
  validateNotEmpty,
} from "validator/antdValidator";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { t } from "i18next";
import useMessage from "hooks/useMessage";
import SegmentBlockLayout from "layout/SegmentBlockLayout/SegmentBlockLayout";
import {
  API_ENDPOINTS,
  periodOptions,
  platformOptions,
  ROUTES,
  timeUnitOptions,
} from "utils/constants";
import centralApi from "services/centralApi";
import { getJourneyTypeList } from "helperFunctions/events";
import AudienceEvent from "pages/Audience/AudienceCreate/components/AudienceEvent/AudienceEvent";
import { FilterInfo, Funnel, QueryPayload } from "utils/models";
import { fetchEventList } from "redux/features/event/eventListSlice";
import { getToken } from "redux/features/app/authTokenSlice";
import PageLayout from "layout/PageLayout/PageLayout";
import { CreateHeader } from "pages/Audience/AudienceCreate/components/CreateHeader/CreateHeader";
import { AudienceManagementPageType,DBPlatformEnum } from "utils/enums";
import { addFilterCondition, fetchFunnelById, removeFilterCondition, setQueryPayloadData, resetQueryPayload } from "redux/features/audience/queryPayloadSlice";
import { STATUS } from "redux/constant";
import { DateRangeSelector } from "components/DateTimeSelectors/DateRangeSelector/DateRangeSelector";
import { FeatureSettingsFlagTypes } from "utils/constants/constants";
import EventCondition from "./components/EventCondition/EventCondition";

const CreateFunnels: React.FC = () => {
  const features = useSelector((state: any) => state.featuresSetting.data);
  const feature = features?.[0]?.features[FeatureSettingsFlagTypes.FUNNEL_V2];
  return (feature!==true && feature!==undefined)?<CreateFunnelV2/>:<CreateFunnelV1/>
}
export default CreateFunnels;
const CreateFunnelV1: React.FC = () => {
  // Initial condition object for new funnel step
  const initialCondition = {
    eventName: undefined,
    list: [],
    attributes: [],
  };
  // In the edit case, the funnel state ([funnel, setFunnel]) holds the original funnel name,
  // which is used for name validation.
  // This value remains unchanged during input updates,
  // retaining the initial funnel name throughout the editing scenario.
  // The loading state is managed separately to handle loading indicators
  // without affecting the original funnel name.
  const [funnel, setFunnel] = useState({ name: "", fetchloading: false ,submitLoading:false });
  const [funnelData, setFunnelData] = useState<any>({
    events: [initialCondition],
  });
  const navigate = useNavigate();
  const location = useLocation();
  const loginUser = useSelector((state: any) => state.loginUser.data);
  const { appId } = useSelector((state: any) => state.activeApp);
  const { showError, showSuccess } = useMessage();
  const eventList = useSelector((state: any) => state.eventList);
  const authToken = useSelector((state: any) => state.authToken.token);
  const [form] = Form.useForm();
  const dispatch: any = useDispatch();
  const funnelId = location.state?.id;
  // Function to fetch funnel details if funnelId exists(for edit case)
  const getFunnel = async () => {
    try {
      const payload = {
        api_key: loginUser.api_key,
        app_id: appId,
        id: funnelId, // Funnel ID to retrieve specific funnel details
      };

      // API call to fetch the funnel data and update the state
      const response = await centralApi(
        "GET",
        API_ENDPOINTS.GETFUNNEL,
        null,
        payload
      );
      setFunnelData(response); // Update funnel data in state
      return response;
    } catch (error) {}
  };

  // Function to either create a new funnel or update an existing one
  const createOrUpdateFunnel = async (data: any) => {
    try {
      const token=await dispatch(getToken()).unwrap();
      const createFunnelObj = new Funnel(data);
      const serializedData = createFunnelObj.toString(); // Serialize funnel data
      const payload = {
        api_key: loginUser.api_key,
        app_id: appId,
        data: serializedData,
        id: funnelId,
        authToken:token,
      };
      const params = {
        app_id: appId,
        api_key: loginUser.api_key,
        id: funnelId,
      };
      // API call to create or update the funnel
      await centralApi(
        "POST",
        funnelId ? API_ENDPOINTS.FUNNEL_UPDATE : API_ENDPOINTS.CREATE_FUNNEL,
        payload,
        params
      );
      // Show success message based on whether it was a creation or update action
      showSuccess(
        funnelId
          ? t("funnelUpdatedSuccessfullyLabel")
          : t("funnelCreatedSuccessfullyLabel")
      );
      navigate(ROUTES.DASHBOARD_FUNNELS); // Navigate back to funnel dashboard
      
    } catch (error) {
      // Show error message if the API call fails
      showError(t("somethingWrongLabel"));
    }
  };
  // };

  // Function to validate if a funnel name is already taken
  const validateFunnelName = async (name: string) => {
    const payload = {
      api_key: loginUser?.api_key,
      app_id: appId,
      funnelName: name,
    };

    return await centralApi(
      "GET",
      API_ENDPOINTS.VALIDATE_FUNNEL_NAME,
      null,
      payload
    );
  };
  //remove the filter condition
  const removeFunnelStep = () => {
    setFunnelData((prevState: any) => {
      // Create a copy of the previous state
      const state = { ...prevState };
      // Create a new array without mutating the original one
      state.events = state.events.slice(0, -1);
      return state;
    });
  };
  // Handle changes in the form and update funnel data accordingly
  const handleFormChange = (data: any) => {
    setFunnelData((prevState: any) => {
      return { ...prevState, ...data }; // Merge updated form data with current state
    });
  };
  //when user submit the form
  const onFormSubmit = async () => {
    try {
      setFunnel((prevState)=>({...prevState,submitLoading:true}))
      // Check if the funnel name was changed and needs validation
      if (funnelData.name !== funnel.name) {
        const isNotValid = await validateFunnelName(funnelData.name);
        if (isNotValid) {
          // Show error message if the funnel name is already taken
          form.setFields([
            {
              name: "name",
              errors: [t("funnelNameExistsErrorMessage")],
            },
          ]);
          setFunnel((prevState)=>({...prevState,submitLoading:false}))
          return; // Stop execution if the name is invalid
        }
      }
      // Prepare payload with form data for API call
      const payload = {
        ...funnelData,
        duration: funnelData.duration || "",
        customunit: funnelData.customunit || "",
        customDate: {},
      };
      // Call the function to create or update the funnel
     await createOrUpdateFunnel(payload);
    

    } catch (error) {
      // Show error message if something goes wrong during submission
      showError(t("somethingWrongLabel"));
    }
    setFunnel((prevState)=>({...prevState,submitLoading:false}))

  };
  // Function to generate funnel steps as tree items
  const getTreeItems = () => {
    const conditionItems = funnelData.events?.map(
      (item: any, index: number) => ({
        icon: (
          <PrimaryButton className="px-2" type="primary">
            {index === 0 ? t("allLabel") : t("andLabel")}
          </PrimaryButton>
        ),
        title: (
          <SegmentBlockLayout
            key={index}
            index={index}
            onDeleteClick={
              index === funnelData.events.length - 1
                ? () => removeFunnelStep()
                : undefined
            }
            showSteps={true}
          >
            <AudienceEvent
              segment={{ ...item, journey: funnelData?.journey }}
              isFunnel={true}
              onDataChange={(data) => {
                setFunnelData((prevState: any) => {
                  const { events } = prevState; // Create a copy of the array
                  // Optionally, create a copy of events if you plan to modify it
                  const updatedEvents = [...events];
                  const modifiedData = { ...data };
                  if (data.operand) {
                    modifiedData.eventName = data.operand;
                    delete modifiedData.operand;
                  }
                  updatedEvents[index] = {
                    ...updatedEvents[index],
                    ...modifiedData, //added new attribute here for multiple attribute
                  };
                  return { ...prevState, events: updatedEvents };
                });
              }}
              form={form}
              id={index}
            />
          </SegmentBlockLayout>
        ),
      })
    );
    // Button to add a new funnel step (condition)
    const addConditionButton = {
      id: null,
      icon: (
        <PrimaryButton
          type="primary"
          onClick={() => {
            //add new condition
            handleFormChange({
              events: [...(funnelData.events || []), { ...initialCondition }],
            });
          }}
          disabled={funnelData.events?.length >= 5} //ifselected steps length > 5
        >
          {t("addConditionLabel")}
        </PrimaryButton>
      ),
      title: "",
    };
    return [...(conditionItems || []), addConditionButton];
  };

  // Fetch event list and funnel data when component mounts or loginUser changes
  useEffect(() => {
    (async () => {
      if (loginUser.api_key && appId) {
        setFunnel((prevState) => ({ ...prevState, fetchloading: true })); // Show loading state
        // Fetch event list based on app_id and api_key
        await dispatch(
          fetchEventList({ api_key: loginUser.api_key, app_id: appId })
        );
        let funnelName: string;
        // Fetch funnel data if funnelId exists (edit mode)
        if (funnelId) {
          const { name } = await getFunnel(); // Load funnel data for editing
          funnelName = name;
        }
        //set funnel name for name validation and set loading false
        setFunnel((prevState) => ({...prevState, name: funnelName, fetchloading: false }));
      }
    })(); //call method
  }, [loginUser]); // Trigger useEffect when loginUser changes
  return (
    <div className="container-fluid funnels-create-container px-1">
      <Helmet>
        <title>Appice | Funnel | {funnelId ? "Edit" : "Create"}</title>
      </Helmet>
      <div className="pb-4 pt-1">
        <CustomBreadCrumb
          items={[
            {
              title: (
                <Link to={ROUTES.DASHBOARD_FUNNELS}>{t("funnelsLabel")}</Link>
              ),
            },
            {
              title: (
                <Link to={ROUTES.DASHBOARD_FUNNELS_CREATE}>
                  {funnelId ? t("editLabel") : t("createLabel")}
                </Link>
              ),
            },
          ]}
        />
      </div>

      <div
        className="px-sm-3 py-4 px-2 d-flex flex-column "
        style={{ background: "var(--color-other-white)" }}
      >
        {funnel.fetchloading ? (
          <Spinner />
        ) : (
          <Form  form={form} onFinish={onFormSubmit}>
            <div  className="d-flex  justify-content-between gap-1 align-items-start ">
              {/* for icon and title of header */}
              <div className="d-flex align-items-center flex-wrap justify-content-between w-100">
                <div className="d-flex   align-items-center">
                  <span className="d-flex   filter-icon-container justify-content-center ">
                    <img
                      src={filterIcon}
                      height={"100%"}
                      width={"100%"}
                      alt="filter"
                    ></img>
                  </span>
                  <span>
                    <h5 className="m-0 mx-2">
                      {" "}
                      {funnelId
                        ? t("editFunnelLabel")
                        : t("createNewFunnelLabel")}
                    </h5>
                  </span>
                </div>
                {/* </span> */}
                <div className="d-flex flex-wrap gap-1">
                  <Form.Item
                    rules={[
                      {
                        validator: validateNotEmpty,
                        message: `${t("selectPeriodMsg")}`,
                      },
                    ]}
                    name={"unit"}
                    className="m-0 input-size-md"
                    initialValue={funnelData?.unit} //when thr is edit page
                  >
                    <Selector
                      onChange={(value) => {
                        handleFormChange({
                          unit: value,
                          duration: undefined,
                          customunit: undefined,
                        });
                      }}
                      placeholder={t("selectPeriodMsg")}
                      options={periodOptions()}
                    />
                  </Form.Item>

                  {funnelData.unit == "Custom" && (
                    <>
                      <Form.Item
                        name={"duration"}
                        className="m-0 input-size-sm"
                        initialValue={funnelData?.duration} //when thr is edit page
                        rules={[
                          {
                            validator: validateNoSpecialChars,
                          },
                          {
                            validator: validateNotEmpty,
                            message: `${t("inputFunnelDescriptionMsg")}`,
                          },
                        ]}
                      >
                        <TextInput
                          onChange={(e: any) => {
                            handleFormChange({ duration: e.target.value });
                          }}
                          placeholder={t("valueLabel")}
                        ></TextInput>
                      </Form.Item>
                      <Form.Item
                        className="m-0 input-size-sm"
                        rules={[
                          {
                            validator: validateNotEmpty,
                            message: `${t("selectPeriodMsg")}`,
                          },
                        ]}
                        name={"customunit"}
                        initialValue={funnelData?.customunit} //when thr is edit page
                      >
                        <Selector
                          onChange={(value) => {
                            handleFormChange({ customunit: value });
                          }}
                          placeholder={t("periodLabel")}
                          options={timeUnitOptions()}
                        ></Selector>
                      </Form.Item>
                    </>
                  )}
                  <Form.Item
                    rules={[
                      {
                        validator: validateNotEmpty,
                        message: `${t("selectPlatformMsg")}`,
                      },
                    ]}
                    name={"platform"}
                    className="m-0 input-size-md"
                    initialValue={funnelData?.platform} //when thr is edit page
                  >
                    <Selector
                      onChange={(value) =>
                        handleFormChange({ platform: value })
                      }
                      mode="multiple"
                      placeholder={t("selectPlatformMsg")}
                      options={platformOptions()}
                    />
                  </Form.Item>
                </div>
              </div>
              <PrimaryButton
                className="mt-1"
                htmlType="submit"
                type="primary"
                loading={funnel.submitLoading}
              >
                {funnelId ? t("updateFunnelLabel") : t("saveFunnelLabel")}
              </PrimaryButton>
              {/* for buttons of header */}
            </div>
            <div className="d-flex gap-5 flex-wrap pt-4 ">
                <div >
                  <strong>{t("funnelNameLabel")}</strong>
                  <Form.Item
                    className="input-size-xlg"
                    rules={[
                      {
                        validator: validateNoSpecialChars,
                      },
                      {
                        validator: validateNotEmpty,
                        message: `${t("inputFunnelNameMsg")}`,
                      },
                    ]}
                    name={"name"}
                    initialValue={funnelData?.name} //when thr is edit page
                  >
                    <TextInput
                      onChange={(e: any) => {
                        handleFormChange({ name: e.target.value });
                      }}
                    />
                  </Form.Item>
                </div>
                <div >
                  <strong>{t("funnelDescriptionLabel")}</strong>
                  <Form.Item
                    name={"description"}
                    className="input-size-xlg"
                    initialValue={funnelData?.description} //when thr is edit page
                    rules={[
                      {
                        validator: validateNoSpecialChars,
                      },
                      {
                        validator: validateNotEmpty,
                        message: `${t("inputFunnelDescriptionMsg")}`,
                      },
                    ]}
                  >
                    <TextInput
                      onChange={(e: any) => {
                        handleFormChange({ description: e.target.value });
                      }}
                    />
                  </Form.Item>
                </div>
            </div>
            <div className="mb-3 ">
              <strong className="mb-1">{t("selectJourneyMsg")}</strong>

              <Form.Item
                name={"journey"}
                className="input-size-md"
                initialValue={funnelData.journey}
                rules={[
                  {
                    validator: validateNotEmpty,
                    message: `${t("selectJourneyMsg")}`,
                  },
                ]}
              >
                <Selector
                  onChange={(value) => {
                    handleFormChange({
                      journey: value,
                      events: [initialCondition],
                    });
                    form.setFieldsValue({ [`operand_${0}`]: undefined });
                  }}
                  placeholder={`${t("selectJourneyMsg")}`}
                  options={getJourneyTypeList(eventList.data)}
                ></Selector>
              </Form.Item>
            </div>
            {/* steps starts from here */}

            <div>
              <Steps
                direction="vertical"
                current={100}
                items={getTreeItems()}
              />
            </div>
          </Form>
        )}
      </div>
    </div>
  );
};
const CreateFunnelV2 = () => {
  // In the edit case, the funnel state ([funnelData, setFunnelData]) holds the original funnel name,
  // which is used for name validation.
  // This value remains unchanged during input updates,
  // retaining the initial funnel name throughout the editing scenario.
  // The loading state is managed separately to handle loading indicators
  // without affecting the original funnel name.
  const [loading, setLoading] = useState(false);
  const nameRef=useRef(null)
  const { data: funnel, status: funnelStatus } = useSelector(
    (state: any) => state.queryPayload
  );
  
  const navigate = useNavigate();
  const location = useLocation();
  const loginUser = useSelector((state: any) => state.loginUser.data);
  const { appId } = useSelector((state: any) => state.activeApp);
  const { showError, showSuccess } = useMessage();
  const authToken = useSelector((state: any) => state.authToken.token);
  const [form] = Form.useForm();
  const dispatch: any = useDispatch();
  const funnelId = location.state?.id;

  // Function to either create a new funnel or update an existing one
  const createOrUpdateFunnel = async () => {
    try {
      setLoading(true)
      //dont validate funnel name if the name has not been changed (for edit case)
      if(funnel.name!==nameRef.current){
        const nameInvalid=await validateFunnelName(funnel.name)
        if (nameInvalid) {
          // Show error message if the funnel name is already taken
          form.setFields([
            {
              name: "name",
              errors: [t("funnelNameExistsErrorMessage")],
            },
          ]);
          setLoading(false)
          return; // Stop execution if the name is invalid
        }
      }
      const token=await dispatch(getToken()).unwrap();
      const queryPayload = new QueryPayload({...funnel,tableInfo:{tableName:`app_users_${appId}`}});
      const payload = {
        api_key: loginUser.api_key,
        app_id: appId,
        id: funnelId,
        authToken:token,
        data: queryPayload.toString(),
      };
      const params = {
        app_id: appId,
        api_key: loginUser.api_key,
        id: funnelId,
      };
      // API call to create or update the funnel
      await centralApi(
        "POST",
        funnelId ? API_ENDPOINTS.FUNNEL_UPDATE : API_ENDPOINTS.CREATE_FUNNEL,
        payload,
        params
      );
      // Show success message based on whether it was a creation or update action
      showSuccess(
        funnelId
          ? t("funnelUpdatedSuccessfullyLabel")
          : t("funnelCreatedSuccessfullyLabel")
      );
      navigate(ROUTES.DASHBOARD_FUNNELS); // Navigate back to funnel dashboard
    } catch (error) {
      // Show error message if the API call fails
      showError(t("somethingWrongLabel"));
    }
    setLoading(false)
  };

  // Function to validate if a funnel name is already taken
  const validateFunnelName = async (name: string) => {
    const payload = {
      api_key: loginUser?.api_key,
      app_id: appId,
      funnelName: name,
    };

    return await centralApi(
      "GET",
      API_ENDPOINTS.VALIDATE_FUNNEL_NAME,
      null,
      payload
    );
  };

  const stepItems = () => {
    return [
      ...(funnel?.filterInfo || []).map(
        (condition: FilterInfo, index: number) => ({
          icon: (
            <PrimaryButton  type="primary">
              Step {index + 1}
            </PrimaryButton>
          ),
          // Title of the step, including the condition details
          title: (
            <SegmentBlockLayout
              onDeleteClick={
                index == funnel.filterInfo?.length - 1
                  ? () => dispatch(removeFilterCondition({ index }))
                  : undefined
              }
            >
              <EventCondition  form={form} index={index} condition={condition} />
            </SegmentBlockLayout>
          ),
        })
      ),
      {
        icon: (
          <PrimaryButton
            onClick={() => dispatch(addFilterCondition({}))}
            type="primary"
            disabled={!funnel.journey}
          >
            {t("addConditionLabel")}
          </PrimaryButton>
        ),
      },
    ];
  };
  useEffect(() => {
    if (location.state?.id) {
      (async () => {
       const {name}= await dispatch(
          fetchFunnelById({
            app_id: appId,
            api_key: loginUser.api_key,
            id: location.state.id,
          })
        ).unwrap();
        nameRef.current=name
      })();
    }
    return () => {
      dispatch(resetQueryPayload());
    };
  }, [location.state]);
  useEffect(() => {
    form.setFieldsValue(funnel);
  }, [funnel]);
  useEffect(() => {
    dispatch(fetchEventList({ app_id: appId, api_key: loginUser.api_key }));
  }, []);
  return (
    <PageLayout
      className="funnels-create-container"
      loading={funnelStatus == STATUS.LOADING}
      title={`${t("funnelLabel")} | ${t("createLabel")}`}
      topLeftItem={
        <CustomBreadCrumb
          items={[
            {
              title: (
                <Link to={ROUTES.DASHBOARD_FUNNELS}>{t("funnelsLabel")}</Link>
              ),
            },
            {
              title: (
                <span>
                  {funnelId ? t("editLabel") : t("createLabel")}
                </span>
                
              ),
            },
          ]}
        />
      }
    >
      <Form onFinish={createOrUpdateFunnel} form={form}>
        <CreateHeader
          initialValues={{
            name: funnel.name,
            description: funnel.description,
            journey: funnel.journey,
          }}
          saveButtonProps={{disabled:!funnel?.filterInfo?.length || !funnel?.sd || !funnel?.ed,loading}}
          type={AudienceManagementPageType.FUNNEL}
          onNameChange={(e) => {
            dispatch(setQueryPayloadData({ name: e.target.value }));
          }}
          onDescriptionChange={(e) => {
            dispatch(setQueryPayloadData({ description: e.target.value }));
          }}
          onJourneyChange={(value) => {
            dispatch(setQueryPayloadData({ journey: value, filterInfo: [] }));
          }}
          headerRightItem={
            funnelStatus !== STATUS.LOADING && (
              <>
                <DateRangeSelector
                
                  initialValue={{ sd: funnel?.sd, ed: funnel?.ed}}
                  onDateChange={({ startDate_C, endDate_C }) => {
                    dispatch(
                      setQueryPayloadData({ sd: startDate_C, ed: endDate_C })
                    );
                  }}
                  disableRange={{ range: 1, rangeType: "year" }}
                  disableType="year"
                />
                <Form.Item
                  rules={[
                    {
                      validator: validateNotEmpty,
                      message: `${t("selectPlatformMsg")}`,
                    },
                  ]}
                  name={"platform"}
                  className="m-0 input-size-md"
                  initialValue={funnel?.p} //when thr is edit page
                >
                  <Selector
                    onChange={(value) =>
                      dispatch(setQueryPayloadData({ p: value }))
                    }
                    mode="multiple"
                    placeholder={t("selectPlatformMsg")}
                    options={[{
                        value: DBPlatformEnum.ANDROID,
                        label: t("androidLabel"),
                      },
                      {
                        value: DBPlatformEnum.IOS,
                        label: t("iosLabel"),
                      },
                      {
                        value: DBPlatformEnum.WEB,
                        label: t("webLabel"),
                      }]}
                  />
                </Form.Item>
              </>
            )
          }
        ></CreateHeader>

        <Steps direction="vertical" current={100} items={stepItems()} />
      </Form>
    </PageLayout>
  );
};

