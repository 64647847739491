export const API_ENDPOINTS = {
  COUNT_TRAIT_REACH_URL: "/o/trait/countTraitReach",
  COUNT_AUDIENCE_REACH_URL: "o/aud/countAudienceReach",
  CREATE_AUDIENCE_URL: "i/aud/create",
  TRAIT_BY_ID_URL: "o/trait/getTraitById",
  UPDATE_AUDIENCE_URL: "i/aud/update",
  VALIDATE_SEGMENT_NAME_URL: "o/aud/validateSegmentName",
  DELETE_TRAIT_URL: "i/trait/delete",
  GET_CURRENT_APPKEY : "/i/apps/getCurrentAppKeys",
  SET_APP_MODE : "/i/apps/setAppMode",
  SET_FCM_MODE : "i/apps/setFcmMode",
  UPLOAD_CERTIFICATE : "/i/apps/ioscertificate",
  CERTIFICATE : "/i/apps/certificate",
  UPDATE_GCM_KEYS : "/i/apps/updateGCMKeys",
  UPDATE_IOS_CERTIFICATES : "/i/apps/updateIOSCertificates",
  DOWNLOAD_ACTIVE_CAMPDATA :"/o/templates/downloadActiveCampaignData",
  DOWNLOAD_TRANSACTIONAL_CAMPDATA:"/o/templates/downloadTransactionalCampaignData",
  GET_CAMPAIGNS_BY_STATUS :"/o/templates/getCampaignsByStatus",
  START_EXPORT:"/i/startExports",
  STATS:"/o/funnels/stats",
  GETFUNNEL:"/i/funnels/getFunnel",
  CREATE_FUNNEL:"/i/funnels/create",
  FUNNEL_UPDATE:"/i/funnels/update",
  GET_FEATURES_SETTINGS_URL:"/o/apps/getFeaturesSettings",
  SAVE_FEATURES_SETTINGS_URL:"/i/apps/savefeaturesSettings",
  SAVE_CAMPAIGN_SETTINGS_URL:"/i/apps/saveCampaignSettings",
  ACTIVE_USER_SESSION_STATS_URL:"/o/metrices/getActiveUserSessionStats",
  GET_SESSION_DETAILS:"/o/metrices/getSessionDetails",
  GET_AVG_SESSION_DETAILS:"/o/metrices/getAvgSessionDetails",
  ACTIVE_USER_TIMELY_SESSIONS_URL:"/o/metrices/getActiveUserTimelySessions",
  GET_GEO_FENCING_URL:"o/aud/getGeoFencing",
  GET_META_VARIABLES_URL:"o/aud/get_metavariables",
  SAVE_GEO_FENCING_URL:"o/aud/saveGeoFencing",
  CREATE_CAMPAIGN_URL :"i/templates/createCampaign",
  UPDATE_CAMPAIGN_URL :"i/templates/updateCampaign",
  GET_USER_DETAILS_URL:"o/users/getUserDetails",
  GET_APP_USERS_URL:"/o/users/getAppUsers",
  LOGIN_URL:"i/login",
  GET_LOGIN_URL:"o/login",
  SIGNUP_URL:"i/signup",
  GET_SIGNUP_URL:"o/signup",
  GET_EMAIL_EXISTENCE:"/o/check-email-existence",
  AUTHENTICATION_URL:"/i/authentication",
  GET_AUTHENTICATION_URL:"/o/authentication",
  FORGET_URL:"i/forget",
  GET_FORGET_URL:"o/forget",
  RESET_URL:"i/reset",
  GET_RESET_URL:"o/reset",
  VALIDATE_FUNNEL_NAME:"/o/funnels/validateFunnelName",
  TRAIT_META_DATA_URL:"o/trait/getTraitMetaData",
  DOWNLOAD_EXPORT_CSV:"/o/downloadExportcsv",
  DELETE_CAMPAIGN_URL : "/i/templates/deleteCampaign",
  SET_ACTIVE_CAMPAIGN_URL : "/i/templates/setActiveCampaign",
  COPY_CAMPAIGN_URL : "/i/templates/copyCampaign",
UPDATE_DISPLAY_PREFERENCE:"/i/updateDisplayPreference",
GET_DISPLAY_PREEFERENCE:"/o/getDisplayPref",
UPDATE_CTRAIT_URL:"/i/trait/update",
 CREATE_CTRAIT_URL:"/i/trait/create",
 GET_TRAIT_BY_ID_URL:"o/trait/getTraitById",
 GET_TRAIT_TABLE_INFO_URL:"/o/trait/getTableInfo",
 GET_CAMPAIGN_STATUS_BY_ID:"/o/templates/getCampaignStatusById",
 GET_CAMPAIGN_BY_ID:"/o/templates/getCampaignById",
 DOWNLOAD_CAMPAIGN_BY_ID:"/o/templates/downloadCampaignDataById",
 CREATE_CAMPAIGN_TEMPLATE:"i/templates/create",
 UPDATE_CAMPAIGN_TEMPLATE:"i/templates/update",
 SAVE_CAMPAIGN_IMAGES:"/i/campaignFiles/campaignimages",
 SAVE_SITE_SETTING:"/i/apps/saveSiteSetting",
 DELETE_TEMPLATE:"/i/templates/deleteTemplate",
 DOWNLOAD_LOGS_URL : "/o/metrices/getCombinedLogFile",
 SEND_PUSH_URL : "i/push/test",
 UPDATE_FCM_TOKEN_URL : "/o/metrices/savePushToken",
 SEARCH_DID_URL : "/o/metrices/getRawUserData",
 SAVE_ALLOW_LIST_URL:"/i/apps/saveallowListSettings",
 REMOVE_CAMP_CONFIGURATOR:"/i/apps/removeCampConfigurator",
 SAVE_CAMP_CONFIGURATOR:"/i/apps/saveCampConfigurator",
 GET_PARTNER_INFO:"/o/apps/getPartnerInfo",
 VALIDATE_TEMPLATE_NAME:"/i/templates/validateTemplateName",
 GET_TEMPLATES_BY_TYPE:"o/templates/getTemplatesByType",
 GET_LOGS : "/o/apps/getLogs",
 SAVE_ENCRYPTION_STATUS:"/i/apps/saveEncryptionStatus",
 GET_ALL_USERS_URL :"/o/users/allUsers",
 DELETE_USER_ROLE_URL:"/o/users/deleteUsersRole",
 USER_APPROVE_URL:"/i/users/updateApproved",
 UPDATE_USER_ROLE_URL:"/o/users/updateRole",
 RESET_PASSWORD_URL:"/o/users/resetUserPassword",
 UNBLOCK_USER_URL:"/o/users/unblockUser",
 DELETE_USER_URL:"o/users/deleteUserpermenantly",
 GET_CUSTOMER_360_MAPPING:"/o/customer360/getCustomer360Mapping",
 UPDATE_CUSTOMER_360_MAPPING_ROW_BY_ID:"/i/customer360/updateCustomer360MappingRowById",
 DELETE_CUSTOMER_360_MAPPING_ROW_BY_ID:"/o/customer360/deleteCustomer360MappingRowById",
 GET_CUSTOM_VARIABLES:"o/aud/get_metacustomvariables",
 ADD_JOURNEY:"/i/journey/addJourney",
 ADD_ATTRIBUTE_TO_EVENT:"/i/journey/addAttributeToEvent",
 ADD_EVENT_TO_JOURNEY:"/i/journey/addEventToJourney",
 UPDATE_EVENT:"/i/journey/updateEvent",
 SAVE_APP_PROVIDERS:"/i/apps/saveAppProvider",
 VALIDATE_EMAIL_URL:"/o/users/validateEmail",
 ADD_USER_ROLE_URL : "/o/users/addUsersRole",
 UPDATE_COMMENT_URL:"/i/templates/updateComments",
 SET_DISPLAY:"/i/journey/setDisplay",
 GET_AUDIT_LOGS_URL: "/o/apps/getAuditLogs",
 GET_ALL_EVENTS_LIST:"/o/metrices/getEventList",
 DELETE_MAPPING_ROW_BY_ID: "/o/mapping/deleteMappingRowById",
 UPDATE_MAPPING_ROW_BY_ID:"/i/mapping/updateMappingRowById",
 GET_MAPPING_LIST:"/o/mapping/getMapping",
 GET_CAMPAIGN_STATS_DATEWISE:"/o/templates/getCampaignStatsDateWise",
 GET_EVENT_FUNNELS_URL:"/o/funnels/getEventFunnels",
 DELETE_FUNNEL_URL:"/i/funnels/delete",
 COPY_FUNNEL_URL:"/i/funnels/copy",
 UPDATE_USER_JOURNEY_URL:"/o/metrices/updateJourney",
 SAVE_USER_JOURNEY_URL:"/o/metrices/saveJourney",
 GET_USER_JOURNEY_URL:"/o/metrices/getJourney",
 GET_USER_JOURNEY_LIST_URL:"/o/metrices/getJourneyList",
 COPY_USER_JOURNEY_URL:"/o/metrices/copyJourney",
 DELETE_USER_JOURNEY_URL:"/o/metrices/deleteJourney",
 GET_USER_JOURNEY_EVENT_DATA_URL:"o/metrices/getJourneyEventData",
 GET_AUDIENCE_SEGMENTS:"/o/aud/getAudienceSegments",
 DELETE_AUDIENCE_SEGMENT:"/i/aud/delete",
 UPDATE_AUDIT_CONFIG:"/i/apps/updateAuditConfig",
 INSERT_ROW_IN_MAPPING:"/i/mapping/insertRowInMapping",
 GET_CAMPAIGNS_PERFORMANCE_REPORTS:"/o/templates/getCampaignsPerformanceReports",
 GET_CAMPAIGN_ATTRIBUTION_REPORTS:"/o/templates/getCampaignAttributionReports",
 SAVE_PARTNER_INFO:"/i/apps/savePartnerInfo",
 AUDIT_LOGS_EXPORT:"/o/auditLogsExport",
 DOWNLOAD_USER_MANAGEMENT_DATA:"/o/apps/downloadUserManagementData",
 GET_SMART_LINKS:"/o/metrices/getSmartLinks",
 GET_APP_GROUPS:"/o/metrices/getAppGroups",
 SAVE_SMART_LINK:"/i/metrices/saveSmartLink",
 GET_ALL_TRAITS_URL:"o/trait/getTraits",
 CREATE_FUNCTION_URL:"/i/function/create",
 UPDATE_FUNCTION_URL:"/i/function/update",
 DELETE_FUNCTION_URL:"/i/function/delete",
 GET_FUNCTIONS_URL:"/o/function/getFunctions",
 GET_FUNCTION_BY_ID_URL:"/o/function/getFunctionById",
 UPDATE_ATTRIBUTION_URL:"/i/apps/updateAttributionSettings",
 SAVE_APP_SETTING_URL:"/i/apps/saveAppSettings",
 SAVE_ACTIVATION_EMAIL_SETTINGS:"/i/apps/saveCampaignActivationEmailSettings",
 CREATE_NEW_APP_URL:"/i/apps/create",
 CHECK_EMAIL_DOMAIN_URL:"/i/apps/checkemaildomain",
 SEND_DEV_DATA_URL:"/i/apps/SendDevData",
 SETUP_DB_URL:"/i/apps/setupDb",
 UPDATE_APP_MANAGEMENT:"/i/apps/updateAppManagement",
 GET_INSTALL_UNINSTALL_STATS_BY_PARAM:"o/metrices/getInstallUnistallStatsByParam",
 GET_INSTALL_UNINSTALL_STATS_BY_SOURCE:"o/metrices/getInstallUnistallStatsBySource",
 GET_INSTALL_DATA:"o/metrices/getInstallData",
 QUERY_RESULT_URL:'/i/queryResult',
 UPDATE_CUSTOM_VARIABLES_URL:"/i/aud/updatemetacustomvariables",
 GET_ALL_USER_EVENTS:"/i/events/getAllUserEvents"
















};
export const ROUTES = {
  DASHBOARD: "/dashboard",
  DASHBOARD_APP: "/dashboard/app",
  DASHBOARD_ALLUSERS: "/dashboard/allusers",
  SIGNUP: "/signup",
  LOGIN: "/login",
  RESET: "/reset",
  FORGET: "/forget",
  LOGOUT: "/logout",
  AUTHENTICATION: "/authentication",
  DASHBOARD_CAMPAIGN: "/dashboard/campaign",
  DASHBOARD_CAMPAIGN_DETAIL: "/dashboard/campaign/:id",
  DASHBOARD_CAMPAIGN_CREATE: "/dashboard/campaign/create",
  DASHBOARD_APPUSER: "/dashboard/appuser",
  DASHBOARD_FUNNELS: "/dashboard/funnels",
  DASHBOARD_FUNNEL_DETAIL: "/dashboard/funnels/:id",
  DASHBOARD_FUNNELS_CREATE: "/dashboard/funnels/create",
  DASHBOARD_JOURNEYS: "/dashboard/journeys",
  DASHBOARD_JOURNEYS_CREATE: "/dashboard/journeys/create",
  DASHBOARD_AUDIENCE: "/dashboard/audience",
  DASHBOARD_AUDIENCE_CREATE: "/dashboard/audience/create",
  DASHBOARD_ACQUISITION: "/dashboard/acquisition",
  DASHBOARD_CHURN: "/dashboard/churn",
  DASHBOARD_DEMOGRAPHICS: "/dashboard/demographics",
  DASHBOARD_UTMBUILDER: "/dashboard/utmbuilder",
  DASHBOARD_CREATEAPP: "/dashboard/createapp",
  DASHBOARD_EDIT_PROFILE: "/dashboard/editProfile",
  DASHBOARD_DISPLAY_PREFRENCE: "/dashboard/displayPrefrence",
  DASHBOARD_TRANSACTIONALCAMPAIGN: "/dashboard/transactionalcampaign",
  DASHBOARD_TRANSACTIONALCAMPAIGN_DETAIL: "/dashboard/transactionalcampaigndetail/:id",
  DASHBOARD_APPSETTING: "/dashboard/appsetting",
  DASHBOARD_EXPORTS: "/dashboard/exports",
  DASHBOARD_EVENTS: "/dashboard/events",
  DASHBOARD_USAGE: "/dashboard/usage",
  DASHBOARD_JOURNEYS_VIEW: "/dashboard/journeys/:id",
  DASHBOARD_TRENDS_ALLTRENDS: "/dashboard/trends/alltrends",
  DASHBOARD_CAMPAIGN_REPORTS: "/dashboard/campaign/reports",
  DASHBOARD_AUDIENCE_ALLTRAITS: "/dashboard/audience/alltraits",
  DASHBOARD_AUDIENCE_TRAIT_CREATE: "/dashboard/audience/trait/create",
  DASHBOARD_Attribute_REPORTS: "/dashboard/campaign/attributereports",
  DASHBOARD_CAMPAIGN_TEMPLATE: "/dashboard/campaign/template",
  DASHBOARD_APPUSER_DETAIL: "/dashboard/appuser/:id",
  DASHBOARD_APPUSERS_DETAILS: "/dashboard/appuserdetails/:id",
  DASHBOARD_DATA_TRANSFORMATION:"/dashboard/datatransformation",
  DASHBOARD_JOURNEY_MANAGEMENT:"/dashboard/journeymanagement",
  DASHBOARD_AUDIT_LOGS:"/dashboard/auditlogs",
  DASHBOARD_CUSTOMER: "/dashboard/customer",
  DASHBOARD_CUSTOMER_DETAIL: "/dashboard/customer/:id",
  DASHBOARD_MANAGEMENT_PERSONALISATION:"/dashboard/management/personalisation",
  DASHBOARD_AUDIENCE_FUNCTION_CREATE: "/dashboard/audience/function/create",
  DASHBOARD_APPMANAGEMENT:"/dashboard/appmanagement",

  DASHBOARD_LINKS:"/dashboard/links",
  DASHBOARD_SMART_LINK_CREATE:"/dashboard/links/smartlinks/create",
  DASHBOARD_SMART_LINK_DETAILS:"/dashboard/links/smartlinks/details",
  DASHBOARD_CAMPAIGN_TEMPLATE_VIEW:"/dashboard/campaign/template/:id",


  
  NOT_FOUND: "*",
  DASHBOARD_LOYALTY: "/dashboard/loyalty",
  DASHBOARD_LOYALTY_MEMBERS: "/dashboard/loyalty/members",
  DASHBOARD_LOYALTY_SEGMENTS: "/dashboard/loyalty/segments",
  DASHBOARD_LOYALTY_TIER: "/dashboard/loyalty/tier",
  DASHBOARD_LOYALTY_TIER_CREATE: "/dashboard/loyalty/tier/create",
  DASHBOARD_LOYALTY_C360: "/dashboard/loyalty/c360",
  DASHBOARD_AUDIENCE_SYNC: "/dashboard/audience/sync",
  DASHBOARD_LOYALTY_REWARDS: "/dashboard/loyalty/rewards",
  DASHBOARD_LOYALTY_REWARDS_CREATE: "/dashboard/loyalty/rewards/create",
  DASHBOARD_PUSH_GENAI: "/dashboard/push/genai",
  GENAI_DASHBOARD: "/genai/dashboard",
  DASHBOARD_AUDIENCE_MODELS: "/dashboard/audience/models",
  DASHBOARD_USER_LIST : "/dashboard/diagnostic",
  DASHBOARD_CAMPAIGN_REVIEW:"/dashboard/campaign/review/:id",
  DASHBOARD_AUDIENCE_FUNCTIONS:"/dashboard/audience/functions",
};
export const METHODS={
  GET_META_VARIABLES:"get_metvariables",
  SAVE_GEO_FENCING:"saveGeoFencing",
  GET_GEO_FENCING:"getGeoFencing"

}




