import deleteIcon from "images/trashpink.svg";
import { useDispatch, useSelector } from "react-redux";
import { t } from "i18next";
import CustomTable from "components/CustomTable/CustomTable";
import PrimaryButton from "components/Buttons/PrimaryButton/PrimaryButton";
import "./DataTransformation.css";
import React, { useEffect, useRef, useState } from "react";
import useMessage from "hooks/useMessage";
import centralApi from "services/centralApi";
import TextInput from "components/Inputs/TextInput/TextInput";
import { Space } from "antd";
import ConfirmPopUp from "components/PopUp/ConfirmPopUp/ConfirmPopUp";
import { API_ENDPOINTS } from "utils/constants";
import Spinner from "components/Spinner/Spinner";
import { removeUnderscoreAddSpace } from "helperFunctions/common";
import { getToken } from "redux/features/app/authTokenSlice";
import Selector from "components/Selector/Selector";
import { STRING_BOOLEAN_OPTIONS } from "utils/constants/selectorOptions";
import { Form } from "antd";
import AddRow from "pages/DataManagement/components/AddRow";
import documentIcon from "../../images/document.png"
import editIcon from "../../images/_edit.svg"
import updateIcon from "../../images/updateIcon.svg"

const DataTransformation: React.FC = () => {
  const { showError, showSuccess } = useMessage();
  const dispatch: any = useDispatch();
  const { appId } = useSelector((state: any) => state.activeApp);
  const loginUser = useSelector((state: any) => state.loginUser.data);
  const authToken = useSelector((state: any) => state.authToken.token);
  const [form] = Form.useForm();
  const formRefs = useRef<{ [key: string]: any }>({});
  const [addRow, setAddRow] = useState({
    open: false,
  });
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const tableName = `customer360mapping_${appId}`; // Correct template literal
  const [rowData, setRowData] = useState([]);
  const [columnData, setColumnData] = useState([]);

  const [column, setColumns] = useState<any[]>([]);
  const [editRowId, setEditRowId] = useState<number | null>(null); // New state to track which row is being edited

  async function getMappingList(payload: {
    app_id: string;
    api_key: string;
    tableName: string;
  }) {
    try {
      setLoading(true);
      const res = await centralApi(
        "GET",
        API_ENDPOINTS.GET_MAPPING_LIST,
        null,
        payload
      );
      setTableData(res.result);
      setColumnData(res.columnTypes)
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (loginUser?.api_key) {
      getMappingList({
        app_id: appId,
        api_key: loginUser.api_key,
        tableName,
      });
    }
  }, [loginUser?.api_key]);

  async function updateMappingRowById(formData: any) {
    const payload = {
      args: JSON.stringify(formData),
      tableName, 
      authToken,
      app_id: appId,
      api_key: loginUser?.api_key,
      key: "id",
    };

    try {
      setLoading(true);
      await centralApi(
        "POST",
        API_ENDPOINTS.UPDATE_MAPPING_ROW_BY_ID,
        payload,
        null
      );
      showSuccess(t("sucessfullyUpdatedLabel"));
      dispatch(getToken());
      setLoading(false);
      setEditRowId(null); // Reset edit mode after successful update
      getMappingList({
        app_id: appId,
        api_key: loginUser.api_key,
        tableName,
      });
    } catch (error) {
      setLoading(false);
      dispatch(getToken());
      showError(t("somethingWrongLabel"));
    }
  }
  const handleRowUpdate = async (formData: any, rowIndex: number) => {
    const updatedRow = formData[rowIndex];
    const parsedRow = Object.fromEntries(
      Object.entries(updatedRow).map(([key, value]) => [
        key,
        value === STRING_BOOLEAN_OPTIONS.TRUE ||
        value === STRING_BOOLEAN_OPTIONS.FALSE
          ? JSON.parse(value)
          : value,
      ])
    );

    const updatedData = { id: rowIndex, ...parsedRow };
    updateMappingRowById(updatedData);
     };

  
//Creates a render function for a TextInput component that can be used in a table.



  const getColumnTitle = (key: string) => {
    const specialTitles: Record<string, string> = {
      destination_col_key: "Destination column root key",
    };

    return specialTitles[key] || removeUnderscoreAddSpace(key);
  };
  const generateColumns = (columnData: any[]) => {
    if (!columnData || columnData.length === 0) return [];
  
    console.log("column data", columnData);
  
    // Column Styles (Optional: You can still use this for custom widths if needed)
    const columnStyles: Record<string, { width: number }> = {
      source_table: { width: 130 },
      source_col: { width: 320 },
      file_table: { width: 130 },
      source_fields: { width: 320 },
      destination_col: { width: 140 },
      display_name: { width: 400 },
      destination_col_child_key: { width: 100 },
      identity_col: { width: 130 }, // For boolean fields
      sensitive_col: { width: 130 }, // For boolean fields
      is_mapped: { width: 130 }, // For boolean fields
      is_visible: { width: 130 }, // For boolean fields
    };
  
    return columnData.map((column) => ({
      title: column.title, // Use `column.title` for title
      dataIndex: column.key, // Use column's name as `dataIndex`
      key: column.key, // Use column's name as the key
      width: columnStyles[column.key]?.width || 130, // Custom column width if defined
      render: (_: any, record: any, index: number) => {
        // Only show editable input when the row is being edited
        if (editRowId === record.id) {
          return (
            <Form form={form} ref={(el) => (formRefs.current[record.id] = el)}>
              <Form.Item
                name={[record.id, column.key]} // Use column.key here for consistency
                initialValue={
                  typeof record[column.key] === "boolean"
                    ? String(record[column.key]) // Convert boolean to string for input
                    : record[column.key]
                }
                style={{ marginBottom: 0 }}
              >
                {column.type === "boolean" ? (
                  <Selector
                    value={String(record[column.key]) ?? undefined}
                    style={{ fontSize: 12 }}
                    options={[
                      {
                        label: t("TrueLabel"),
                        value: STRING_BOOLEAN_OPTIONS.TRUE,
                      },
                      {
                        label: t("FalseLabel"),
                        value: STRING_BOOLEAN_OPTIONS.FALSE,
                      },
                    ]}
                    placeholder={t("Select an option")}
                  />
                ) : (
                  <TextInput
                    style={{ fontSize: 12 }}
                    defaultValue={record[column.key]} // Use column.key here too
                  />
                )}
              </Form.Item>
            </Form>
          );
        }
  
       // If not editing, show the value only if it exists
       const valueToRender =
       column.type === "boolean"
         ? record[column.key] === undefined || record[column.key] === null
           ? "" // Don't render anything if the value is null or undefined
           : record[column.key]
           ? t("TrueLabel") // Translate "True" to your localized string
           : t("FalseLabel") // Translate "False" to your localized string
         : record[column.key];

     return valueToRender ? <span>{valueToRender}</span> : null; // Render value or nothing
},
    }));
  };
  
  
  
  
  const actionColumn = {
    title: t("actionLabel"),
    key: "action",
    width: 200,
    render: (_: any, record: any) => (
      <Space>
        <ConfirmPopUp
          title={t("deleteLabel")}
          onConfirm={() => deleteRowById(record.id)}
          description={t("areYouSureMsg")}
          placement="left"
          id={record._id}
          icon={deleteIcon}
        >
          <PrimaryButton className="action-btn mx-1">
            <img src={deleteIcon} alt={"close"} style={{ width: 18 }} />
          </PrimaryButton>
        </ConfirmPopUp>
        {editRowId === record.id ? (
          <ConfirmPopUp
            title={t("updateLabel")}
            onConfirm={async () => {
              try {
                const updatedValues = await formRefs.current[
                  record.id
                ].validateFields();
                handleRowUpdate(updatedValues, record.id);
              } catch (error) {
                showError(t("somethingWrongLabel"));
              }
            }}
            description={t("areYouSureMsg")}
            placement="left"
            id={record._id}
            icon={editIcon}
          >
            <PrimaryButton className="action-btn mx-1">

            <img
                  src={updateIcon}
                  alt={"update"}
                  style={{ width: 16 }}
                ></img>                 </PrimaryButton>
          </ConfirmPopUp>
        ) : (
          <PrimaryButton
            className="action-btn mx-1"
            onClick={() => setEditRowId(record.id)} // Set the row as editable when clicked
          >

<img
                  src={editIcon}
                  alt={"edit"}
                  style={{ width: 16 }}
                ></img>      
                    </PrimaryButton>
        )}
        <PrimaryButton
        className="action-btn mx-1"
        onClick={() => handleCopyRow(record)} // Trigger copy row function
      >
 <img
                  src={documentIcon}
                  alt={"document_icon"}
                  style={{ width: 16 }}
                ></img>
                   </PrimaryButton>
      </Space>
    ),
  };

  const dynamicColumns =
    Array.isArray(column) && column.length > 0
      ? generateColumns(column)
      : [];
  const columns = [...dynamicColumns, actionColumn];

  async function deleteRowById(id: number) {
    const payload = {
      args: JSON.stringify({
        value: id,
        key: "id",
        tableName,
      }),
      app_id: appId,
      api_key: loginUser?.api_key,
    };

    try {
      setLoading(true);
      await centralApi(
        "GET",
        API_ENDPOINTS.DELETE_MAPPING_ROW_BY_ID,
        null,
        payload
      );
      showSuccess(t("sucessfullyDeletedLabel"));
      setLoading(false);

      // Refresh the data after deletion
      getMappingList({
        app_id: appId,
        api_key: loginUser.api_key,
        tableName,
      });
    } catch (error) {
      setLoading(false);
    }
  }
  const handleCopyRow = (record: any) => {
    // Copy the values of the record (row) into the rowData state
    setRowData({
      ...record,  // The record values to populate the form with
    });
  
    // Open the Add Row drawer and allow user to edit the copied data
    setAddRow({
      open: true,
    });
  };
  
  useEffect(() => {
    if (columnData?.length > 0) {
     
      const columnNames = columnData
        .filter((column:any) => column.column !== "id") // Exclude 'id' column if needed
        .map((column) => ({
          title: getColumnTitle(column?.column),  // You can use any logic here for titles, e.g., make it more user-friendly
          key: column?.column,    // Column name for the key
          type:column?.type
        }));

      // Set the columns state with the new columnNames array
      setColumns(columnNames);
    }
     
  }, [columnData]);
  const handleAddRow = async (values) => {
  
    // Prepare the payload for inserting the new row
    const payload = {
      args: JSON.stringify({
        ...values,  // The form values to insert
      }),
      tableName: tableName,  // The table name where the row will be inserted

      app_id: appId,  // Application ID
      api_key: loginUser?.api_key,  // API key for authentication
    };
  
    try {
      setLoading(true);  // Show loading spinner while the request is being made
  
      // Make the API call to insert the new row (use POST instead of GET)
      await centralApi(
        "POST",  // Use POST method to insert a row
        API_ENDPOINTS.INSERT_ROW_IN_MAPPING,  // The API endpoint for row insertion
        null,
        payload  // Send the payload containing form values and other data
      );
  
      // Show success message after the row is inserted
      showSuccess(t("rowInsertedLabel"));
  
      // Close the "Add Row" drawer/modal after insertion
      setAddRow({
        open: false,  // Close the drawer
      });
  
      // Refresh the data to reflect the newly inserted row
      getMappingList({
        app_id: appId,
        api_key: loginUser.api_key,
        tableName,
      });
  
      setLoading(false);  // Reset loading state after the request is complete
    } catch (error) {
      // Handle any errors that occur during the insertion
      setLoading(false);  // Reset loading state
      console.error("Error inserting row:", error);
      showError(t("somethingWentWrongLabel"));  // Show an error message to the user
    }
  };
  
  
  
  return (
    <div>
      <div className="d-flex justify-content-between flex-wrap my-3">
        <h4 className="fw-semi-bold">{t("c360dataMappingLabel")}</h4>
        <div className="d-flex gap-2">
          <PrimaryButton
            onClick={() => {
              setAddRow({
                open: true,
              });
            }}
            type="primary"
          >
            {t("addRowLabel")}
          </PrimaryButton>
         </div>
      </div>
      <div className="data-transformation-container">
        <div
          className={`${
            tableData?.length === 0 ? "data-transformation-container-table" : ""
          } data-transformation-body p-3 mt-3`}
        >
          {dynamicColumns.length === 0 ? (
            <Spinner />
          ) : (
            <CustomTable
              dataSource={tableData}
              columns={columns}
              loading={loading}
              rowKey="id"
              size="small"
              scroll={{ x: 2700, y: 400 }}
            />
          )}
          <AddRow
  addRowState={addRow}
  setFormData={setRowData}
  setAddRowState={setAddRow}
  columns={column}
  onSave={handleAddRow}  // Passing onSave function to AddRow
  initialValues={rowData}  // Pass the copied row data here
/>

               
        </div>
      </div>
    </div>
  );
};

export default DataTransformation;
