import pushNotificationIcon from "images/Push_new.svg"
import inAppNotificationIcon from "images/InApp_new.svg"
import inAppSurveyIcon from "images/InApp_new.svg"
import webBellIcon from "images/Web_Push_new.svg"
import inAppIcon from "images/Rating_new.svg"
import emailIcon from "images/Email_new.svg"
import webPopupIcon from "images/Web_Popup_new.svg"
import messageIcon from "images/SMS_new.svg"
import googleIcon from "images/Google.svg"
import facebookIcon from "images/Facebook.svg"
import whatsappIcon from "images/WhatsApp_new.svg"
import landingPageIcon from "images/Landing_Pg.svg"
import nativeDisplayIcon from "images/NativeDisplay.svg"
import rcsIcon from "images/RCS.svg"
import nudgeIcon from "images/Nudge.svg"


import "./EngagementToolHome.css"
import React, { ReactElement, useEffect, useState } from "react"
import MobilePushNotification from "../MobilePushNotification/MobilePushNotification"
import { useLocation } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { setCampaignData } from "../../../../../../redux/features/campaign/campaignDataSlice"
import SMS from "../SMS/SMS"
import InAppNotification from "../InAppNotification/InAppNotification"
import WebPush from "../WebPush/WebPush"
import Email from "../Email/Email"
import Facebook from "../Facebook/Facebook"
import WhatsApp from "../WhatsApp/WhatsApp"
import Google from "../Google/Google"
import WebPopUp from "../WebPopUp/WebPopUp"
import NativeDisplay from "../NativeDisplay/NativeDisplay"
import { CampaignChannelTypeEnum, ToolIdEnum } from "utils/enums"
import { t } from "i18next"
import Nudge from "../Nudge/Nudge"
import { BOOLEAN_OPTIONS } from "utils/constants/selectorOptions"
import MultiStep from "../MultiStep/MultiStep"
import multiStepIcon from "images/MultiStep_Campaign.svg"

type toolType = {
  id: ToolIdEnum
    // id: toolIdType

  label: string
  icon: string
  component: ReactElement
}
function Tool({ label, icon, onClick }: any) {
  return (
    <div
      onClick={onClick}
      className="d-flex flex-column engagement-tool-comp align-items-center"
    >
      <img
        className="engagement-tool-comp-icon"
        height={120}
        width={120}
        src={icon}
      ></img>
      <h6>{label}</h6>
    </div>
  )
}
function EngagementToolHome() {
  const dispatch: any = useDispatch()
  const features = useSelector((state: any) => state.featuresSetting.data);
  const channelStatus = features[0]?.channel_status;
  const campaignData = useSelector((state: any) => state.campaignData.data)
  const [selectedTool, setSelectedTool] = useState<ToolIdEnum>(
    campaignData.t || ""
  )
  const { state } = useLocation()
  useEffect(() => {
    if (state?.cid && state?.type) {
      setSelectedTool(state.type)
    }
  }, [state])
  useEffect(() => {
    setSelectedTool(campaignData.t)
  }, [campaignData?.t])
  const toolData: toolType[] = [
    {
      id: ToolIdEnum.PUSH,

      label: t("pushLabel"),
      icon: pushNotificationIcon,
      component: <MobilePushNotification />,
    },
    {
      id: ToolIdEnum.IN_APP,

      label: t("inAppCapitalLabel"),
      icon: inAppNotificationIcon,
      component: <InAppNotification />,
    },
    {
      id: ToolIdEnum.SMS,
      
      label: t("smsLabel"),
      icon: messageIcon,
      component: <SMS />,
    },
    {
      id: ToolIdEnum.EMAIL,

      label: t("emailLabel"),
      icon: emailIcon,
      component: <Email type={CampaignChannelTypeEnum.EMAIL} />,
    },
    {
      id: ToolIdEnum.WEB_PUSH,

      label: t("webPushLabel"),
      icon: webBellIcon,
      component: <WebPush />,
    },
    {
      id: ToolIdEnum.WEB_POPUP,

      label: t("webPopupLabel"),
      icon: webPopupIcon,
      component: <WebPopUp />,
    },
    {
      id: ToolIdEnum.WHATSAPP,

      label: t("whatsAppLabel"),
      icon: whatsappIcon,
      component: <WhatsApp type={CampaignChannelTypeEnum.WHATSAPP}/>,
    },
    {
      id: ToolIdEnum.IN_APP_RATING,

      label: t("inAppRatingLabel"),
      icon: inAppIcon,
      component: <></>,
    },
    {
      id: ToolIdEnum.IN_APP_SURVEY,

      label: t("inAppSurveyLabel"),
      icon: inAppSurveyIcon,
      component: <></>,
    },
    {
      id: ToolIdEnum.LANDING_PAGE,

      label: t("landingPageLabel"),
      icon: landingPageIcon,
      component: <Email type={CampaignChannelTypeEnum.LANDING_PAGE} />,
    },
    {
      id: ToolIdEnum.FACEBOOK,

      label: t("facebookLabel"),
      icon: facebookIcon,
      component: <Facebook />,
    },
    {
      id: ToolIdEnum.GOOGLE,

      label: t("googleLabel"),
      icon: googleIcon,
      component: <Google />,
    },
    {
      id: ToolIdEnum.NATIVE,

      label: t("nativeDisplayLabel"),
      icon: nativeDisplayIcon,
      component: <NativeDisplay />,
    },
    {
      id: ToolIdEnum.RCS,

      label: t("rcsLabel"),
      icon: rcsIcon,
      component: <WhatsApp type={CampaignChannelTypeEnum.RCS}/>,
    }
    ,
    {
      id: ToolIdEnum.NUDGE,
      label: t("nudgeLabel"),
      icon: nudgeIcon,
      component: <Nudge/>,
    },
    {
      id: ToolIdEnum.MULTISTEP,

      label: t("multiStepCampaignlLabel"),
      icon: multiStepIcon,
      component: <MultiStep/>,
    },
  ];
  
  const filteredToolData = toolData.filter(tool => {
    // Check if channelStatus is undefined. If undefined, return all tools
    if (channelStatus === undefined) {
      return BOOLEAN_OPTIONS.TRUE;  // Return all tools
    }
  
    // If channelStatus is defined, check if the status for the tool is true
    return channelStatus[tool.id] === BOOLEAN_OPTIONS.TRUE;
  });

  return (
    <div className="campaign-engagement-tool-content  p-2 ">
      {selectedTool
        ? toolData.find(({ id }) => id == selectedTool)?.component
        : filteredToolData.map((tool: toolType) => {
            return (
              <Tool
                key={tool.label}
                {...tool}
                onClick={() => {
                  setSelectedTool(tool.id)
                  dispatch(setCampaignData({ ...campaignData, t: tool.id }))
                }}
              />
            )
          })}
    </div>
  )
}

export default React.memo(EngagementToolHome)
