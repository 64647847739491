import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import centralApi from "../../../services/centralApi";
import { STATUS } from "../../constant";
import { E_OperatorEnum } from "utils/enums";
import { API_ENDPOINTS } from "utils/constants";
import { formatPgSqlOperatorAndValues } from "utils/operators";
import { FilterInfo, QueryPayload } from "utils/models";
// Initial value for a trait condition
export const traitConditionIntialValue: FilterInfo = {
  func: undefined,
  compute: false,
  operand: "",
  operator: "",
  value: [],
  type: "",
  e_operator: E_OperatorEnum.AND,
};
/**
 * formatComputedTraitFilterInfo  function that formats the filterInfo
 * by modifying operators and values. It processes both top-level 
 * filter conditions and any nested group conditions
 * @param filterInfo - An array of FilterInfo objects representing filter conditions.
 * @returns An array of modified FilterInfo objects where operators and values are formatted.
 */
export const formatComputedTraitFilterInfo=(filterInfo:FilterInfo[])=>{
 // Map through the filterInfo array  to modify each condition
 const modifiedfilterInfo = filterInfo?.map(
  (condition: FilterInfo) => {
    let modifiedCondition = { ...condition }; // Copy the original condition

    if (condition.operator && condition.value) {
      // Format operator and values for filter condition
      const modifiedValues = formatPgSqlOperatorAndValues(
        condition.operator,
        condition.value
      );
      // Merge the modified values into the copied condition
      modifiedCondition = { ...modifiedCondition, ...modifiedValues };
    }

    // If the filter condition has a  group (nested condition), handle it
    if (condition.group && condition.group.length) {
      // Map through each condition within the group
      modifiedCondition.group = condition.group.map(
        (groupCondition: FilterInfo) => {
          let modifiedGroupCondition = { ...groupCondition }; // Copy the group condition

          if (groupCondition.operator && groupCondition.value) {
            // Format operator and value for each group condition
            const modifiedGroupValues = formatPgSqlOperatorAndValues(
              groupCondition.operator,
              groupCondition.value
            );
            // Merge the formatted values into the group condition copy
            modifiedGroupCondition = {
              ...modifiedGroupCondition,
              ...modifiedGroupValues,
            };
          }
          // Return the modified group condition
          return modifiedGroupCondition;
        }
      );
    }
    // Return the modified top-level condition
    return modifiedCondition;
  }
);
return modifiedfilterInfo
}

/**
 * This function modifies the filter conditions in the payload.
 * If the condition has an operand and a group, it prepares the data for sending to the API by
 * nesting the parent condition inside the group at first, without precedence and e_operator.
 * If the condition only has a group, it processes the data received from the API
 * and reconstructs the original condition by pulling the first condition out of the group 
 * and restoring precedence and e_operator.
 * if the group is not thr, it returns the condition as it is.
 *
 * @param {FilterInfo} filterInfo - The payload containing filter conditions.
 * @returns {FilterInfo[]} The modified filter data.
 */
export const restructureFilterConditions = (filterInfo: FilterInfo[]):FilterInfo[] => {
  const modifiedFilterData:FilterInfo[] = filterInfo.map((condition) => {
    
    // If condition has an operand and a non-empty group, prepare the data for sending to the API
    if (condition.operand && condition.group?.length) {
      const { e_operator, precedence, group, ...updatedCondition } = condition;           // Remove group from copied condition

      // Return new structure with the current condition as part of the group for sending to the API
      return {
          precedence,
          e_operator,
          group: [updatedCondition, ...group],
        }
    
    // If only group is present, process data coming from the API
    } else if (condition.group?.length) {
      const [firstCondition, ...group] = condition.group;  // Extract the first condition and the rest of the group

      // Reconstruct the original condition with precedence and operator received from the API
      return {
        ...firstCondition,
        precedence: condition.precedence,
        e_operator: condition.e_operator,
        group: group.length ? group : undefined,  // Only include group if there are remaining elements
      };
    
    // If no group or operand, return the condition as-is
    } else {
      const {group,...updatedCondition}=condition
      return updatedCondition;
    }
  });

  // Return the modified filter data
  return modifiedFilterData;
};
// Initial state of the queryPayload slice
const initialState: { data: QueryPayload; status: any } = {
  data: {
    name: "",
    tableInfo: { tableName: "" },
    projectionInfo: [],
    filterInfo: [],
    days: undefined,
    audid: "",
  },
  status: STATUS.IDLE,
};
// **
//  * Automatically updates the e_operator (AND/OR) for both parent and nested conditions 
//  * in the filterInfo array based on the changes detected in the provided changedFields.
//  * 
//  * If the parent e_operator is AND, all nested conditions will be changed to OR. 
//  * Conversely, if any nested condition has an OR, it will change the parent to AND.
//  *
const changeOperatorAutomatically=(changedFields:any,filterInfo:FilterInfo[],index:number | undefined=undefined,parentIndex:number | undefined=undefined)=>{
  // Create a copy of the filterInfo array to ensure immutability
  let modifiedFilterInfo=[...filterInfo]
  // Check if the e_operator has been changed by user  and if there are any conditions to modify
  if(changedFields.e_operator &&  filterInfo?.length ){
    // Determine the opposite logical operator (if AND, change to OR; if OR, change to AND)
    const opposite_Eoperator=changedFields.e_operator==E_OperatorEnum.AND?E_OperatorEnum.OR:E_OperatorEnum.AND
    // Check if the change is being made at the parent/root level
    const isParent=(index !== undefined && parentIndex == -1)
    // Set the e_operator for parent and group
    const parent_Eoperator=isParent?changedFields.e_operator:opposite_Eoperator
    const group_Eoperator=isParent?opposite_Eoperator:changedFields.e_operator
    // Map through the filterInfo array to update the e_operator for each condition
    modifiedFilterInfo=filterInfo.map((condition,mapIndex)=>{
        let modifiedCondition={...condition,e_operator:mapIndex?parent_Eoperator:undefined}
        let modifiedConditionGroup=modifiedCondition.group?.map((groupCondition:FilterInfo)=>{
        return {...groupCondition,e_operator:group_Eoperator}
        })
        modifiedCondition={...modifiedCondition,group:modifiedConditionGroup}
        return modifiedCondition
      })
    }
    // Return the updated filterInfo array
    return modifiedFilterInfo
}
// Async thunk to fetch computed trait by ID
export const fetchComputedTraitById = createAsyncThunk(
  "queryPayload/fetchComputedTraitById",
  async ({ api_key, app_id, id }: any) => {
    const params = {
      api_key,
      app_id,
      id,
    };
    // Call to API to get the trait data by ID
    return centralApi(
      "GET",
      API_ENDPOINTS.GET_TRAIT_BY_ID_URL,
      null,
      params
    ).then((data) => {
      return data;
    });
  }
);

// Async thunk to funnel by ID
export const fetchFunnelById = createAsyncThunk(
  "queryPayload/fetchFunnelById",
  async ({ api_key, app_id, id }: any) => {
    const params = {
      api_key,
      app_id,
      id,
    };
    // Call to API to get the trait data by ID
    return centralApi(
      "GET",
      API_ENDPOINTS.GETFUNNEL,
      null,
      params
    ).then((data) => {
      return data;
    });
  }
);

// Slice for managing computed trait state
const queryPayloadSlice = createSlice({
  name: "queryPayload",
  initialState,
  reducers: {
    // Action to set computed trait data
    setQueryPayloadData(state, action) {
      state.data = { ...state.data, ...action.payload };
    },
     //Action to modify data in filter condition
    setFilterInfoData(state, action) {
      // Create a copy of the filterInfo array to modify it.
      let modifiedFilterInfo = [...(state.data.filterInfo || [])];
      // Destructure the payload to get indices and updated data
      const { parentIndex, index, data } = action.payload;
      
      if (index !== undefined && parentIndex == -1) {
        // If -1 that means thr is no parent , we're updating the root level not nested/group data
        modifiedFilterInfo[index] = data;
        modifiedFilterInfo=changeOperatorAutomatically(data,modifiedFilterInfo,index,parentIndex)
        state.data = { ...state.data, filterInfo: modifiedFilterInfo };
      } else if (
        index !== undefined &&
        parentIndex !== -1 &&
        parentIndex !== undefined
      ) {
        // If parentIndex is provided, we're updating a  group
        const parentItem = { ...modifiedFilterInfo[parentIndex] }; // Copy parent item to ensure immutability
        parentItem.group = [...(parentItem.group || [])]; // Ensure group is an array and copy it

        // Update the group at the specified index with the new data
        parentItem.group[index] = data;

        // Update the modifiedFilterInfo with the updated parent item
        modifiedFilterInfo[parentIndex] = parentItem;
        modifiedFilterInfo=changeOperatorAutomatically(data,modifiedFilterInfo,index,parentIndex)
        state.data = { ...state.data, filterInfo: modifiedFilterInfo };
      }
    },
    // Action to remove a condition from the filterInfo array
    // This handles removal either at the root level or within a nested group
    removeFilterCondition(state, action) {
      const index = action.payload.index; // Index of the condition to remove
      const parentIndex = action.payload.parentIndex; // Index of the parent  (if any)
      const modifiedState = [...(state.data.filterInfo || [])]; // Create a copy of filterInfo array for immutability
      if (parentIndex == undefined) {
        // If there's no parentIndex, remove the condition from the root-level filterInfo array
        modifiedState.splice(index, 1); // Remove the condition at the specified index
      } else {
        // If there's a parentIndex, it means the condition is ins  ide a  group
        // Create a shallow copy of the group to modify it immutably
        const modifiedGroup = [...(modifiedState[parentIndex].group || [])];
        modifiedGroup.splice(index, 1); // Remove the condition from the group at the specified index

        // Update the state with the modified group
        modifiedState[parentIndex] = {
          ...modifiedState[parentIndex], // Ensure the parent object is copied before modifying
          group: modifiedGroup, // Assign the updated group back to the parent
        };
      }
      // Update the state with the new filterInfo structure after the removal
      state.data = { ...state.data, filterInfo: modifiedState };
    },
    // Action to add a new filter condition to the filterInfo
    addFilterCondition(state, action) {
      const parentIndex = action.payload.parentIndex; // Index of the parent group, if any
      let modifiedFilterInfo = [...(state.data.filterInfo || [])]; // Copy of the existing filterInfo array to maintain immutability
      // Determine the precedence (order) of the new condition.
      const precedence =
        parentIndex !== undefined
          ? undefined
          : modifiedFilterInfo.length + 1;
      // Create the initial condition with the computed precedence.
      let modifiedIntialCondition = {
        ...traitConditionIntialValue,
        precedence: precedence,
      };
      // Handle special cases where the  e_operator should not be set.
      // Don't set e_operator if it's the first condition being added in parent
      if (state.data.filterInfo?.length == 0) {
        modifiedIntialCondition = {
          ...modifiedIntialCondition,
          e_operator: undefined,
        };
      }
      // If there's no parent condition (root-level addition), update the filterInfo array directly.
      if (parentIndex === undefined) {
         // Determine the e_operator based on the existing conditions:
        // 1. If modifiedInitialCondition has an e_operator, check the first group's first condition's e_operator.
        // 2. If not found, check the second parent condition's e_operator.
        // 3. If the operator is AND, switch it to OR, otherwise set it to AND.
        let e_operator=modifiedIntialCondition.e_operator?modifiedFilterInfo?.[0]?.group?.[0]?.e_operator==E_OperatorEnum.AND || modifiedFilterInfo?.[1]?.e_operator ==E_OperatorEnum.OR?E_OperatorEnum.OR:E_OperatorEnum.AND:undefined
        state.data = {
          ...state.data,
          filterInfo: changeOperatorAutomatically({...modifiedIntialCondition,e_operator},[...modifiedFilterInfo, modifiedIntialCondition],-1,-1),
        };
      } else {
       // Determine the e_operator:
        // If the parentIndex condition's e_operator or the next condition's e_operator is AND, set it to OR.
        // Otherwise, set it to AND.
        const e_operator=modifiedFilterInfo?.[parentIndex]?.e_operator==E_OperatorEnum.AND || modifiedFilterInfo?.[parentIndex+1]?.e_operator==E_OperatorEnum.AND ?E_OperatorEnum.OR:E_OperatorEnum.AND
        modifiedIntialCondition={...modifiedIntialCondition,e_operator}
        // If adding the condition to a specific group (nested condition):
        modifiedFilterInfo[parentIndex] = {
          ...modifiedFilterInfo[parentIndex],
          group: [
            ...(modifiedFilterInfo[parentIndex].group || []),
            modifiedIntialCondition, // Add the new condition to the group
          ],
        };
        modifiedFilterInfo=changeOperatorAutomatically(modifiedIntialCondition,modifiedFilterInfo)
        // Update the state with the modified filterInfo
        state.data = { ...state.data, filterInfo: modifiedFilterInfo };
      }
    },
    // Action to reset computed trait data to initial state
    resetQueryPayload(state) {
      state.data = initialState.data;
    },
  },
  extraReducers: (builder) => {
    // Handle pending state of fetchComputedTraitById
    builder.addCase(fetchComputedTraitById.pending, (state) => {
      state.status = STATUS.LOADING;
    });
    // Handle fulfilled state of fetchComputedTraitById
    builder.addCase(fetchComputedTraitById.fulfilled, (state, action) => {
      let filterInfo = formatComputedTraitFilterInfo(action.payload.filterInfo)
       filterInfo=restructureFilterConditions(filterInfo)
      // Set the status to 'idle' indicating that the fetch action is completed
      state.status = STATUS.IDLE;

      // Update the state with the received data, but include the modified filterInfo
      state.data = { ...action.payload, filterInfo };
    });

    // Handle rejected state of fetchComputedTraitById
    builder.addCase(fetchComputedTraitById.rejected, (state) => {
      state.status = STATUS.ERROR;
    });

    // Handle pending state of fetchFunnelById
    builder.addCase(fetchFunnelById.pending, (state) => {
      state.status = STATUS.LOADING;
    });
    // Handle fulfilled state of fetchFunnelById
    builder.addCase(fetchFunnelById.fulfilled, (state, action) => {
      // Update the state with the received data, but include the modified filterInfo
      state.data = action.payload;
      state.status = STATUS.IDLE;
    });

    // Handle rejected state of fetchFunnelById
    builder.addCase(fetchFunnelById.rejected, (state) => {
      state.status = STATUS.ERROR;
    });

  },
});

// Export actions and reducer
export const {
  setQueryPayloadData,
  resetQueryPayload,
  removeFilterCondition,
  addFilterCondition,
  setFilterInfoData,
} = queryPayloadSlice.actions;
export default queryPayloadSlice.reducer;
