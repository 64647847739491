
import React, { useState, useEffect } from 'react';
import { Tag } from 'antd'; 
import PushContainer from '../../../NotificationContainer/PushContainer/PushContainer';
import SMSContainer from '../../../NotificationContainer/SMSContainer/SMSContainer';
import VariantList from '../../../VariantList/VariantList';
import { CampaignChannelTypeEnum } from 'utils/enums';

const Template = ({ templateSelected, stepIndex, totalSteps }) => {
 
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [selectedVariant, setSelectedVariant] = useState<any>(null);
  const [variants, setVariants] = useState<any>([]);

  const { template, template_type, blobContainerCDN } = templateSelected[currentStepIndex] ?? {};
  // State to manage the current step index

  // State to store templates for each step dynamically
  const [templatesByStep, setTemplatesByStep] = useState({});

  // When template changes, reset the current step¯÷ā index to 0
  useEffect(() => {

    setCurrentStepIndex(stepIndex);
  }, [stepIndex]);
  // When a new template is selected, update the state for that specific step
  useEffect(() => {

    if (templateSelected && template  ) {


      // Create a new updatedTemplates object to ensure we don't mutate the state directly
      const updatedTemplates = { ...templatesByStep };
      // Update only the template for the current step (currentStepIndex)
      updatedTemplates[currentStepIndex] = {
        template,
        template_type
      };
  
      // Update the state with the new template for the specific step
      setTemplatesByStep(updatedTemplates);
    }
  }, [ template_type, template,stepIndex]);
  

  // Handle step click to change the current step
  const handleStepClick = (index) => {
    // console.log("step clickkk index",index)
    setCurrentStepIndex(index);
  };
  const isArray = Array.isArray(template);

  useEffect(() => {
    if (isArray) {
      setVariants(template);
      setSelectedVariant(template[0]);
    }
  }, [template]);
  // Render the step tags dynamically
  const renderStepTags = () => {
    return Array.from({ length: totalSteps }, (_, index) => (
      <Tag
      className='mb-2'
        key={index}
        color={currentStepIndex === index ? 'blue' : 'default'} // Highlight the selected step
        style={{ marginRight: '8px' }}
        onClick={() => handleStepClick(index)} // Handle step click
      >
        Step {index + 1}
      </Tag>
    ));
  };
 
  // Render the selected template for the current step
  const renderSelectedTemplate = () => {
    const currentTemplateData = templatesByStep[currentStepIndex];
    if (!currentTemplateData) {
      return null;
    }

    const { template, template_type } = currentTemplateData;
const isVariantArray = Array.isArray(template);

  // Handle case when template is an array of variants
    const selectedTemplate = isVariantArray? template[selectedVariant ? variants.indexOf(selectedVariant) : 0]:template;

    switch (template_type) {
      case CampaignChannelTypeEnum.PUSH:
        return (
          <PushContainer
            headerText={selectedTemplate?.notificationHeader}
            descriptionText={selectedTemplate?.notificationDescription}
            headerImage={`${blobContainerCDN}${selectedTemplate?.notificationImage}`}
            isExpandedImage={selectedTemplate?.pushType === 'expanded'}
            expandedImage={`${blobContainerCDN}${selectedTemplate?.expandedNotificationImage}`}
            expandedDescription={selectedTemplate?.expandedNotificationDescription}
            variableInfo={selectedTemplate?.variableInfo}
            mediaType={selectedTemplate?.mediaType}
          />
        );

      case CampaignChannelTypeEnum.SMS:
        return (
          <SMSContainer
            message={selectedTemplate?.message}
            variableInfo={selectedTemplate?.smsVariableInfo}
          />
        );

      case CampaignChannelTypeEnum.EMAIL:
        return (
          <div className='overflow-auto'>
            <EmailDeviceData
                campDetailsData={selectedTemplate}
              />
            
          </div>
        );

      default:
        return null;
    }
  };
  const handleVariantClick = (id: string) => {
    variants.forEach((variant: any) => {
      if (variant.variance_id == id) {
        setSelectedVariant(variant);
      }
    });
  };
  return (
    <div>

      <div className="d-flex">
        {/* Render dynamic step tags */}
        {renderStepTags()}
      </div>
      <div className={`p-2 ${templatesByStep[currentStepIndex]?.template ? 'border rounded' : '' } `}>
        {/* Render selected template based on current step */}
        {Array.isArray(template) && (
              <VariantList
                variants={variants}
                selectedVariant={selectedVariant}
                handleVariantClick={handleVariantClick}
              />
            )}
        {renderSelectedTemplate()}
      </div>
    </div>
  );
};

export default Template;
const EmailDeviceData = ({
  campDetailsData,
}: {
  campDetailsData: any;
}) => {
  return (
    <div className=" ms-3 campaign-detail-template">
      {campDetailsData && (
        <div>
          <span>{campDetailsData?.subjectData}</span>
          <div
            dangerouslySetInnerHTML={{
              __html: campDetailsData?.bodyHtmlData,
            }}
          />
        </div>
      )}
    </div>
  );
};