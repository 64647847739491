import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { STATUS } from "../../constant";
import centralApi from "../../../services/centralApi";
import { fetchFunnelById, setFilterInfoData, setQueryPayloadData } from "../audience/queryPayloadSlice";
export const fetchEventList = createAsyncThunk(
  "event/fetchEventList",
  async ({api_key, app_id}:any) => {
    const params = {
      api_key,
      app_id,
    };

    return centralApi(
      "GET",
      "o/metrices/getEventList",
      null,
      params
    ).then(({data}) => { 
      return data || [];
    });
  }
);
const getJourneyEvents=(data:any[],journey:string) => {
  // Initialize an empty array to hold filtered events
  let event_by_journey = [];
  // Check if the journey in the segment is not empty
  if (journey) {
     // Filter the events from eventList by matching journey
    event_by_journey = data?.filter((event: any) => {
      return event.journey == journey  || !event.journey;
    });
  }
  // Map filtered events to the desired structure (label, value, list)
  return event_by_journey?.map((event: any) => ({
    label: event?.displayname, // Display name of the event
    value: event?.event, // value of the event
    list: event?.list, // List of attributes
  }))}
const initialState:any= {
  data: [],
  status: STATUS.IDLE,
  journey:"",
  journeyEvents:[]
}
const eventListSlice = createSlice({
  name: "eventList",
 initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchEventList.pending, (state) => {
      state.status = STATUS.LOADING;
    });
    builder.addCase(fetchEventList.fulfilled, (state,action) => {
      if(state.journey){
        state.journeyEvents=getJourneyEvents(action.payload,state.journey)
      }
      state.status = STATUS.IDLE;
      state.data=action.payload
    });
    builder.addCase(fetchEventList.rejected, (state) => {
      state.status = STATUS.ERROR;
      state.data=[]
    });
    //setQueryPayloadData
    builder.addCase(setQueryPayloadData, (state, action) => {
      if(action.payload.journey && state.data){
        state.journeyEvents=getJourneyEvents(state.data,action.payload.journey)
      }
    });
    builder.addCase(fetchFunnelById.fulfilled, (state, action) => {
      state.journey=action.payload?.journey || ""
      if(action.payload?.journey && state.data){
        state.journeyEvents=getJourneyEvents(state.data,action.payload.journey)
      }
    });
  },
  reducers: {
  },
});
export default eventListSlice.reducer;

