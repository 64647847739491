import { Form, FormInstance} from "antd";
import { useEffect, useState } from "react";
import { t } from "i18next";
import Selector from "components/Selector/Selector";
import TextInput from "components/Inputs/TextInput/TextInput";
import SegmentedCustom from "components/SegmentedCustom/SegmentedCustom";
import { booleanStringOptions, E_OPERATOR_OPTIONS } from "utils/constants/selectorOptions";
import { OperatorManager, PgsqlOperators } from "utils/operators";
import { Attribute, FilterInfo } from "utils/models";
import { DataTypeEnum, E_OperatorEnum } from "utils/enums";
import {CloseOutlined} from "assets/icons"
import "./AttributeCondition.css"

type propType = {
  id: string | number;
  attributes: any[];
  index: number;
  form: FormInstance;
  condition:FilterInfo
  setValue: (data: Attribute | undefined) => void;
};
function AttributeCondition({
  id,
  attributes,
  index,
  setValue,
  form,
  condition,
}: propType) {
  const value=condition?.attributes?.[index]
  const [showForm, setShowForm] = useState(false);
  const [valid, setValid] = useState(false);
  const inputName={
    operand:`operand[${index}]_${id}`,
    operator:`operator[${index}]_${id}`,
    value:`value[${index}]_${id}`
  }
  const [fieldData, setFieldData] = useState<Attribute>({
    operand: value?.operand || "",
    operator: value?.operator || "",
    type:value?.type || "",
    e_operator: value?.e_operator,
    value: value?.value || [],
  });
  const handleInputChange = (data:Partial<Attribute>) => {
    setFieldData((prevState) => {
      const modifiedData = { ...prevState, ...data };
      setValue(modifiedData);
      return modifiedData;
    });
  };
  useEffect(() => {
    // Check if all required fields (attribute, operator, and value) are filled
    if (fieldData.operand && fieldData.operator && fieldData?.value?.length) {
      // If all fields are filled, mark the form as valid and invoke callback function
      setValid(true);
    } else {
      // If any required field is empty, mark the form as invalid
      setValid(false);
    }
  }, [fieldData, attributes]);
  useEffect(() => {
    //reset fields on component unmount
    return () => {
      form.resetFields();
    };
  }, []);

  return (
    <div className="attribute-condition-parent-container">
      <div>
        {fieldData?.e_operator && index !== 0 && (
          <div>
            <SegmentedCustom
              size={"small"}
              value={fieldData.e_operator}
              options={E_OPERATOR_OPTIONS}
              onChange={(value: E_OperatorEnum) =>
                handleInputChange({ e_operator: value })
              }
            />
          </div>
        )}
      </div>
      <div
        className="d-flex gap-2 p-0 pb-1 "
        onMouseLeave={() => setShowForm(valid ? false : true)}
      >
        <Form.Item
          hidden={valid && !showForm}
          name={inputName.operand}
          rules={[{ required: true, message: `${t("selectAttributeMsg")}` }]}
          className="m-0 input-size-md"
          initialValue={fieldData.operand || undefined}
        >
          <Selector
            placeholder={`${t("selectAttributeMsg")}`}
            onChange={async (attributeValue) => {
              const type=attributes?.find(({value})=>value===attributeValue)?.type
              await handleInputChange({ operand: attributeValue,type,operator:undefined,value:undefined});
              form.resetFields([inputName.operator,inputName.value])
            }}
            options={attributes}
          ></Selector>
        </Form.Item>
        <Form.Item
          hidden={valid && !showForm}
          rules={[{ required: true, message: `${t("selectOperatorMsg")}` }]}
          name={inputName.operator}
          className="m-0 input-size-md"
          initialValue={fieldData.operator || undefined}
        >
          <Selector
            placeholder={`${t("selectOperatorMsg")}`}
            onChange={async(value) => {
              await handleInputChange({ operator: value ,value:undefined})
              form.resetFields([inputName.value])
            }}
            options = {
              // Retrieve PostgreSQL operator options based on the field type.
              (OperatorManager.getPgSqlOperatorOptions(fieldData.type)?.options || [])
                .filter(({ value }: { value: any }) => {
                  // Filter only specific operators: EQUALS, NOT_EQUALS, LESS_THAN, and GREATER_THAN.
                  return [
                    PgsqlOperators.EQUALS,
                    PgsqlOperators.NOT_EQUALS,
                    PgsqlOperators.LESS_THAN,
                    PgsqlOperators.GREATER_THAN
                  ].includes(value);
                })
            }
          ></Selector>
        </Form.Item>{" "}
        {fieldData?.operand ? (
          <Form.Item
            hidden={valid && !showForm}
            rules={[{ required: true, message: `${t("valueLabel")}` }]}
            className="m-0 input-size-sm "
            name={inputName.value}
            initialValue={fieldData.value?.[0]}
          >
          {fieldData.type==DataTypeEnum.BOOLEAN?<Selector
              onChange={(value) => {
              handleInputChange({ value:[value] })}}
              placeholder={`${t("valueLabel")}`}
              options={booleanStringOptions()}/>:<TextInput
              type={fieldData.type}
             placeholder={`${t("valueLabel")}`}
             onChange={(e:React.ChangeEvent<HTMLInputElement>) => {
              const value=e.target.value
              handleInputChange({ value:value?[value]:[] });
            }}
            ></TextInput>}
          </Form.Item>
        ) : (
          <></>
        )}
      </div>
      {/* Display selected attribute, operator, and value preview if the form is valid and showform is false  */}
      {valid && !showForm && (
        <div className="d-flex align-items-center">
          <div
            className="result-condition me-2 d-flex gap-2 text-overflow-ellipsis"
            onClick={() => {
              setShowForm(true);
            }}
          >
            <span>{fieldData.operand}</span>{" "}
            <strong>
              {
                //get operator label
               OperatorManager.getOperatorLabel(fieldData?.operator)
              }
            </strong>{" "}
            <span>{fieldData.value}</span>
          </div>
          {index + 1 == condition?.attributes?.length && (
            <CloseOutlined
              onClick={() => {
                setValue(undefined);
              }}
            />
          )}
        </div>
      )}
    </div>
  );
}
export default AttributeCondition;
