
import {  Form } from "antd";
import { useForm } from "antd/es/form/Form";
import PrimaryButton from "components/Buttons/PrimaryButton/PrimaryButton";
import CustomDrawer from "components/CustomDrawer/CustomDrawer";
import TextInput from "components/Inputs/TextInput/TextInput";
import Selector from "components/Selector/Selector";
import { t } from "i18next";
import { FC, useEffect, useState } from "react";
import {  STRING_BOOLEAN_OPTIONS } from "utils/constants/selectorOptions";
interface AddRowProps {
  addRowState: { open: boolean };
  setAddRowState: (value: { open: boolean }) => void;
  setFormData: (data: any) => void;
  columns:any;
  onSave: (data: any) => void;  // Adding onSave as a prop to handle the form data in the parent
  initialValues: any;  // Accept initial values for the form

}

const AddRow: FC<AddRowProps> = ({
  addRowState,
  setAddRowState,
  setFormData,
  columns,
  onSave,
  initialValues,
}) => {
  const [form] = useForm();
 
  const mapInitialValuesToForm = () => {
    const formValues: any = {};
    columns.forEach((column) => {
      if (initialValues && initialValues[column.key] !== undefined) {
        // Convert boolean values to "true" or "false" strings
        if (typeof initialValues[column.key] === STRING_BOOLEAN_OPTIONS.BOOLEAN) {
          formValues[column.key] = initialValues[column.key] ? STRING_BOOLEAN_OPTIONS.TRUE : STRING_BOOLEAN_OPTIONS.FALSE;
        } else {
          formValues[column.key] = initialValues[column.key];
        }
      } else {
        // formValues[column.key] = ''; // Default empty value for missing keys
      }
    });
    return formValues;
  };
  // When initialValues change, update the form fields
  useEffect(() => {
    if (initialValues) {
      const mappedValues = mapInitialValuesToForm(); // Map initial values
      form.setFieldsValue(mappedValues); // Update form fields with mapped values
    }
  }, [initialValues, columns, form]);

  //handle form submit
  const onSubmit = (values) => {
    setFormData(values)
    onSave(values);  // Call the parent's onSave function with form values

  };
  return (
    <CustomDrawer
      footer={
        <div>
          <PrimaryButton type="primary"  onClick={() => form.submit()}>
            {t("saveLabel")}
          </PrimaryButton>
        </div>
      }
      open={addRowState.open}
      placement="right"
      closable={false}
      width={600}

      onClose={() => setAddRowState({ open: false })}
    >
      {columns.length >0?
      <div className=" mt-1 ">
        <h5 className="fw-semibold">{t("addRowLabel")}</h5>
        <div className="mt-3">
          <Form
            colon={false}
            labelAlign="left"
            layout="vertical"
            form={form}
            onFinish={onSubmit}
          >
      {columns.map((column, index) => (
          
          <Form.Item
            key={index}
            label={column.title} // Use column title as label
            name={column.key}  // Use column's key as the name
          >
           
               {column.type === STRING_BOOLEAN_OPTIONS.BOOLEAN ? (
            <Selector
            
              style={{ fontSize: 12 }}
              options={[
                { label: t("TrueLabel"), value: STRING_BOOLEAN_OPTIONS.TRUE },
                { label: t("FalseLabel"), value: STRING_BOOLEAN_OPTIONS.FALSE },
              ]}
              placeholder={t("selectLabel")}
            />
          ) : (
            <TextInput
              placeholder={`Enter ${column.title}`}
            />
          )}
            
          </Form.Item>
        ))}
         </Form>
        </div>
      </div>:null}
    </CustomDrawer>
  );
};

export default AddRow;
