import PrimaryButton from "components/Buttons/PrimaryButton/PrimaryButton";
import { useNavigate } from "react-router-dom";
import React, { useEffect } from 'react';
import './NotFound.css'; // Make sure to create and import a CSS file for styling
import { useSelector } from 'react-redux';
import { t } from "i18next";

interface NotFoundProps {
  setShowSideNav: (show: boolean) => void;
  isPageNotFound?: boolean;
}
  const NotFound: React.FC<NotFoundProps> = ({ setShowSideNav ,isPageNotFound}) => {
    const activeApp = useSelector((state: any) => state.activeApp);
      const linkTo = activeApp.appId && activeApp.appName 
      ? `/dashboard/app?id=${activeApp.appId}` 
      : '/dashboard';
      useEffect(() => {
      setShowSideNav(false);
      }, [])
     
      const handleHomeClick = () => {
        if (window.location.pathname + window.location.search === linkTo) {
          // Reload the page if already on the target URL
          window.location.reload();
        } else {
          // Navigate to the target URL
          navigate(linkTo);
        }
      };      
    const navigate=useNavigate()
    return (
      <div
        style={{ height: "100vh" }}
        className="d-flex flex-column gap-3  justify-content-center align-items-center"
      >
        <h1>{ isPageNotFound ? t("pageNotFoundLabel") : t("accessDeniedLabel")}</h1>
        <p>{isPageNotFound ?  t("pageNotFoundMessageLabel") :t("accessDeniedMessageLabel")}</p>
        <div className="d-flex gap-2">
            <PrimaryButton  onClick={()=>navigate(-1)}>{t("backLabel")}</PrimaryButton>
            <PrimaryButton type="primary" onClick={handleHomeClick}>{t("homeLabel")}</PrimaryButton>

        </div>
      </div>
    );
  };
  export default NotFound;
