import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../../../redux/features/user/loginUserSlice";
import AppCard from "../../../components/Cards/AppCard/AppCard";
import useMessage from "../../../hooks/useMessage";
import "./Home.css";
import { useNavigate } from "react-router-dom";
import Spinner from "../../../components/Spinner/Spinner";
import { setActiveApp } from "../../../redux/features/app/activeAppSlice";
import { Helmet } from "react-helmet";
import centralApi from "../../../services/centralApi";
import { ROUTES } from "utils/constants";
import { t } from "i18next";
const Home: React.FC =()=>{
  const [loading, setLoading] = useState(true);
  const [dashboardData, setDashboardData] = useState<any>({});
  const { showError } = useMessage();
  const [appStats, setAppStats] = useState<any>({data:{},loading:true});
  const loginUser = useSelector((state: any) => state.loginUser.data);
  const { appId } = useSelector((state: any) => state.activeApp);
  const dispatch:any = useDispatch();
  const navigate = useNavigate();
  /**
 * handleAppClick handles navigation to either an app dashboard or app settings.
 * 
 * This function checks whether the current user has global admin rights or if the user 
 * has been approved for the selected app. If the user is allowed, it updates the active 
 * app state and navigates to the specified page. If the user is not approved, it shows 
 * an error message.
 * 
 * @param {string} id - The unique ID of the selected app.
 * @param {string} appName - The name of the selected app.
 * @param {string} redirectTo - The target route to navigate to (e.g., 'appsetting' or 'app').
 */
const handleAppClick = (id: string, appName: string, redirectTo: string) => {
  // Check if the user is a global admin or has been approved for the selected app
  if (loginUser?.global_admin || loginUser?.approvedAppids?.includes(id)) {
    // If the user is allowed, dispatch the action to set the active app in the state
    dispatch(setActiveApp({ appName, appId: id }));

    // Navigate to the specified route, passing the app name in the state
    navigate(redirectTo, { state: { appName } });
  } else {
    // If the user is not approved, show an error message
    showError(t("approvalPendingMsg"));
  }
};

  const fetchAppsStats=async()=>{
    try {
      const params = {
        api_key: loginUser.api_key,
        app_id: appId,
      };
      const app_stats = await centralApi(
        "GET",
        "o/metrices/getAppStats",
        null,
        params
      );
      setAppStats({data:app_stats?.data,loading:false});
    } catch (error) {
      setAppStats({data:{},loading:false});
    }
    
  }
  const fetchDashboard = async () => {
    try {
      setLoading(true);
      const data= await centralApi("GET","/o/dashboard")
      if (data.redirect) {
        if(data.redirectPath=="/login"){
          dispatch(logoutUser())
        }
        else{
          navigate(data.redirectPath);
        }
      }
      setDashboardData(data);
      setLoading(false);
    } catch (error) {
      dispatch(logoutUser())
      setLoading(false);
    }
  };
  useEffect(() => {
    if(loginUser.api_key){
      fetchDashboard();
      fetchAppsStats()
    }
  }, []);
  return (
    <div className="panel-home ">
      <Helmet>
        <title>Appice | Home</title>
      </Helmet>
      {loading?<Spinner />:dashboardData?.userOfApps?.length?<></>:<div className="d-flex justify-content-center text-center align-items-center">
        
        {loginUser?.global_admin  ? <h4>{t("noAppLabel")}</h4> :<h4>{t("noAppAssignedMsg")}</h4>}
        </div>}
      {!loading &&
        dashboardData.userOfApps?.map((app: any) => {
          return (
            <AppCard
              key={app._id}
              appName={app.name}
              dashboardClick={() => handleAppClick(app._id,app.name,`app?id=${app._id}`)}
              metaDataLoading={appStats.loading}
              values={{
                installs: {
                  android: appStats.data[app._id]?.androidInstallTotal || "NA",
                  ios: appStats.data[app._id]?.iosInstallTotal  || "NA" ,
                  global:appStats.data[app._id]?.webInstallTotal || "NA" ,
                },
                churned: {
                  android: appStats.data[app._id]?.androidUnInstallTotal || "NA",
                  ios: appStats.data[app._id]?.iosUnInstallTotal || "NA",
                  global: appStats.data[app._id]?.webUnInstallTotal || "NA",
                },
              }}
              settingClick={() => handleAppClick(app._id,app.name,ROUTES.DASHBOARD_APPSETTING)}
              deleteClick={loginUser?.global_admin?() =>{}:undefined}
            ></AppCard>
          );
        })}
    </div>
  );
}

export default Home;
