import { useEffect } from "react";
import FunnelGraph from "funnel-graph-js";
import "./FunnelChart.css";
import { t } from "i18next";
interface dataType {
  title: string;
  count: number;
  avgStepDuration?: number;
  avgTotDuration?: number;
  footerLabel?: string;
  dropoffOperand?: string;

}
/**
 * Formats time from seconds into a human-readable "Xm Ys" format.
 * @param {number} [seconds] - The time in seconds to be formatted.
 * @returns {string} - The formatted time string (e.g., "5m 30s"), or an empty string if input is undefined or zero.
 */
function formatTime(seconds?: number): string {
    if (!seconds) return ""; // Return an empty string if seconds is undefined or zero
    const minutes = Math.floor(seconds / 60); // Convert total seconds into minutes
    const remainingSeconds = seconds % 60; // Get remaining seconds after converting to minutes
    return `${minutes}m ${remainingSeconds}s`; // Format and return the time string
  }
  
const FunnelChart = ({ data }: { data: dataType[] }) => {
  useEffect(() => {
    if (data) {
      const graph = new FunnelGraph({
        container: ".funnelContainer",
        gradientDirection: "vertical",
        data: {
          labels: data?.map(
            (item) =>
              `${item.title}${
                item.avgStepDuration
                  ? `\n${t("avgStepDurationLabel")}: ${formatTime(
                      item.avgStepDuration
                    )}`
                  : ""
              }${
                item.avgTotDuration
                  ? `\n${t("avgCompletionTimeLabel")}: ${formatTime(item.avgTotDuration)}`
                  : ""
              }`
          ),
          colors: ["orange", "red"],
          values:
            data
              ?.filter(({ count }) => count !== undefined)
              ?.map(({ count }) => Number(count)) || [],
        },
        displayPercent: true,
        direction: "horizontal",
        width:
          (data?.length || 0) * 240 > 600 ? (data?.length || 0) * 240 : 600,
        height: 350,
        subLabelValue: "raw",
      });
      graph.draw();
      data?.forEach((item, index) => {
            const parentElement = document.querySelector(`.label-${index + 1}`);
            if (parentElement) {
                parentElement.innerHTML += `<div class="d-flex custom__funnel_bottom__label flex-column">${item?.footerLabel?`<span>${item?.footerLabel}</span>`:""}${item.dropoffOperand?`<span class="dropoff_custom_label" >${t("dropoffEventLabel")} : ${item.dropoffOperand}</span>`:""}<div>`;
            }
      });
    }
  }, [data]);

  return (
    <div className="funnel-chart-main-container">
      <div className="funnelContainer" />
    </div>
  );
};

export default FunnelChart;
