import { FC, useEffect, useMemo } from "react";
import { Form, Tag } from "antd";
import Selector from "components/Selector/Selector";
import { t } from "i18next";
import { Attribute, FilterInfo } from "utils/models";
import { setFilterInfoData } from "redux/features/audience/queryPayloadSlice";
import { useDispatch, useSelector } from "react-redux";
import PrimaryButton from "components/Buttons/PrimaryButton/PrimaryButton";
import { FormInstance } from "antd/lib";
import AttributeCondition from "../AttributeCondition/AttributeCondition";
import { E_OperatorEnum } from "utils/enums";
import "./EventCondition.css"
interface EventConditionProps {
  condition: FilterInfo;
  index: number;
  form: FormInstance;
}
// Initial value for attributes
const attributeInitialValue: Attribute = {
  operand: "",
  operator: "",
  value: [],
  type: "",
  e_operator: E_OperatorEnum.AND,
};
const EventCondition: FC<EventConditionProps> = ({ condition, index, form }) => {
  const dispatch = useDispatch();
  const eventsByJourney = useSelector(
    (state: any) => state.eventList.journeyEvents
  );

  /**
   * Handles changes in the form data.
   * @param data - An object representing the  form data. Each key is a string, and values can be a string, array of strings, boolean, or undefined.
   */
  const handleInputChange = async (data: {
    [key: string]: string | string[] | boolean | undefined | Attribute[];
  }) => {
    await dispatch(
      setFilterInfoData({
        data: { ...condition, ...data },
        parentIndex: -1,
        index,
      })
    );
    // reset all other fields in block if operand gets change
    if (data.operand) {
      form.resetFields();
    }
  };
  /**
 * Retrieves the attributes of a specific event based on the provided operand.
 *
 * @param {string | undefined} operand - The event identifier to search for.
 * @returns {Array<{ label: string; value: string; type: string }>} 
 *          - An array of event attributes containing label, value, and type.
 *          - Returns an empty array if the operand is undefined or no matching event is found.
 */
const getEventAttributes = (operand: string | undefined) => {
  // If operand is undefined or eventsByJourney is not available, return an empty array.
  if (!operand || !eventsByJourney) return [];

  // Find the event in eventsByJourney that matches the given operand.
  const matchedEvent = eventsByJourney.find(
    ({ value }: { value: string }) => value === operand
  );

  // If a matching event is found, map its list to extract event attributes.
  return (
    matchedEvent?.list.map(
      ({ name, type }: { name: string; type: string }) => ({
        label: name, 
        value: name, 
        type,      
      })
    ) || []
  );
};

  //get all attributes of selected event
  const attributes = useMemo(() => {
   return getEventAttributes(condition?.operand)
  }, [condition?.operand, eventsByJourney]);
  //get all attributes of selected dropoff_event
  const dropoff_attributes = useMemo(() => {
    return getEventAttributes(condition.dropoff_operand)
  }, [condition?.dropoff_operand, eventsByJourney]);
useEffect(()=>{
 return ()=>{
  form.resetFields()
 } 
},[])
  return (
    <div className="w-100 d-flex justify-content-between audience-event-step-container">
      <div className="d-flex flex-column gap-3">
        <div>
          <Form.Item
            rules={[{ required: true, message: `${t("selectEventMsg")}` }]}
            className="m-0 input-size-lg"
            name={`operand_${index}`}
            initialValue={condition.operand || undefined}
          >
            <Selector
              // className="input-size-lg"
              showSearch
              onChange={(value: string) => {
                handleInputChange({
                  operand: value,
                  attributes: [],
                  dropoff_attribute: undefined,
                });
              }}
              options={eventsByJourney || []}
              placeholder={`${t("selectEventMsg")}`}
            />
          </Form.Item>
        </div>
        <div className="d-flex flex-wrap w-100 flex-column">
          {!condition.attributes || condition?.attributes?.length == 0 ? (
            <PrimaryButton
              className="input-size-sm"
              onClick={() =>
                handleInputChange({
                  attributes: [
                    { ...attributeInitialValue, e_operator: undefined },
                  ],
                })
              }
              disabled={attributes?.length == 0}
            >
              {t("attributeLabel")}
            </PrimaryButton>
          ) : (
            condition.attributes.map((_: any, attrIndex: number) => {
              return (
                <AttributeCondition
                  form={form}
                  id={index}
                  condition={condition}
                  key={`${index}_${attrIndex}`}
                  setValue={(data) => {
                    let modifiedAttribute = [...(condition?.attributes || [])];
                    if (data) {
                      modifiedAttribute[attrIndex] = data;
                    }
                    //remove the attribute object from attributes in redux
                    else {
                      modifiedAttribute.splice(attrIndex, 1);
                    }
                    handleInputChange({ attributes: modifiedAttribute });
                  }}
                  index={attrIndex}
                  attributes={attributes}
                />
              );
            })
          )}
          <div>
            {condition.attributes && condition?.attributes?.length > 0 && (
              <>
                <Tag
                  className="cursor-pointer"
                  onClick={() => {
                    handleInputChange({
                      attributes: [
                        ...(condition?.attributes || []),
                        attributeInitialValue,
                      ],
                    });
                  }}
                >
                  {t("plusWhereLabel")}
                </Tag>
              </>
            )}
          </div>
        </div>
      </div>
     {index?<div className="ps-2 ms-2 funnel-dropoff-container">
        <span className="">{t("inputDropoffDetailsLabel")}</span>
      <div className="d-flex gap-2 flex-wrap mt-3">
         <Form.Item
            colon={false}
            labelCol={{flex:"110px"}}
            labelAlign="left"
            label={t("dropoffEventLabel")}
            className="input-size-lg m-0"
            name={`dropoff_operand_${index}`}
            initialValue={condition.dropoff_operand || undefined}
          >
            <Selector
              showSearch
              allowClear
              onClear={()=>
                handleInputChange({
                  dropoff_operand: undefined,
                  dropoff_attribute:undefined
                })
              }
              onChange={(value: string) => {
                handleInputChange({
                  dropoff_operand: value,
                  dropoff_attribute: undefined
                });
                form.resetFields([`dropoff_attribute_${index}`])
              }}
              options={eventsByJourney || []}
            />
          </Form.Item>
            <Form.Item
              labelCol={{flex:"110px"}}
              colon={false}
              labelAlign="left"
              className="m-0 input-size-lg"
              label={t("dropoffAttributeLabel")}
              name={`dropoff_attribute_${index}`}
              initialValue={condition.dropoff_attribute || undefined}
            >
              <Selector
                showSearch
                allowClear
                onClear={()=>
                  handleInputChange({
                    dropoff_attribute: undefined,
                  })
                }
                onChange={(value: string) => {
                  handleInputChange({
                    dropoff_attribute: value,
                  });
                }}
                options={dropoff_attributes}
              />
            </Form.Item>
            </div>
            </div>:<></>}
    </div>
  );
};

export default EventCondition;


