"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.securityConfig = void 0;
exports.securityConfig = {
    encryptedKeys: {
        siteSettings: 0,
        emailCfg: 0,
        beefreeConfig: 0,
        googleMapApikey: 0,
        infrastructureSettings: 0,
        ldapConfig: 0,
        samlConfig: 0,
        push_notification: 0,
        webPush_prod: 0,
        webPush_dev: 0,
        email_Config: 0,
        appsettingsBaseUrl: 0,
        frequency_capping: 0,
        DND_Values: 0,
        mode_prod: 0,
        partnerInfo: 0,
        webhook: 0,
        passThrough: 0,
        campaignConfigurator: 0,
        hms: 0,
        channel_obj: 0,
        fcm: 0,
        wpn: 0,
        fcmKey_prod: 0,
        fcmKey_dev: 0,
        isServiceEnabled: 0,
        ios_cert3: 0,
        ios_bundleid: 0,
        ios_key_type: 0,
        ios_keyid: 0,
        ios_teamid: 0,
        ios_topic: 0,
        ios_passphrase: 0,
        proxyUrl: 0,
        proxyPORT: 0,
        smsCfg: 0,
        gcm_key: 0,
        dev_gcm_key: 0,
        dev_ios_cert3: 0,
        dev_ios_bundleid: 0,
        kill_switch: 0,
        encryptionkey: 0
    },
    sensitiveKeys: {
        infrastructureSettings: 0,
        ldapConfig: 0,
        samlConfig: 0,
        epnu_key_true: 0,
        epnu_key_false: 0,
        epnu_key_prod: 0
    }
};
