import React, { ReactNode, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { BOOLEAN_OPTIONS } from 'utils/constants/selectorOptions';
import { checkUserRole } from 'helperFunctions/common';
import { FeatureSettingsFlagTypes, roleCheckTypes } from 'utils/constants/constants';
import { STATUS } from 'redux/constant';
import Spinner from 'components/Spinner/Spinner';
import { ROUTES } from 'utils/constants';

// Define RoleCondition as a union type of all keys in roleCheckTypes.
// This allows us to refer to specific roles without manually defining each one.
type RoleCondition = keyof typeof roleCheckTypes;

// Define FeatureCondition as a union type of all keys in FeatureSettingsFlagTypes.
// This ensures only valid feature flags can be used.
type FeatureCondition = typeof FeatureSettingsFlagTypes[keyof typeof FeatureSettingsFlagTypes];

// Define the props expected by the AccessControl component
interface AccessControlProps {
  children: ReactNode;
  accessControl?: { 
    role?: RoleCondition[];  // Array of roles required for access
    features?: FeatureCondition[]; // Array of feature flags required for access
  }; 
  fallbackComponent?: ReactNode; // Component to render if access is denied
  getAccessedItems?: (filteredItems: Array<{ role?: RoleCondition ,featureFlag?:FeatureCondition,[key: string]: any}>) => void; // Function to receive filtered items
  items?: Array<{ role?: RoleCondition,featureFlag?:FeatureCondition,[key: string]: any }>; // List of items to be filtered based on role access
}

const AccessControl: React.FC<AccessControlProps> = ({
  children,
  accessControl,
  fallbackComponent = <></>,
  getAccessedItems,
  items
}) => {
  // Get the currently logged-in user's data from the Redux store
  const loggedInUser = useSelector((state: any) => state?.loginUser?.data);
  const currentUserRole = loggedInUser?.activeAppUserRole; // Retrieve the user's role
  const isValid = loggedInUser?.isValid 
  const { appId } = useSelector((state: any) => state.activeApp);
 
  // Get the feature settings from Redux state and type it to allow optional properties
  const features: Partial<Record<FeatureCondition, boolean>> = useSelector(
    (state: any) => state.featuresSetting?.data[0]?.features || {}
  );

  const settingLoadder = useSelector((state: any) => state.featuresSetting.status);

  const loginLoadder = useSelector((state: any) => state?.loginUser.status);

  const isLoading =()=>{
    return settingLoadder == STATUS.LOADING || loginLoadder == STATUS.LOADING
  }

  

  // useEffect hook runs whenever the 'items' array changes.
  // Filters the items based on roles and feature settings  passes the filtered list to 'getAccessedItems' if provided.
  useEffect(() => {
    if (items && getAccessedItems) {
      const filteredItems = items.filter(({ role,featureFlag }) => {
        //Filters the items based on  feature settings
        if(featureFlag && (features[featureFlag]===undefined || features[featureFlag] === BOOLEAN_OPTIONS.TRUE)){
          return BOOLEAN_OPTIONS.FALSE
        }
        return role
          ? roleCheckTypes[role] // Check if the role is defined in roleCheckTypes
            ? checkUserRole(currentUserRole, roleCheckTypes[role]) // Check if user has required role
            : BOOLEAN_OPTIONS.FALSE // Deny access if the role is undefined in roleCheckTypes
          : BOOLEAN_OPTIONS.TRUE; // Allow access if role is undefined on item
      });
      getAccessedItems(filteredItems); // Pass filtered items to the callback function
    }
  }, []);

  // Determine if the user is authenticated by checking if they have an API key
  const isAuthenticated = loggedInUser?.api_key ? BOOLEAN_OPTIONS.TRUE : BOOLEAN_OPTIONS.FALSE;

  //Render to Dashboard route if we have app id == null 
  if(loggedInUser?.api_key && appId === null && location.pathname !== ROUTES.DASHBOARD_CREATEAPP && location.pathname !== ROUTES.DASHBOARD_APPMANAGEMENT && location.pathname !== ROUTES.DASHBOARD){
    window.location.pathname = ROUTES.DASHBOARD
  }

  // Initialize an array to store all access condition checks
  const conditionChecks = [isAuthenticated];

   // Check if user is valid by signature check i.e no change is made to user in api response 
   if (loggedInUser?.api_key  && !isValid) {
    conditionChecks.push(BOOLEAN_OPTIONS.FALSE)
  }


  // Check if the user has any of the required roles specified in accessControl
  if (accessControl?.role) {
   let hasRoleAccess = accessControl.role.some((role: RoleCondition) =>
    checkUserRole(currentUserRole, roleCheckTypes[role])
  );
  conditionChecks.push(hasRoleAccess);
  }

  // Check feature flags in accessControl: deny access if any required feature flag is active
  if (accessControl?.features) {
    accessControl.features?.forEach((feature) => {
      if (features[feature] === BOOLEAN_OPTIONS.TRUE || features[feature]==undefined) {
        conditionChecks.push(BOOLEAN_OPTIONS.FALSE); // Deny access if the feature flag is active
      }
    });
  }

  // Evaluate all conditions: access is granted only if all checks are true
  const isAccessGranted = conditionChecks.every(Boolean);
  
  // Conditionally render children or fallback component based on access status
  return   isLoading() ? <Spinner/> : isAccessGranted ? <>{children}</> :  <>{fallbackComponent}</>;
};

export default AccessControl;
