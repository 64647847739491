import { Divider } from "antd";
import "./AllowList.css";
import PrimaryButton from "../../../../components/Buttons/PrimaryButton/PrimaryButton";
import { Form } from "antd";
import TextInput from "../../../../components/Inputs/TextInput/TextInput";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import centralApi from "../../../../services/centralApi";
import Spinner from "../../../../components/Spinner/Spinner";
import { t } from "i18next";
import { API_ENDPOINTS } from "utils/constants";
import { getToken } from "redux/features/app/authTokenSlice";
import useMessage from "hooks/useMessage";
interface AllowListProps {
  currentAppKey: any;
  updateCurrentAppKey: () => void;
}

const AllowList: React.FC<AllowListProps> = ({ currentAppKey , updateCurrentAppKey}) => {
  const [form] = Form.useForm();
  const { showError, showSuccess } = useMessage();

  const [loading, setLoading] = useState(false);
  const dispatch: any = useDispatch();
  const authToken = useSelector((state: any) => state.authToken.token);


  const loginUser = useSelector((state: any) => state.loginUser);
  const activeApp = useSelector((state: any) => state.activeApp);
   const appId = activeApp.appId;
  const api_key = loginUser.data.api_key;
  const onFormSubmit = async(values: any) => {
    const payload = {
      args: JSON.stringify({
        app_id: appId,
        allowList: values.allowList,
      }),
      authToken,
      api_key: api_key,
    };
  await  saveAllowList(payload);
   await dispatch(getToken())
  };

  async function saveAllowList(formData: any) {
    const url: string = API_ENDPOINTS.SAVE_ALLOW_LIST_URL;

    try {
      setLoading(true);
      await centralApi("POST", url, formData,null);
      
      updateCurrentAppKey();
      showSuccess(t("sucessfullySavedLabel"));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      showError(t("somethingWrongLabel"));
    
    }
    dispatch(getToken())
  }
  

  
  useEffect(() => {
    const updatedFormValues = {
      allowList: currentAppKey[0]?.allowList
    };
    form.setFieldsValue(updatedFormValues);

  }, [currentAppKey, form]);
  return (
    <div className="allow-list-setting-container">
      <div className="mt-4 mb-4  ">
        <h6 className="allow-list-setting-container-heading"> {t("allowListSettingsLabel")}  </h6>
        <Divider className="allow-list-setting-custom-divider" />

        <div>
          <div className=" col-sm-8 mt-4 align-items-center allow-list-setting-form ">
          
            {loading ? (
              <Spinner />
            ) : (
              <Form
                form={form}
                layout="horizontal"
                onFinish={onFormSubmit}
              >
                <Form.Item
                  label= {t("allowListLabel")} 
                  name="allowList"
                >
                  <TextInput />
                </Form.Item>
             

                <div >
                  <PrimaryButton
                    size="middle"
                    type="primary"
                    htmlType="submit"
                    style={{ border: "none", width: "70px" }}
                  >
                    {t("saveLabel")}
                  </PrimaryButton>
                </div>
              </Form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default AllowList;
