"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.QueryPayload = exports.FilterInfo = exports.ProjectionInfo = exports.TableInfo = exports.Attribute = exports.ModifierInfo = void 0;
const typescript_json_serializer_1 = require("typescript-json-serializer");
const base_1 = require("../common/base");
const constants_1 = require("../../constants");
/**
 * Represents the pagination part of the query about the table/projection/.
 */
class ModifierInfo {
    constructor(limit, orderBy, orderByName) {
        this.limit = limit;
        this.orderBy = orderBy;
        orderByName = orderByName;
    }
}
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", Number)
], ModifierInfo.prototype, "limit", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], ModifierInfo.prototype, "orderBy", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], ModifierInfo.prototype, "orderByName", void 0);
exports.ModifierInfo = ModifierInfo;
/**
 * Represents information about attribute
 */
let Attribute = class Attribute {
    constructor(operand, operator, value, type, e_operator) {
        this.operand = operand;
        this.operator = operator;
        this.value = value;
        this.type = type;
        this.e_operator = e_operator;
    }
};
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], Attribute.prototype, "operand", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], Attribute.prototype, "operator", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", Array)
], Attribute.prototype, "value", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], Attribute.prototype, "type", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], Attribute.prototype, "e_operator", void 0);
Attribute = __decorate([
    (0, typescript_json_serializer_1.JsonObject)(),
    __metadata("design:paramtypes", [String, String, Array, String, String])
], Attribute);
exports.Attribute = Attribute;
/**
 * Represents the information about the table.
 */
let TableInfo = class TableInfo {
    constructor(tableName, modifierInfo // this will. be used in future , Currently not in support
    ) {
        this.tableName = tableName;
        this.modifierInfo = modifierInfo; // this will. be used in future , Currently not in support
    }
};
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], TableInfo.prototype, "tableName", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", ModifierInfo)
], TableInfo.prototype, "modifierInfo", void 0);
TableInfo = __decorate([
    (0, typescript_json_serializer_1.JsonObject)(),
    __metadata("design:paramtypes", [String, ModifierInfo // this will. be used in future , Currently not in support
    ])
], TableInfo);
exports.TableInfo = TableInfo;
/**
 * Represents information about a projection.
 */
let ProjectionInfo = class ProjectionInfo {
    constructor(operand, type, func, name, jsonb, modifierInfo, isSensitive, defaultValue, jsonb_type) {
        this.operand = operand;
        this.type = type;
        this.func = func;
        this.name = name;
        this.jsonb = jsonb;
        this.modifierInfo = modifierInfo;
        this.jsonb_type = jsonb_type;
        this.isSensitive = isSensitive;
        this.defaultValue = defaultValue;
    }
};
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], ProjectionInfo.prototype, "operand", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], ProjectionInfo.prototype, "type", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], ProjectionInfo.prototype, "func", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], ProjectionInfo.prototype, "name", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], ProjectionInfo.prototype, "jsonb", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], ProjectionInfo.prototype, "jsonb_type", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", Boolean)
], ProjectionInfo.prototype, "isSensitive", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], ProjectionInfo.prototype, "defaultValue", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", ModifierInfo)
], ProjectionInfo.prototype, "modifierInfo", void 0);
ProjectionInfo = __decorate([
    (0, typescript_json_serializer_1.JsonObject)(),
    __metadata("design:paramtypes", [String, String, String, String, String, ModifierInfo, Boolean, String, String])
], ProjectionInfo);
exports.ProjectionInfo = ProjectionInfo;
/**
 * Represents information about a filter condition.
 */
let FilterInfo = class FilterInfo {
    constructor(operand, operator, value, type, e_operator, precedence, group, jsonb, compute, func, interval, comparison, modifierInfo, // this will. be used in future , Currently not in support
    period) {
        this.operand = operand;
        this.operator = operator;
        this.value = value;
        this.type = type;
        this.e_operator = e_operator;
        this.precedence = precedence;
        this.group = group;
        this.jsonb = jsonb;
        this.compute = compute;
        this.func = func;
        this.interval = interval;
        this.modifierInfo = modifierInfo, // this will. be used in future , Currently not in support
            this.comparison = comparison;
        this.period = period;
    }
};
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], FilterInfo.prototype, "operand", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], FilterInfo.prototype, "operator", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", Array)
], FilterInfo.prototype, "value", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], FilterInfo.prototype, "type", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], FilterInfo.prototype, "e_operator", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", Number)
], FilterInfo.prototype, "precedence", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", Array)
], FilterInfo.prototype, "group", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], FilterInfo.prototype, "jsonb", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], FilterInfo.prototype, "jsonb_type", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", Boolean)
], FilterInfo.prototype, "compute", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], FilterInfo.prototype, "func", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], FilterInfo.prototype, "interval", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], FilterInfo.prototype, "comparison", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], FilterInfo.prototype, "period", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", ModifierInfo)
], FilterInfo.prototype, "modifierInfo", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", Array)
], FilterInfo.prototype, "attributes", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], FilterInfo.prototype, "dropoff_operand", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], FilterInfo.prototype, "dropoff_attribute", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", Object)
], FilterInfo.prototype, "dropoff", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", Number)
], FilterInfo.prototype, "count", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", Number)
], FilterInfo.prototype, "avg_step_duration", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", Number)
], FilterInfo.prototype, "avg_tot_duration", void 0);
FilterInfo = __decorate([
    (0, typescript_json_serializer_1.JsonObject)(),
    __metadata("design:paramtypes", [String, String, Array, String, String, Number, Array, String, Boolean, String, String, String, ModifierInfo, String])
], FilterInfo);
exports.FilterInfo = FilterInfo;
/**
 * Represents the payload for querying different types of data.
 */
let QueryPayload = class QueryPayload extends base_1.BaseModel {
    constructor({ name, description, tableInfo, projectionInfo, filterInfo, audid, days, status, modifierInfo, sd, ed, p, journey }) {
        super();
        this.name = name;
        this.description = description;
        this.tableInfo = tableInfo;
        this.projectionInfo = projectionInfo;
        this.filterInfo = filterInfo;
        this.audid = audid;
        this.days = days;
        this.status = status;
        this.modifierInfo = modifierInfo;
        this.sd = sd;
        this.ed = ed;
        this.p = p;
        this.journey = journey;
    }
};
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], QueryPayload.prototype, "name", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], QueryPayload.prototype, "description", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", TableInfo)
], QueryPayload.prototype, "tableInfo", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", Array)
], QueryPayload.prototype, "projectionInfo", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", Array)
], QueryPayload.prototype, "filterInfo", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", ModifierInfo)
], QueryPayload.prototype, "modifierInfo", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", Number)
], QueryPayload.prototype, "days", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], QueryPayload.prototype, "audid", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], QueryPayload.prototype, "_id", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], QueryPayload.prototype, "status", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", Number)
], QueryPayload.prototype, "sd", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", Number)
], QueryPayload.prototype, "ed", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", Array)
], QueryPayload.prototype, "p", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], QueryPayload.prototype, "journey", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], QueryPayload.prototype, "fileName", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], QueryPayload.prototype, "email", void 0);
QueryPayload = __decorate([
    (0, typescript_json_serializer_1.JsonObject)(),
    __metadata("design:paramtypes", [QueryPayload])
], QueryPayload);
exports.QueryPayload = QueryPayload;
