import React, { useEffect, useMemo, useState } from "react";
import { Col, Form, Row } from "antd";
import TextInput from "components/Inputs/TextInput/TextInput";
import Selector from "components/Selector/Selector";
import PrimaryButton from "components/Buttons/PrimaryButton/PrimaryButton";
import { Link, useLocation, useNavigate } from "react-router-dom";
import CustomBreadCrumb from "components/CustomBreadCrumb/CustomBreadCrumb";
import Spinner from "components/Spinner/Spinner";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { t } from "i18next";
import useMessage from "hooks/useMessage";
import centralApi from "services/centralApi";
import { getToken } from "redux/features/app/authTokenSlice";
import { fetchEventList } from "redux/features/event/eventListSlice";
import { getJourneyTypeList } from "helperFunctions/events";
import { API_ENDPOINTS, ROUTES } from "utils/constants";
const JourneysCreate: React.FC = () => {
  const [loading, setLoading] = useState({get:false,post:false});
  const [journeyData, setJourneyData] = useState<any>({});
  const {data:eventList} = useSelector((state: any) => state.eventList);
  const navigate=useNavigate()
  const dispatch:any = useDispatch();
  const [journeyType, setJourneyType] = useState<string | undefined>(undefined);
  const loginUser = useSelector((state: any) => state.loginUser.data);
  const { appId } = useSelector((state: any) => state.activeApp);
  const { showError, showSuccess } = useMessage();
  const { state } = useLocation();
  const [form] = Form.useForm();

// Memoized computation of events filtered by journey
const eventsByJourney = useMemo(() => {
  // Initialize an empty array to hold filtered events
  let event_by_journey = eventList || [];
  // Check if the journey  is not empty
  if (journeyType) {
     // Filter the events from eventList by matching journey
    event_by_journey = eventList?.filter((event: any) => {
      return event.journey == journeyType || !event.journey;
    });
  }
  // Map filtered events to the desired structure (label, value, list)
  return event_by_journey?.map((event: any) => ({
    label: event?.displayname, // Display name of the event
    value: event?.event, // value of the event
    list: event?.list, // List of attributes
  }));
}, [journeyType,eventList]);


function getShowArray(result: any): any[] {
  const { startEvent, endEvent } = journeyData; // Assuming journeyData is globally available

  let show: any[] = [];
  let nodes: any[] = [];
  let queue: any[] = [];

  // If the result is "Failed", return an empty array or handle it as needed
  if (result === 'Failed') {
    return show;
  }

  // Add matching nodes based on links
  result.links.forEach((link: any) => {
    result.nodes.forEach((node: any) => {
      if (link.name === node.name) {
        nodes.push(node);
      }
    });
  });

  const obj = result.links || [];

  // Helper function to process the show array for only the end event
  function onlyEndEvent(obj: any[]): void {
    obj.forEach((link: any) => {
      checkPresent(link);
    });
  }

  // Function to merge two paths if they share the same parent
  function mergePaths(parent: any, journey: any): void {
    parent.value += journey.value;

    if (parent.children.length !== 0 && journey.children.length !== 0) {
      journey.children.forEach((child: any) => {
        const match = parent.children.find((e: any) => e.name === child.name);
        if (match) {
          mergePaths(match, child);
        } else {
          parent.children.push(child);
        }
      });
    }

    if (parent.children.length === 0 && journey.children.length !== 0) {
      parent.children.push(...journey.children);
    }
  }

  // Function to recursively check and clean the children
  function checkPresent(ans: any): void {
    for (let i = 0; i < ans.children.length;) {
      if (ans.children[i].children.length === 0 && ans.children[i].name !== endEvent) {
        ans.children.splice(i, 1);
      } else {
        checkPresent(ans.children[i]);
        if (ans.children[i].children.length === 0 && ans.children[i].name !== endEvent) {
          ans.children.splice(i, 1);
        } else {
          i++;
        }
      }
    }
  }

  // Function to get the journey list that starts with startEvent
  function JourneyList(obj: any[]): any[] {
    let journeyList: any[] = [];
    obj.forEach((link: any) => {
      if (link.name === startEvent) {
        journeyList.push(link);
      } else {
        queue.push(link);
        while (queue.length !== 0) {
          const journey = queue.pop();
          journey.children.forEach((child: any) => {
            if (child.name === startEvent) {
              journeyList.push(child);
            } else {
              queue.unshift(child);
            }
          });
        }
      }
    });
    return journeyList;
  }

  // Helper function to recursively adjust the 'step' value for each node and its children
  function transformData(data:any) {
    function adjustSteps(nodes:any, step = 0) {
      return nodes.map((node:any) => {
        let updatedNode = { ...node, step };
        
        // Recursively adjust the children
        if (updatedNode.children && updatedNode.children.length > 0) {
          updatedNode.children = adjustSteps(updatedNode.children, step + 1);
        }
        
        return updatedNode;
      });
    }
    return adjustSteps(data);
  }  
  // Start processing based on startEvent and endEvent
  if (startEvent === 'all') {
    if (endEvent !== '' && endEvent !== 'all') {
      onlyEndEvent(obj);
      show = obj.filter((item: any) => item.children.length > 0); // Show only items with children
    } else {
      show = obj;
      
    }
  } else if (startEvent !== '') {
    const journeyList = JourneyList(obj);
    if (journeyList.length === 0) {
      return show; // No journey available, return empty show
    }
    // Case of ONE TO ALL 
    if(endEvent === 'all'){
       show =  transformData(journeyList);
       return show
    }

    let ans = { ...journeyList[0] };
    for (let i = 1; i < journeyList.length; i++) {
      mergePaths(ans, journeyList[i]);
    }

    checkPresent(ans);
    if (ans.children.length > 0) {
      show.push(ans);
    }
  } else {
    if (endEvent !== '') {
      onlyEndEvent(obj);
      show = obj.filter((item: any) => item.children.length > 0);
    }
  }

  return show;
}

  //SAVE OR UPDATE JOURNEY
  async function saveOrUpdateJourneyData() {
    try {
      setLoading((prevState)=>({...prevState,post:true}));
      const authToken = await dispatch(getToken()).unwrap();
      const params = {
        app_id: appId,
        api_key: loginUser.api_key,
      };
      const {result}=await centralApi("GET",API_ENDPOINTS.GET_USER_JOURNEY_EVENT_DATA_URL,null,{...params,args:JSON.stringify({platform:journeyData.platform,journeyRange:journeyData.period})})
      const show = getShowArray(result); // generate the paths based on the events selected by user
      const payload = {
        ...params,
        args: {...journeyData,paths:show,id:state?.id},
        authToken,
      };
      const res = state?.id
        ? await centralApi("POST", API_ENDPOINTS.UPDATE_USER_JOURNEY_URL, payload, params)
        : await centralApi("POST", API_ENDPOINTS.SAVE_USER_JOURNEY_URL, payload, params);
        setLoading((prevState)=>({...prevState,post:false}));
      !state?.id
        ? showSuccess(t("journeyCreatedSuccessfullyMsg"))
        : res.modifiedCount
        ? showSuccess(t("journeyUpdatedSuccessfullyMsg"))
        : showError(t("somethingWrongLabel"));
    } catch (error: any) {
      setLoading((prevState)=>({...prevState,post:false}));
      showError(t("somethingWrongLabel"));
    }
    navigate(ROUTES.DASHBOARD_JOURNEYS)
    
  }

  //fetches the journey and updates loading state
  async function fetchJourneyData() {
    try {
        const params={
          app_id: appId,
          api_key: loginUser?.api_key,
          id: state?.id}
          setLoading((prevState)=>({...prevState,get:true}))
        const data = await centralApi("GET",API_ENDPOINTS.GET_USER_JOURNEY_URL,null,params);
        setJourneyData(data);
        setLoading((prevState)=>({...prevState,get:false}));
    } catch (error) {
    }
  }
  useEffect(() => {
     (async()=>{
      if (state?.id) {
        await fetchJourneyData();
      }
      await dispatch(
        fetchEventList({ api_key: loginUser.api_key, app_id: appId })
      );
     })()
  }, [state?.id, loginUser?.api_key]);

  const innerContainer = {
    backgroundColor: "var(--color-other-white)",
  };
  
  const onFormValueChange = (changedData: any) => {
    const keyName = Object.keys(changedData)[0];
    const value = Object.values(changedData)[0];
    setJourneyData({ ...journeyData, [keyName]: value });
  };
  const eventSelector=(
    <Selector
    placeholder={`${t("selectLabel")}`}
    options={
        [
            { label: "All", value: "all" },
            ...(eventsByJourney || []),
          ]
        
    }
  />
  )
  return (
    <div className="container-fluid px-1">
      <Helmet>
        <title>Appice | Journeys | {state?.id ? t("editLabel") : t("createLabel")}</title>
      </Helmet>
      <CustomBreadCrumb
      className="my-3"
        items={[
          {
            title: <Link to={ROUTES.DASHBOARD_JOURNEYS}>{t("journeysLabel")}</Link>,
          },
          {
            title: (
              <span>
                {state?.id ? t("updateLabel") : t("createLabel")}
              </span>
            ),
          },
        ]}
      />
      <div className="container-fluid pb-sm-4 p-3 " style={innerContainer}>
        {loading.get ? (
          <Spinner />
        ) : (
          <Form
            form={form}
            layout="vertical"
            initialValues={journeyData}
            onValuesChange={onFormValueChange}
            requiredMark={journeyData}
            onFinish={() => saveOrUpdateJourneyData()}
          >
            <Row className="d-flex justify-content-between align-items-center mb-3">
              <Col>
                
                  <h5 className="m-0">
                    {state?.id ? t("updateJourneyLabel") : t("createNewJourneyLabel")} 
                  </h5>
               
              </Col>
              <Col>
                <PrimaryButton loading={loading.post} type="primary" htmlType="submit">
                  {state?.id ? t("updateJourneyLabel") : t("saveJourneyLabel")}
                </PrimaryButton>
              </Col>
            </Row>
            <Row className="d-flex justify-content-between">
              <Col span={11}>
                <Form.Item
                  label={t("nameLabel")}
                  name={"journeyName"}
                  rules={[
                    {
                      required: true,
                      message: `${t("plzInputJourneyNameMsg")}`,
                    },
                  ]}
                >
                  <TextInput placeholder={t("journeyNameLabel")} />
                </Form.Item>
              </Col>
              <Col span={11}>
                <Form.Item label={t("journeyLabel")} initialValue={journeyType}>
                  <Selector
                    allowClear
                    onClear={() => setJourneyType(undefined)}
                    placeholder={`${t("selectLabel")}`}
                    onChange={async(value) => {  
                      await setJourneyData((prevData:typeof  journeyData)=>({...prevData,startEvent:undefined,endEvent:undefined}))
                      form.resetFields(["startEvent","endEvent"])
                      setJourneyType(value)
                    
                    }}
                    options={getJourneyTypeList(eventList)}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row className="d-flex justify-content-between">
              <Col span={24}>
                <Form.Item
                  label={t("descriptionLabel")}
                  name={"journeyDescription"}
                  rules={[
                    {
                      required: true,
                      message: `${t("plzInputJourneyDescriptionMsg")}`,
                    },
                  ]}
                >
                  <TextInput placeholder={t("journeyDescriptionLabel")} />
                </Form.Item>
              </Col>
            </Row>
            <Row className="d-flex justify-content-between">
              <Col span={11}>
                <Form.Item
                  label={t("startEventLabel")}
                  name={"startEvent"}
                  rules={[
                    {
                      required: true,
                      message: `${t("plzSelectStartEventMsg")}`,
                    },
                  ]}
                >
                 {eventSelector}
                </Form.Item>
              </Col>
              <Col span={11}>
                <Form.Item
                  label={t("endEventLabel")}
                  name={"endEvent"}
                  rules={[
                    { required: true, message: `${t("plzSelectEndEventMsg")}` },
                  ]}
                >
                  {eventSelector}
                </Form.Item>
              </Col>
            </Row>
            <Row className="d-flex justify-content-between">
              <Col span={11}>
                <Form.Item
                  label={t("platformLabel")}
                  name={"platform"}
                  rules={[
                    { required: true, message: `${t("plzSelectPlatformMsg")}` },
                  ]}
                >
                  <Selector
                    placeholder={`${t("selectLabel")}`}
                    options={[
                      {
                        value: "all",
                        label: "All",
                      },
                      {
                        value: "android",
                        label: "Android",
                      },
                      {
                        value: "ios",
                        label: "IOS",
                      },
                      {
                        value: "web",
                        label: "Web",
                      },
                    ]}
                  />
                </Form.Item>
              </Col>
              <Col span={11}>
                <Form.Item
                  label={t("periodLabel")}
                  name={"period"}
                  rules={[
                    { required: true, message: `${t("plzSelectPeriodMsg")}` },
                  ]}
                >
                  <Selector
                    placeholder={`${t("selectLabel")}`}
                    options={[
                      {
                        label: "Yesterday",
                        value: "yesterday",
                      },
                      {
                        label: "Last 7 days",
                        value: "last7days",
                      },
                      {
                        label: "Last 30 days",
                        value: "last30days",
                      },
                      {
                        label: "This Month",
                        value: "thismonth",
                      },
                      {
                        label: "Last Month",
                        value: "lastmonth",
                      },
                    ]}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        )}
      </div>
    </div>
  );
};

export default JourneysCreate;
