import { Divider } from "antd";
import "./CampaignActivationEmail.css";
import PrimaryButton from "../../../../components/Buttons/PrimaryButton/PrimaryButton";
import { Form } from "antd";
import TextInput from "../../../../components/Inputs/TextInput/TextInput";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import centralApi from "../../../../services/centralApi";
import { API_ENDPOINTS } from "utils/constants";
import ShowMessage from "../../../../components/ShowMessage/ShowMessage";
import Spinner from "../../../../components/Spinner/Spinner";
import { t } from "i18next";
import { getToken } from "redux/features/app/authTokenSlice";
import { CampaignActivationEmailPayload } from "utils/models";

interface CampaignActivationEmailProps {
  currentAppKey: any;
  updateCurrentAppKey: () => void;
}

const CampaignActivationEmail: React.FC<CampaignActivationEmailProps> = ({ currentAppKey, updateCurrentAppKey }) => {
  const [form] = Form.useForm();
  const dispatch: any = useDispatch();

  const [loading, setLoading] = useState(false);

  const loginUser = useSelector((state: any) => state.loginUser);
  const activeApp = useSelector((state: any) => state.activeApp);
  const [showMessage, setShowMessage] = useState<any>({
    type: "success",
    message: "",
    show: false,
  });
  const appId = activeApp.appId;
  const api_key = loginUser.data.api_key;

  const onFormSubmit = async (values: CampaignActivationEmailPayload) => {
    const authToken = await dispatch(getToken()).unwrap();
  
    // Structure the payload as required by the backend
    const payload = {
      campaignActivationEmail: {
        bcc: values.bcc,
        recipients: values.recipients,
      },
      app_id: appId,
      api_key: api_key,
      authToken,
    };
  
    // Send the payload to the backend
    saveCampaignActivationEmailSettings(payload);
    dispatch(getToken());
  };
  
  async function saveCampaignActivationEmailSettings(formData: any) {
  console.log("formData", formData)
    try {
      setLoading(true);
      // Send the payload as part of the request body
      await centralApi("POST", API_ENDPOINTS.SAVE_ACTIVATION_EMAIL_SETTINGS, formData); 
      setShowMessage({
        type: "success",
        message: t("sucessfullySavedLabel"),
        show: true,
      });
      setLoading(false);
      updateCurrentAppKey();
    } catch (error) {
      console.log(error)
      setLoading(false);
      setShowMessage({
        type: "error",
        message: t("somethingWrongLabel"),
        show: true,
      });
    }
    hideMessage();
  }

  const hideMessage = () => {
    setTimeout(() => {
      setShowMessage({ type: "success", message: "", show: false });
    }, 2000);
  };

  useEffect(() => {
    if (currentAppKey.length > 0 && currentAppKey[0]?.campaignActivationEmail) {
      const updatedFormValues = {
        recipients: currentAppKey[0].campaignActivationEmail.recipients || "",
        bcc: currentAppKey[0].campaignActivationEmail.bcc || "",
      };
      form.setFieldsValue(updatedFormValues);
    }
  }, [currentAppKey, form]);

  return (
    <div className="campaign-activation-email-container">
      <div className="mt-4 mb-4">
        <h6 className="campaign-activation-email-container-heading">
          {t("campaignActivationEmailLabel")}
        </h6>
        <Divider className="campaign-activation-email-custom-divider" />
        <div>
          <div className="mt-4 align-items-center campaign-activation-email-form">
            <div className="mb-4">
              <p>{t("recipientsLabel")}</p>
              <div className="col-sm-6">
                {showMessage.show && (
                  <ShowMessage
                    type={showMessage.type}
                    content={showMessage.message}
                  />
                )}
                {loading ? (
                  <Spinner />
                ) : (
                  <Form form={form} layout="horizontal" onFinish={onFormSubmit}>
                    <Form.Item
                      name={"recipients"}
                      rules={[
                        {
                          required: true,
                          message: t("enterValidMsg") as string,
                        },
                        {
                          pattern: /^([\w+-.%]+@[\w.-]+\.[A-Za-z]{2,4})(,\s*[\w+-.%]+@[\w.-]+\.[A-Za-z]{2,4})*$/,
                          message: t("enterValidMsg") as string,
                        },
                      ]}
                    >
                      <TextInput placeholder="Enter comma-separated emails" />
                    </Form.Item>

                    <p>{t("bccLabel")}</p>
                    <Form.Item
                      name={"bcc"}
                      rules={[
                        {
                          required: false,
                        },
                        {
                          pattern: /^([\w+-.%]+@[\w.-]+\.[A-Za-z]{2,4})(,\s*[\w+-.%]+@[\w.-]+\.[A-Za-z]{2,4})*$/,
                          message: t("enterValidMsg") as string,
                        },
                      ]}
                    >
                      <TextInput placeholder="Enter comma-separated emails" />
                    </Form.Item>

                    <PrimaryButton
                      size="middle"
                      type="primary"
                      htmlType="submit"
                      style={{ border: "none", width: "70px" }}
                    >
                      {t("saveLabel")}
                    </PrimaryButton>
                  </Form>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CampaignActivationEmail;