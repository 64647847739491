import { t } from "i18next";
import { PlatformEnum } from "utils/enums";

export const acqSrcChurnModifiedData = (
  data: any,
  filter: string,
  churn: boolean
) => {
  let newAcquisition = 0;
  let repeatAcquisition = 0;
  let churnModifiedData: any = [];
  let churnTableModifiedData: any = {};
  let churnTotal = 0;

  // Define the available platforms
  const availablePlatforms = [PlatformEnum.ANDROID, PlatformEnum.IOS, PlatformEnum.WEB];

  // If filter is "all", include all platforms, otherwise filter based on the provided filter
  const platformKeys = filter === PlatformEnum.ALL || !filter
    ? availablePlatforms
    : [filter]; // Uses the available platforms if 'all' or no filter is provided

  // Check if filter is valid (i.e., belongs to one of the available platforms)
  const validPlatforms = platformKeys.filter((key) =>
    availablePlatforms.includes(key)
  );
  if (validPlatforms.length === 0) {
    console.error("Invalid platform filter provided.");
    return;
  }

  // If not churn, calculate newAcquisition and repeatAcquisition
  if (!churn) {
    validPlatforms.forEach((key) => {
      // Initialize platform-specific counters for newAcquisition and repeatAcquisition
      let platformNewAcquisition = 0;
      let platformRepeatAcquisition = 0;

      // Iterate over each entry for the given platform
      data[key]?.forEach((entry: any) => {
        const install = entry?.self?.in;
        const repeatInstall = entry?.self?.ri;

        // Add the `in` value to platform-specific `newAcquisition` and `ri` value to `repeatAcquisition`
        platformNewAcquisition += isNaN(install) ? 0 : install;
        platformRepeatAcquisition += isNaN(repeatInstall) ? 0 : repeatInstall;
      });

      // Add platform-specific data to the modified table data
      churnTableModifiedData[key] = {
        newAcquisition: platformNewAcquisition,
        repeatAcquisition: platformRepeatAcquisition,
      };

      // Accumulate the total newAcquisition and repeatAcquisition for all platforms
      newAcquisition += platformNewAcquisition;
      repeatAcquisition += platformRepeatAcquisition;
    });

    // Format the data with the desired table structure
    return {
      data: [
      
        {
          id: PlatformEnum.ANDROID,
          label: t("androidLabel"),
          value: churnTableModifiedData[PlatformEnum.ANDROID]?.newAcquisition || 0,
        },
        {
          id: PlatformEnum.IOS,
          label: t("iosLabel"),
          value: churnTableModifiedData[PlatformEnum.IOS]?.newAcquisition || 0,
        },
        {
          id: PlatformEnum.WEB,
          label: t("webLabel"),
          value: churnTableModifiedData[PlatformEnum.WEB]?.newAcquisition || 0,
        },
      ],
      tableData: {
        newAcquisition: newAcquisition,
        repeatAcquisition: repeatAcquisition,
        android: churnTableModifiedData[PlatformEnum.ANDROID]?.newAcquisition || 0,
        ios: churnTableModifiedData[PlatformEnum.IOS]?.newAcquisition || 0,
        web: churnTableModifiedData[PlatformEnum.WEB]?.newAcquisition || 0,
        total: newAcquisition + repeatAcquisition,
      },
      total: newAcquisition + repeatAcquisition,
    };
  } else {
    // If churn is true, calculate churn data for each platform
    validPlatforms?.forEach((key) => {
      let tempObj: any = {};
      const uninstall = isNaN(data[key]?.[0]?.self?.un)
        ? 0
        : data[key]?.[0]?.self.un;
      const ruinstall = isNaN(data[key]?.[0]?.self?.ru)
        ? 0
        : data[key]?.[0]?.self.ru;

      tempObj["value"] = uninstall + ruinstall;
      tempObj["label"] = key;
      tempObj["id"] = key;

      churnTableModifiedData[key] = tempObj["value"];
      churnTotal += tempObj["value"];

      churnModifiedData.push(tempObj);
    });

    return {
      data: churnModifiedData,
      tableData: {
        ...churnTableModifiedData, // Add churn data for each platform
        total: churnTotal,
      },
      total: churnTotal,
    };
  }
};
export const locationModifiedData = (
  cityData: any,
  countryData: any,
  filter: "country" | "city",
  churn: boolean,
  numberOfRecords = 6
) => {
  try {
    const data = filter == "city" ? cityData : countryData;
    let modifiedData = [];
    if (churn) {
      modifiedData = churnLocationModifiedData(data, filter);
    } else {
      modifiedData = data?.map((item: any) => {
        return { location: item[0], cur: item[1], prev: item[2] };
      });
    }
    return modifiedData
  } catch (error) {
    console.log(error);
  }
  function churnLocationModifiedData(data: any, filter: "city" | "country") {
    let uniqueKeyObject: any = {};
    data.forEach((item: any) => {
      const key: string = filter == "city" ? item.cty : item.cc;
      if (uniqueKeyObject[key]) {
        uniqueKeyObject[key]["cur"] += item.total;
      } else {
        uniqueKeyObject[key] = { location: key, cur: item.total };
      }
    });
    return Object.values(uniqueKeyObject).sort(
      (a: any, b: any) => b.cur - a.cur
    );
  }
};
export const acquisitionOSModifiedData = (
  data: any,
  filter: string,
  numberOfRecords: number
) => {
  let modifiedData: any = [];
  let versions = Object.keys(data ? data : {});
  let allTotal: number = 0;
  versions.forEach((key) => {
    let total = 0;
    let platform = "";
    data[key].forEach((v: any, index: number) => {
      if (!index) {
        platform = v.p;
      }
      if (platform == filter || filter == PlatformEnum.ALL) {
        total += v.total;
      }
    });
    allTotal += total;
    if (platform == filter || filter == PlatformEnum.ALL) {
      modifiedData.push({ id: key, label: key, value: total, platform });
    }
  });
  modifiedData = modifiedData
    .sort((a: any, b: any) => b.value - a.value)
    .slice(0, numberOfRecords);
  return { versions: modifiedData, total: allTotal };
};
export const acqChurnAppVersionModifiedData = (
  data: any,
  filter: string,
  churn: boolean,
  numberOfRecords: number
) => {
  let mData: any = [];
  let total = 0;
  if (churn) {
    let uniqueKeyObject: any = {};
    data.forEach((version: any) => {
      if(version.p==filter || filter==PlatformEnum.ALL){

        if (uniqueKeyObject[version.av]) {
          total+=version.total
          uniqueKeyObject[version.av].value += version.total;
        } else {
          uniqueKeyObject[version.av] = {
            id: `${version.p + " " + version.av}`,
            label: `${version.p + " " + version.av}`,
            value: version.total,
          };
        }
      }
    });
    mData=Object.values(uniqueKeyObject)

  }
  else{

    
    const platformKeys = data
    ? filter && filter != PlatformEnum.ALL
    ? [filter]
    : Object.keys(data)
    : [];
    const processedVersions: any = {};
    platformKeys.forEach((key) => {
      data[key].forEach(({ x, value }: any) => {
        total += value;
        if (processedVersions[x]) {
          processedVersions[x].value += value;
        } else {
          processedVersions[x] = { id: key+" "+x, label: key+" "+x, value }
        }
      });
      mData = Object.values(processedVersions);
      
    });
  }
  mData = mData
      .sort((a: any, b: any) => b.value - a.value)
      .slice(0, numberOfRecords);
  return { versions: mData, total };
};

export const dayWiseModifiedData = (
  { newData, repeatData }: any,
  filter: string,
  dayOrHour: "d" | "h"
) => {
  
  let finalInputArray = [...newData, ...repeatData];
  var aggregatedData: any = {};

  finalInputArray.forEach((item: any) => {
    if (filter === PlatformEnum.ALL || item.p === filter) {
      // Initialize the aggregation structure for this hour if it doesn't exist yet
      if (!aggregatedData[item[dayOrHour]]) {
        aggregatedData[item[dayOrHour]] = {
          android: { new: 0, repeat: 0 },
          ios: { new: 0, repeat: 0 },
          web: { new: 0, repeat: 0 }
        };
      }

      // Add data to the correct platform's new/repeat count
      const platform = item.p; // android, ios, web
      const isNew = item.isnew;
      const total = item.total;

      if (isNew) {
        // Add `new` values
        aggregatedData[item[dayOrHour]][platform].new += total;
      } else {
        // Add `repeat` values
        aggregatedData[item[dayOrHour]][platform].repeat += total;
      }
    }
  });

  // Transform the aggregated data into the format suitable for your chart
  const output = Object.keys(aggregatedData).map((hour) => {
    const { android, ios, web } = aggregatedData[hour];
    const totalNew = android.new + ios.new + web.new;
    const totalRepeat = android.repeat + ios.repeat + web.repeat;

    return {
      name: hour,
      android: android.new + android.repeat,
      ios: ios.new + ios.repeat,
      web: web.new + web.repeat,
      new: totalNew,
      repeat: totalRepeat
    };
  });

  // Return the data in the format required
  return output;
};
