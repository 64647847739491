import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { STATUS } from "../../constant";
import centralApi from "../../../services/centralApi";
import { API_ENDPOINTS } from "utils/constants";
export const fetchCustomVars = createAsyncThunk(
  "customMetaVars/fetchCustomVars",
  async ({api_key, app_id,all}:any) => {
    const params = {
      api_key,
      app_id,
      all,
      method: "get_metacustomvariables",
    };

    return centralApi(
      "GET",
      API_ENDPOINTS.GET_CUSTOM_VARIABLES,
      null,
      params
    ).then(({ result }) => {
      return result
      
    });
  }
);
const initialState:any= {
  data: [],
  rawData:[],
  status: STATUS.IDLE,
}
const customMetaVarsSlice = createSlice({
  name: "customMetaVars",
 initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchCustomVars.pending, (state) => {
      state.status = STATUS.LOADING;
    });
    builder.addCase(fetchCustomVars.fulfilled, (state,action) => {
      state.status = STATUS.IDLE;
      state.rawData=action.payload
      const modifiedResult: { label: string; value: string; type: string;isVisible:boolean}[] = [];

      // Use a Set to keep track of unique `value` properties
      const uniqueValues = new Set<string>();
      
      action.payload?.forEach((item: { name: string; type: string; displayName: string ; is_visible:boolean}) => {
        // Check if `item` has `, `type`, and `name`
        if (item.type && item.name) {
          // Only add the item if its `value` (name) hasn't been added yet
          if (!uniqueValues.has(item.name)) {
            uniqueValues.add(item.name);  // Mark `name` as seen
      
            // Push the item into `modifiedResult`
            modifiedResult.push({
              label: item.displayName || item.name,
              value: item.name,
              type: item.type,
              isVisible:item.is_visible
            });
          }
        }
      });
      state.data=modifiedResult
    });
    builder.addCase(fetchCustomVars.rejected, (state) => {
      state.status = STATUS.ERROR;
    });
  },
  reducers: {
    setField(state,action){
      const updatedList = [...state.rawData];
      const index=action.payload.index
      const key=action.payload.key
      const value=action.payload.value
      updatedList[index][key] = value;
      state.rawData=updatedList
    }
  },
});
export const {setField}=customMetaVarsSlice.actions
export default customMetaVarsSlice.reducer;

