import { Divider } from "antd";
import "./KillSwitch.css";
import PrimaryButton from "../../../../components/Buttons/PrimaryButton/PrimaryButton";
import { Form } from "antd";
import Selector from "../../../../components/Selector/Selector";
import TextInput from "../../../../components/Inputs/TextInput/TextInput";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import centralApi from "../../../../services/centralApi";
import Spinner from "../../../../components/Spinner/Spinner";

import { t } from "i18next";
import { getToken } from "redux/features/app/authTokenSlice";
import useMessage from "hooks/useMessage";

const KillSwitch: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const dispatch: any = useDispatch();
  const killSwitchObj =  useSelector((state: any) => state.featuresSetting?.data[0]|| {});
  const {showError,showSuccess}=useMessage()

const [killSwitchData, setKillSwitchData] = useState(killSwitchObj?.kill_switch || { state: "0", android: [{ v: "" }], ios: [{ v: "" }], web: [{ v: "" }] });

  const [labelsHidden, setlabelsHidden] = useState(true);
  const loginUser = useSelector((state: any) => state.loginUser);
  const activeApp = useSelector((state: any) => state.activeApp);
  const authToken = useSelector((state: any) => state.authToken.token);
  
  const appId = activeApp.appId;
  const api_key = loginUser.data.api_key;
  
  const onFormSubmit = async (values: any) => {
    const { android, ios, web, state } = values;
  
    const payload = {
      args: JSON.stringify({
        ...(android && android !== "" ? { android: [{ v: android }] } : {}),
        ...(ios && ios !== "" ? { ios: [{ v: ios }] } : {}),
        ...(web && web !== "" ? { web: [{ v: web }] } : {}),
        baseurl: "https://a.appice.io",
        state,
        app_id: appId,
      }),
  
      app_id: appId,
      api_key: api_key,
      authToken,
    };
  
    try {
      // Call the function to save the settings
      await saveKillSwitchSetting(payload);
  
      // After save, update the killSwitchData state with the new values
      setKillSwitchData({
        ...killSwitchData,
        state: state,
        android: [{ v: android }],
        ios: [{ v: ios }],
        web: [{ v: web }],
      });
  
      // Dispatch token update after saving the settings (if needed)
      await dispatch(getToken());
  
      // Optionally, you can reload the data here if needed
  
      // Notify the user that the settings were saved successfully
      showSuccess(t("sucessfullySavedLabel"));
    } catch (error) {
      // Show error message if saving fails
      showError(t("somethingWrongLabel"));
    }
  };
  
  async function saveKillSwitchSetting(formData: any) {
    const url: string = `/i/apps/savekillSwitchSetting`;

    try {
      setLoading(true);
     await centralApi("POST", url, formData, null);
    //  await getKillSwitchState();
    
    showSuccess(t("sucessfullySavedLabel"))

    setLoading(false);
  } catch (error) {
    showError(t("somethingWrongLabel"))
setLoading(false)
  }
}
  
 
  useEffect(() => {
    handleDisableButton(killSwitchData?.state);
  }, [killSwitchData?.state]);
  const handleDisableButton = (value: string) => {
    if (value === "1") {
      setlabelsHidden(false);
    }
    if (value === "0") {
      setlabelsHidden(true);
    }
  };
  const androidValidationRules = labelsHidden
    ? []
    : [{ required: true, message: "Please Provide Version Details!" }];

  return (
    <div className="kill-switch-setting-container">
      <div className="mt-4 mb-4  ">
        <h6 className="kill-switch-setting-container-heading">
         {t("killSwitchSettingLabel")}
        </h6>
        <Divider className="kill-switch-setting-custom-divider" />

        <div>
          <div className=" col-sm-8 mt-4 align-items-center kill-switch-setting-form ">
            
            {loading ? (
              <Spinner />
            ) : (
              <Form
                layout="horizontal"
                onFinish={onFormSubmit}
                initialValues={{
                  state: killSwitchData?.state || "0",
                  android:
                    killSwitchData?.android?.length > 0
                      ? killSwitchData.android[0]?.v
                      : "",
                  ios:
                    killSwitchData?.ios?.length > 0
                      ? killSwitchData?.ios[0]?.v
                      : "",
                  web:
                    killSwitchData?.web?.length > 0
                      ? killSwitchData?.web[0]?.v
                      : "",
                }}
              >
                <Form.Item label="Kill Switch" name={"state"}>
                  <Selector
                    onChange={(value) => {
                      handleDisableButton(value);
                    }}
                    size="middle"
                    style={{ width: "100%" }}
                    defaultValue={"0"}
                    options={[
                      {
                        value: "0",
                        label: "Disable",
                      },
                      {
                        value: "1",
                        label: "Enable",
                      },
                    ]}
                  />
                </Form.Item>
                <Form.Item
                  label="Android"
                  name={"android"}
                  rules={androidValidationRules}
                >
                  <TextInput disabled={labelsHidden} />
                </Form.Item>
                <Form.Item label="IOS" name={"ios"}>
                  <TextInput disabled={labelsHidden} />
                </Form.Item>
                <Form.Item label="Web" name={"web"}>
                  <TextInput disabled={labelsHidden} />
                </Form.Item>

                <div className="">
                  <PrimaryButton
                    size="middle"
                    type="primary"
                    htmlType="submit"
                    style={{ border: "none", width: "70px" }}
                  >
                    Save
                  </PrimaryButton>
                </div>
              </Form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default KillSwitch;
