import { Divider, Form } from "antd";
import { useForm } from "antd/es/form/Form";
import PrimaryButton from "components/Buttons/PrimaryButton/PrimaryButton";
import CustomDrawer from "components/CustomDrawer/CustomDrawer";
import { t } from "i18next";
import { FC, useEffect, useState } from "react";
import CustomCheckbox from "components/CustomCheckbox/CustomCheckbox";
import Selector from "components/Selector/Selector";
import { removeTrailingNumber } from "helperFunctions/common";
import { BOOLEAN_OPTIONS } from "utils/constants/selectorOptions";
import { E_OperatorEnum, OrderByEnum } from "utils/enums";
import { useSelector } from "react-redux";
interface Criteria {
  operand: string;
  status: number;
  category: string;
  since: { have: string };
}
[];
interface AttributeColumn {
  index?: string;
  col: string; // Column name or identifier
  alias: string; // Alias or alternative name
  type?: "event" | "attribute" | "value"; // Type of column (event, attribute, value)
  event?: string; // Optional: Event name associated with the column (for attribute and value types)
}

interface FunnelExportProps {
  v2?:boolean;
  funnelExportState: boolean;
  setFunnelExportState: (value: boolean) => void; // Optional setter function that accepts a boolean value
  totalSteps?: number;
  handleCountAudienceReach: (
    params: any) => void; // Callback function to handle form submission
    statsData?: any;
}

interface FunnelV2ExportProps {
  funnelExportState: boolean;
  setFunnelExportState: (value: boolean) => void;
  handleCountAudienceReach: (
    params: any,
  ) => void;
  statsData: any;
}

const FunnelExport: FC<FunnelExportProps> = ({
  v2,
  funnelExportState,
  setFunnelExportState,
  totalSteps,
  handleCountAudienceReach,
  statsData,
}) => {
  return v2 ? (
    <FunnelExportV2
      funnelExportState={funnelExportState}
      setFunnelExportState={setFunnelExportState}
      handleCountAudienceReach={handleCountAudienceReach}
      statsData={statsData}
    />)
    :(
      <FunnelExportV1
        funnelExportState={funnelExportState}
        setFunnelExportState={setFunnelExportState}
        totalSteps={totalSteps}
        handleCountAudienceReach={handleCountAudienceReach}
        statsData={statsData}
      />
    );
};
export default FunnelExport;

const FunnelExportV1: FC<FunnelExportProps> =({
  funnelExportState,
  setFunnelExportState,
  totalSteps,
  handleCountAudienceReach,
  statsData,
})=>{
  const [form] = useForm();

  const [selectedSteps, setSelectedSteps] = useState<any>({});
  const [selectedAttributes, setSelectedAttributes] = useState({});
  const [sortAttribute, setSortAttribute] = useState<string[]>([]);
  const [columns, setColumns] = useState<string[]>([]);
const [selectedSortAttribute, setSelectedSortAttribute] = useState({});
  const handleCheckChange = (step: number, value: string) => {
    //select the steps
    setSelectedSteps((prevSelectedSteps: any) => {
      const newSelectedSteps = { ...prevSelectedSteps };

      if (prevSelectedSteps[step] === value) {
        // If the step is being deselected, also deselect all subsequent steps
        for (let i = step; i <= totalSteps; i++) {
          newSelectedSteps[i] = null;
        }
      } else {
        // If the step is being selected
        for (let i = 1; i <= step; i++) {
          if (value === "hn") {
            newSelectedSteps[i] = i === step ? "hn" : "h";
          } else {
            newSelectedSteps[i] = value;
          }
        }
      }
      // Remove null values
      const removedNullValue = Object.fromEntries(
        Object.entries(newSelectedSteps).filter(([_, v]) => v !== null)
      );

      // After updating the steps, update the attributes
      updateSelectedAttributes(removedNullValue, statsData);

      return removedNullValue;
    });
  };
  const updateSelectedAttributes = (
    newSelectedSteps: any,
    statsData: any
  ) => {
    setSelectedAttributes(() => {
      const updatedAttributes: {
        [event: string]: { label: string; value: string; index: string }[];
      } = {};

      for (const stepIndexStr in newSelectedSteps) {
        const stepIndex = parseInt(stepIndexStr, 10);
        if (!isNaN(stepIndex) && newSelectedSteps[stepIndexStr] !== null) {
          const event = statsData[stepIndex - 1]; // statsData is 0-based
          const eventAttributes =
            event?.events?.list.map((attr: any) => attr.name) || [];
          const eventName = removeTrailingNumber(event?.name);
          const attributeName = `${eventName}_Date`;

          // Initialize the event group if not already
          if (!updatedAttributes[eventName]) {
            updatedAttributes[eventName] = [];
          }

          // Add event name attribute
          updatedAttributes[eventName].push({
            label: attributeName,
            value: `${attributeName}_${stepIndex - 1}`, // added for events
            index: `${stepIndex - 1}`,
          });

          // Add  attributes
          eventAttributes.forEach((attr: any) => {
            updatedAttributes[eventName].push({
              label: attr,
              value: `${attr}_${stepIndex - 1}`, // value for attributes
              index: `${stepIndex - 1}`,
            });
          });
        }
      }

      return updatedAttributes;
    });
  };

  const getColumnOptions = () => {
    return Object.entries(selectedAttributes).flatMap(([, attrs]) =>
      Array.isArray(attrs)?
    attrs.map((attr: any) => ({
        label: attr.label,
        value: attr.value,
      })):[]
    );
  };
  // Function to find an option by its value
  const sortAttributeValues = (value: any) => {
    const options = getAttributeOptions();
    const data = options.find((option) => option.value === value);
    setSelectedSortAttribute(data);
  };

  //get attribute option for select attribute
  const getAttributeOptions = () => {
    if (Object.entries(sortAttribute).length > 0) {
      return Object.entries(sortAttribute).flatMap(([event, attributes]) =>
        Array.isArray(attributes)?
      attributes.map((attr) => ({
          index: attr.index, // Use the index from the attribute
          name: event, // Use the event key as the name
          label: attr.label, // Use the label from the attribute
          value: attr.value, // Use the value from the attribute
        })):[]
      );
    } else {
      return Object.entries(columns).flatMap(([, attributes]) =>
        Array.isArray(attributes)?    attributes.map((attr) => ({
          label: attr.label, // Use the label from the attribute
          value: attr.value, // Use the value from the attribute
          index: attr.index, // Use the index from the attribute

        })):[]
      );
    }
  };

  const onSubmit = (values: any) => {
    const { sortBy,selectedColumns } = values;

    //get the maximun no of steps selected

    const maxSelectedStepIndex = Math.max(
      ...Object.keys(selectedSteps).map(Number)
    );
    //Generate criteria based on selected steps
    const criteria = []; //criteria payload
    for (let i = 1; i <= maxSelectedStepIndex; i++) {
      const stepIndex: any = i.toString();
      const operand = removeTrailingNumber(statsData[i - 1]?.name); //  access statsData (0-based) operand put the value of events upto the tootal number of steps selected
      const status = selectedSteps[stepIndex] === "h" ? 1 : 0; // check if have selected then 1 if have not then 0
      const since = {
        have: selectedSteps[stepIndex], // put the value whether have not or have selected if have then "h"
      };

      criteria.push({
        operand,
        status,
        category: "Events",
        since,
      });
    }
    const attrColumns = selectedColumns && extractAttributeColumns(); // get the attribute columns

    handleCountAudienceReach(
      {
        criteria,
        selectedSortAttribute,
        sortBy,
        attrColumns,
      }
    );
    setFunnelExportState(false);
  };
  const extractAttributeColumns = () => {
    const attrColumns: AttributeColumn[] = [];
    //check if sortAttribute length >0 and does not contain only colunm key 
      if (Object.keys(sortAttribute).length > 0 && !('columns' in sortAttribute)) {

      // Map sortAttributes to attrColumns with step index
      Object.entries(sortAttribute).forEach(([eventName, attributes]) => {
        const baseAlias = removeTrailingNumber(eventName);
        //get the index of attributes and its associated event
        const indexes = new Set(
         Array.isArray(attributes)? attributes.map((attr) => parseInt(attr.index, 10)):[]
        );
        const filteredAttributes = Array.isArray(attributes)?attributes.filter(
          (attr) => !attr.label.includes("_Date")
        ):[];
        // Check if there are any attributes for the event
        if (filteredAttributes.length > 0) {
          // Add step key and date columns
          indexes.forEach((index: any) => {
            const stepNum = index + 1; // Increment the index by 1 for column naming

            attrColumns.push(
              {
                col: `step${stepNum}key`,
                alias: baseAlias,
                type: "event",
              },
              
              {
                col:
                  `step${stepNum}date`
                    ,

                alias: `${baseAlias}_Date`,
              },
             
            );

            // Add attributes and values from sortAttributes
            filteredAttributes.forEach((attr:any) => {
              if (parseInt(attr.index, 10) === index) {
                // Only include attributes with the current index

                const attributeAlias = `${baseAlias}_${attr.label
                  .split(" ")
                  .join("")}`;

                attrColumns.push(
                  {
                    col: `'${attr.label}'`,
                    alias: attributeAlias,
                    type: "attribute",
                    event: baseAlias,
                    index: attr.index,
                  },
                  {
                    col:`step${stepNum}Segment->> '${attr.label}'`,
                    alias: `${attributeAlias}Value`,
                    type: "value",
                    index: attr.index,
                  }
                );
              }
            });
          });
        }
      });
    } else {
      //check the selected attributes in that index

      for (let index = 0; index < Object.keys(selectedSteps).length; index++) {
        const pos = index + 1; // To create step1, step2, etc.
      
        attrColumns.push(
          {
            col: `step${pos}key`,
            alias: removeTrailingNumber(statsData[index]?.name || ''),
            type: "event",
          },
          {
            col: `step${pos}date`,
            alias: `${removeTrailingNumber(statsData[index]?.name || '')}_Date`,
          }
        );
      }
      
     }
    // )

    return attrColumns;
  };
  interface Attribute {
    label: string;
    value: string;
    index: number;
  }
  
  interface AttributesMap {
    [key: string]: Attribute[];
  }
  const mapSelectedValuesToAttributes = (selectedValues:any) => {
    const groupedAttributes: AttributesMap = {};
    const columns: AttributesMap = {};
    // Initialize flags to track the presence of _Date and other attributes
     let containsDate;
    let containsOtherAttributes = false;

    // First pass: Determine if _Date or other attributes are present
    selectedValues.forEach((selectedValue:any) => {
      // Check if the selectedValue includes _Date
      if (selectedValue.includes("_Date")) {
        containsDate = true;
      } else {
        containsOtherAttributes = true;
      }
    });

    // Second pass: Map attributes based on presence of _Date and other attributes
    selectedValues.forEach((selectedValue:any) => {
      let foundAttribute = null;
      let eventName = "";

      // Find the selected value within the selectedAttributes
      for (const [event, attributes] of Object.entries(selectedAttributes)) {
        foundAttribute =Array.isArray(attributes)?  attributes.find(
          (attr) => attr.value === selectedValue
        ):[];
        if (foundAttribute) {
          eventName = event;
          break;
        }
      }

      if (foundAttribute) {
        // Place _Date values in columns only if no other attributes are present
        if (selectedValue.includes("_Date")) {
          if (!containsOtherAttributes) {
            if (!columns[eventName]) {
              columns[eventName] = []; // Initialize array if it doesn't exist
            }
            columns[eventName].push({
              label: foundAttribute.label,
              value: foundAttribute.value,
              index: foundAttribute.index,
            });
          } else {
            // Place _Date attributes in groupedAttributes if other attributes are present
            if (!groupedAttributes[eventName]) {
              groupedAttributes[eventName] = [];
            }
            groupedAttributes[eventName].push({
              label: foundAttribute.label,
              value: foundAttribute.value,
              index: foundAttribute.index,
            });
          }
        } else {
          // Save other attributes
          if (!groupedAttributes[eventName]) {
            groupedAttributes[eventName] = [];
          }
          groupedAttributes[eventName].push({
            label: foundAttribute.label,
            value: foundAttribute.value,
            index: foundAttribute.index,
          });
        }
      }
    });

    // Return both columns and sortAttributes separately
    return {
      columns,
      sortAttributes: groupedAttributes,
    };
  };
  useEffect(() => {
    form.resetFields();
    setSelectedSteps({});

    form.resetFields(["checkboxGroup"]);
  }, [funnelExportState]); 
  return  (
    <CustomDrawer
      footer={
        <div>
          <PrimaryButton type="primary" htmlType="submit" onClick={() => form.submit()}>
            {t("saveLabel")}
          </PrimaryButton>
        </div>
      }
      open={funnelExportState}
      placement="right"
      closable={false}
      onClose={() => setFunnelExportState(false)}
    >
      <div className=" mt-1 funnel-export-container">
        <h5 className="fw-semibold">Export funnel data</h5>
        {totalSteps > 0 && (
          <Form
            colon={false}
            labelAlign="left"
            labelCol={{ flex: "25px" }}
            layout="vertical"
            form={form}
            onFinish={onSubmit}
          >
            <Divider />

            <Form.Item name="checkboxGroup">
              <div>
                {statsData?.map((event: any, index: number) => (
                  <div key={index}>
                    <CustomCheckbox
                      className="mb-2"
                      value={`step${index + 1}+h`}
                      checked={selectedSteps[index + 1] === "h"}
                      onChange={() => handleCheckChange(index + 1, "h")}
                      label={`Users who have done Step ${
                        index + 1
                      } of ${totalSteps}`}
                    />
                    {index + 1 === totalSteps && totalSteps > 1 && (
                      <CustomCheckbox
                        className="mb-2"
                        value={`step${index + 1}+hn`}
                        checked={selectedSteps[index + 1] === "hn"}
                        onChange={() =>
                          handleCheckChange(index + 1, "hn")
                        }
                        label={`Users who have not done Step ${
                          index + 1
                        } of ${totalSteps}`}
                      />
                    )}
                  </div>
                ))}
              </div>
            </Form.Item>

            <Form.Item name="selectedColumns">
              <Selector
                mode="multiple"
                options={getColumnOptions()}
                placeholder="Select columns"
                // onChange={handleSortAttributeChange}
                onChange={(selectedValues) => {
                  const groupedAttributes =
                    mapSelectedValuesToAttributes(selectedValues);
                  setSortAttribute(groupedAttributes.sortAttributes);
                  setColumns(groupedAttributes.columns);
                }}
              />
            </Form.Item>

            <Form.Item name="sortAttribute">
              <Selector
                options={getAttributeOptions()}
                placeholder="Sort attribute"
                onChange={(selectedValue) => {
                  sortAttributeValues(selectedValue);
                  // Update state with the sort attribute object
                }}
              />
            </Form.Item>
            <Form.Item name="sortBy" initialValue="DESC">
              <Selector
                options={[
                  {
                    label: "ASC",
                    value: "ASC",
                  },
                  {
                    label: "DESC",
                    value: "DESC",
                  },
                ]}
                placeholder="Sort by"
              />
            </Form.Item>
          </Form>
        )}
      </div>
    </CustomDrawer>
    )
};

const FunnelExportV2: FC<FunnelV2ExportProps> = ({
  funnelExportState,
  setFunnelExportState,
  handleCountAudienceReach,
}) => {
  const [form] = useForm();
  const statsData = useSelector((state: any) => state.queryPayload.data);
  const totalSteps =(statsData  && statsData?.filterInfo.length > 0) ? statsData.filterInfo.length : 0;
  const [selectedSteps, setSelectedSteps] = useState<boolean[]>([]);
  const [notDoneStep,setNotDoneStep]= useState<boolean>(BOOLEAN_OPTIONS.FALSE);
  const [sortAttributeOptions,setSortAttributeOptions]= useState<[]>([])

  const handleCheckChange = (index: number, notDoneLastStep?: boolean) => {
      const updatedSteps = [...selectedSteps];
    
      if (notDoneLastStep) {
        setNotDoneStep(BOOLEAN_OPTIONS.TRUE); // Mark "not done" checkbox as checked
        // If "Users who have not done Step X of X" is checked:
        updatedSteps[index] = BOOLEAN_OPTIONS.FALSE; // Uncheck last step
        for (let i = 0; i < index; i++) {
          updatedSteps[i] = BOOLEAN_OPTIONS.TRUE; // Check all previous steps
        }
      } else {
        setNotDoneStep(BOOLEAN_OPTIONS.FALSE); // Reset "not done" checkbox when any step is checked
        if (updatedSteps[index]) {
          // Uncheck this step and all after it
          for (let i = index; i < updatedSteps.length; i++) {
            updatedSteps[i] = BOOLEAN_OPTIONS.FALSE;
          }
        } else {
          // Check this step and all previous steps
          for (let i = 0; i <= index; i++) {
            updatedSteps[i] = BOOLEAN_OPTIONS.TRUE;
          }
        }
      }
    
      setSelectedSteps(updatedSteps);
    };

  /**
   * Generates dropdown options for selected columns and sort attributes based on selected steps.
   * 
   * @param {any} statsData - The dataset containing filter information.
   * @param {boolean[]} selectedStep - An array representing selected steps, where `true` means the step is selected.
   * @returns {Array<{ label: string, value: string }>} - An array of options with `label` and `value` for dropdowns.
   */
  const getOptionsFromSelectedSteps = (statsData: any, selectedStep: boolean[]) => {

    const includedIndices = selectedStep.map((selected, index) => 
      selected || (notDoneStep && index === selectedStep.length - 1)
    );
    return statsData.filterInfo
      .filter((_: any, index: any) => includedIndices[index]) // Filter only the selected steps
      .flatMap((filter: any, stepIndex: number) => {
        const options:any = [];
  
        // Loop through attributes and add each operand as an option
        filter?.attributeList?.forEach((attr: any, attrIndex: number) => {
          options.push({
            label: attr.operand,
            value: `${attr.operand}_${stepIndex}_${attrIndex}`, // Ensure uniqueness
            originalValue: attr.operand, // Store original values
          });
        });
  
        return options;
      });
  };
  


    // Generate dropdown options for selected columns 
    const selectedColumnsOptions = getOptionsFromSelectedSteps(statsData, selectedSteps);




    //submitting values while saving
    const onSubmit = (values: any) => {
      if (!selectedSteps[0]) {
        form.setFields([
          {
            name: "checkboxGroup",
            errors: [t("stepValidationLabel")],
          },
        ]);
        return; // Stop execution if the first step is not selected
      }
    
      // Count the number of 'false' values
      const falseCount = selectedSteps.filter((step) => !step).length;
    
      // Clone statsData to avoid mutating the original object
      let updatedStatsData = { ...statsData, filterInfo: [...statsData.filterInfo] };
    
    
      // If more than one false, filter only 'true' indices
      if (falseCount > 1) {
        updatedStatsData.filterInfo = updatedStatsData.filterInfo.filter((_:any, index:number) => selectedSteps[index]);
      } else {
        // Otherwise, update e_operator normally
        updatedStatsData.filterInfo = updatedStatsData.filterInfo.map((filter: any, index: number) => {
          if (index === 0) return filter;
          const e_operator = selectedSteps[index] ? E_OperatorEnum.AND : notDoneStep ? E_OperatorEnum.OR : undefined;
          return e_operator ? { ...filter, e_operator } : null;
        }).filter((item: any) => item !== null);
      }
    
      // Handle projectionInfo based on selected columns
      if (values.selectedColumns && values.selectedColumns.length > 0) {
        const projectionMap: Record<string, { precedence: number; type: string; operand: string; values: string[] }> = {};
    
        values.selectedColumns.forEach((selectedValue: string) => {
          const [originalValue, stepIndexStr, _] = selectedValue.split('_');
          const stepIndex = parseInt(stepIndexStr);
          const filterInfo = updatedStatsData.filterInfo[stepIndex];
          
          if (filterInfo) {
            const key = `${stepIndex}`;
            if (!projectionMap[key]) {
              projectionMap[key] = {
                precedence: filterInfo.precedence,
                type: "string", 
                operand: filterInfo.operand,
                values: []
              };
            }
            projectionMap[key].values.push(originalValue);
          }
        });
    
        updatedStatsData.projectionInfo = Object.values(projectionMap);
      } else {
        updatedStatsData.projectionInfo = [];
      }
    
      // Handle modifierInfo based on sort attribute and order
      if (values.sortAttribute && values.sortBy) {
        const [originalValue] = values.sortAttribute.split('_');
        
        updatedStatsData.modifierInfo = {
          orderBy: values.sortBy,
          orderByName: originalValue
        };
      } 
    
      // Call the callback function with updated statsData
      handleCountAudienceReach(updatedStatsData);
      setFunnelExportState(BOOLEAN_OPTIONS.FALSE);
    };

    //to initialize selected steps to false for no of steps
    useEffect(() => {
      setSelectedSteps(new Array(totalSteps).fill(false));
    }, [totalSteps]);
    

return (
  <CustomDrawer
  footer={
    <div>
      <PrimaryButton type="primary" htmlType="submit" onClick={() => form.submit()}>
        {t("saveLabel")}
      </PrimaryButton>
    </div>
  }
  open={funnelExportState}
  placement="right"
  closable={false}
  onClose={() => setFunnelExportState(false)}
>
  <div className=" mt-1 funnel-export-container">
    <h5 className="fw-semibold">Export funnel data</h5>
    {totalSteps > 0 && (
      <Form
        colon={false}
        labelAlign="left"
        labelCol={{ flex: "25px" }}
        layout="vertical"
        form={form}
        onFinish={onSubmit}
      >
        <Divider />

        <Form.Item 
         name="checkboxGroup" 
         rules={[
           { required: true, message:"Please select atleast one step"},
          ]}
        >
          <div>
              {statsData?.filterInfo?.map((event: any, index: number) => (
              <div key={index}>
                  <CustomCheckbox
                  className="mb-2"
                  checked={selectedSteps[index]}
                  onChange={() => {
                    handleCheckChange(index)
                    form.resetFields(["selectedColumns","sortAttribute"]);
                    setSortAttributeOptions([])
                  }}
                  label={`Users who have done Step ${index + 1} of ${totalSteps}`}
                  />
              </div>
              ))}
               {totalSteps > 1 && (
                  <CustomCheckbox
                      className="mb-2"
                      checked={notDoneStep}
                      onChange={(e:any) => {
                          setNotDoneStep(e.target.checked);
                          handleCheckChange(totalSteps - 1, BOOLEAN_OPTIONS.TRUE)}       
                      }
                      label={`Users who have not done Step ${totalSteps} of ${totalSteps}`}
                  />
              )}    
          </div>
          </Form.Item>
          <Form.Item name="selectedColumns">
              <Selector
                mode="multiple"
                options={selectedColumnsOptions}
                placeholder="Select columns"
                onChange={(selectedValues: string[]) => {
                  // Define type for selectedColumnsOptions
                  const selectedOptions = selectedColumnsOptions.filter((option: { label: string; value: string }) =>
                    selectedValues.includes(option.value)
                  );          
                  // Update state with full objects
                  setSortAttributeOptions(selectedOptions);
            
                }}
              />
            </Form.Item>

            <Form.Item name="sortAttribute">
              <Selector
                options={sortAttributeOptions}
                placeholder="Sort attribute"
              />
            </Form.Item>
            <Form.Item name="sortBy" >
              <Selector
                options={[
                  {
                    label: OrderByEnum.ASC,
                    value: OrderByEnum.ASC,
                  },
                  {
                    label: OrderByEnum.DESC,
                    value: OrderByEnum.DESC,
                  },
                ]}
                placeholder="Sort by"
              />
            </Form.Item>
        

      </Form>
    )}
  </div>
</CustomDrawer>
)
};


