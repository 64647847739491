"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BooleanStringEnum = exports.DBPlatformEnum = exports.OperatorEnum = exports.PGSQLActionTypeEnum = exports.QueueStatusEnum = exports.ValidatorOptionsEnum = exports.RTLLanguageEnum = exports.PeriodEnum = exports.OrderByEnum = exports.ComparisonEnum = exports.SegmentEnum = exports.IntervalEnum = exports.PlatformEnum = exports.CERTPATH = void 0;
// Constant representing the path to the directory where application certificates are stored
exports.CERTPATH = "/certs/appcertificates/";
var PlatformEnum;
(function (PlatformEnum) {
    PlatformEnum["ALL"] = "all";
    PlatformEnum["ANDROID"] = "Android";
    PlatformEnum["IOS"] = "iOS";
    PlatformEnum["WEB"] = "Web";
    PlatformEnum["UNKNOWN"] = "Unknown";
})(PlatformEnum = exports.PlatformEnum || (exports.PlatformEnum = {}));
var IntervalEnum;
(function (IntervalEnum) {
    IntervalEnum["DAYS"] = "d";
    IntervalEnum["HOURS"] = "h";
    IntervalEnum["MINUTES"] = "m";
    IntervalEnum["SECONDS"] = "s";
    IntervalEnum["DAYS_OR_MORE"] = "dm";
    IntervalEnum["DAYS_OR_LESS"] = "dl";
})(IntervalEnum = exports.IntervalEnum || (exports.IntervalEnum = {}));
var SegmentEnum;
(function (SegmentEnum) {
    SegmentEnum["FUNCSEGMENT"] = "funcsegment";
    SegmentEnum["AUDSEGMENT"] = "audsegment";
})(SegmentEnum = exports.SegmentEnum || (exports.SegmentEnum = {}));
var ComparisonEnum;
(function (ComparisonEnum) {
    ComparisonEnum["FROM"] = "from";
    ComparisonEnum["BEFORE"] = "before";
})(ComparisonEnum = exports.ComparisonEnum || (exports.ComparisonEnum = {}));
var OrderByEnum;
(function (OrderByEnum) {
    OrderByEnum["ASC"] = "ASC";
    OrderByEnum["DESC"] = "DESC";
})(OrderByEnum = exports.OrderByEnum || (exports.OrderByEnum = {}));
var PeriodEnum;
(function (PeriodEnum) {
    PeriodEnum["YESTERDAY"] = "Yesterday";
    PeriodEnum["TODAY"] = "Today";
    PeriodEnum["TOMORROW"] = "Tomorrow";
    PeriodEnum["CURRENT_MONTH"] = "CURRENT_MONTH";
    PeriodEnum["CURRENT_DAY"] = "CURRENT_DAY";
    PeriodEnum["CURRENT_DATE"] = "CURRENT_DATE";
    PeriodEnum["THIS_WEEK"] = "This Week";
    PeriodEnum["LAST_WEEK"] = "Last Week";
    PeriodEnum["THIS_MONTH"] = "This Month";
    PeriodEnum["LAST_MONTH"] = "Last Month";
    PeriodEnum["CUSTOM"] = "Custom";
})(PeriodEnum = exports.PeriodEnum || (exports.PeriodEnum = {}));
var RTLLanguageEnum;
(function (RTLLanguageEnum) {
    RTLLanguageEnum["ARABIC"] = "Arabic";
    RTLLanguageEnum["URDU"] = "Urdu";
})(RTLLanguageEnum = exports.RTLLanguageEnum || (exports.RTLLanguageEnum = {}));
var ValidatorOptionsEnum;
(function (ValidatorOptionsEnum) {
    ValidatorOptionsEnum["REGEX"] = "regex";
    ValidatorOptionsEnum["CUSTOM"] = "custom";
    ValidatorOptionsEnum["FILE"] = "file";
})(ValidatorOptionsEnum = exports.ValidatorOptionsEnum || (exports.ValidatorOptionsEnum = {}));
var QueueStatusEnum;
(function (QueueStatusEnum) {
    QueueStatusEnum["INITIATED"] = "initiated";
    QueueStatusEnum["FAILED"] = "failed";
    QueueStatusEnum["PROCESSED"] = "processed";
})(QueueStatusEnum = exports.QueueStatusEnum || (exports.QueueStatusEnum = {}));
var PGSQLActionTypeEnum;
(function (PGSQLActionTypeEnum) {
    PGSQLActionTypeEnum["SET_READ_ONLY"] = "SET TRANSACTION READ ONLY";
})(PGSQLActionTypeEnum = exports.PGSQLActionTypeEnum || (exports.PGSQLActionTypeEnum = {}));
var OperatorEnum;
(function (OperatorEnum) {
    OperatorEnum["AND"] = "AND";
    OperatorEnum["OR"] = "OR";
})(OperatorEnum = exports.OperatorEnum || (exports.OperatorEnum = {}));
var DBPlatformEnum;
(function (DBPlatformEnum) {
    DBPlatformEnum["ANDROID"] = "Android";
    DBPlatformEnum["IOS"] = "IOS";
    DBPlatformEnum["WEB"] = "Web";
})(DBPlatformEnum = exports.DBPlatformEnum || (exports.DBPlatformEnum = {}));
var BooleanStringEnum;
(function (BooleanStringEnum) {
    BooleanStringEnum["TRUE"] = "true";
    BooleanStringEnum["FALSE"] = "false";
})(BooleanStringEnum = exports.BooleanStringEnum || (exports.BooleanStringEnum = {}));
